import { AuthService } from 'ngx-auth';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { TokenStorage } from './token-storage.service';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { RefreshTokenAction, RefreshTokenFailedAction } from '../actions/login.action';
import { LoginService } from './login.service';
import { OAuthToken } from '../models/oauth-token';
import { ConnectableUser } from 'mys-base';

@Injectable()
export class AuthenticationService implements AuthService
{
    // region Constructor & Attributes

    /**
     * Last URL asked for by the user before being redirected to the login page
     */
    private INTERRUPTED_URL = 'interrupted_url';

    constructor(private store: Store, private tokenStorage: TokenStorage)
    {
    }

    // endregion

    public isAuthorized(): Observable<boolean>
    {
        return this.getAccessToken().pipe(map(token => !!token));
    }

    public getAccessToken(): Observable<string>
    {
        return this.tokenStorage.getAccessToken();
    }

    /**
     * Function, that should perform refresh token verifyTokenRequest
     * @description Should be successfully completed so interceptor can execute pending requests or retry original one
     * @returns {Observable<any>}
     */
    public refreshToken(): Observable<any>
    {
        console.log('Token being refreshed...');

        /**
         * This call was commented, because it broke the "interruptedUrl" process.
         * If that causes any more issues, it can be un-commented, but be aware that the "interruptedUrl" process would
         * then need some rework
         */
        return this.tokenStorage.getRefreshToken().pipe(
            map((refreshToken: string) => {

                /**
                 * If the "refreshToken" is null, calling RefreshTokenAction would trigger a useless query to our
                 * backend, which would log a warning.
                 * To avoid that, we defined a specific actions, designed to simulate a failed RefreshToken request
                 */
                const action = !!refreshToken ? new RefreshTokenAction(refreshToken) : new RefreshTokenFailedAction();
                return this.store.dispatch(action);
            })
        );
    }

    public refreshShouldHappen(response: HttpErrorResponse, request?: HttpRequest<any>): boolean
    {
        return response.status === 401;
    }

    /**
     * Verify that outgoing request is refresh-token, so interceptor won't intercept this request
     */
    public verifyRefreshToken(req: HttpRequest<any>): boolean
    {
        return req.url.includes(LoginService.OAUTH_TOKEN_PATH);
    }

    /**
     * Checks if request must be skipped by interceptor.
     * Useful for requests such as request token which doesn't require token in headers
     */
    public skipRequest(req: HttpRequest<any>): boolean
    {
        /**
         * Default behavior (see https://www.npmjs.com/package/ngx-auth)
         */
        const shouldSkip = req.url.endsWith('third-party-request')

            /**
             * If we are requesting our assets from NGX-Translate, for example, we don't want to apply the
             * HTTP Bearer Interceptor
             */
            || req.url.includes('/assets/');


        // console.log('skipRequest :', shouldSkip);

        return shouldSkip;
    }

    /**
     * Save access data in the storage
     *
     * @private
     * @param oauthToken
     */
    public saveAccessData(oauthToken: OAuthToken<ConnectableUser>)
    {
        this.tokenStorage
            .setAccessToken(oauthToken.access_token)
            .setRefreshToken(oauthToken.refresh_token);
    }

    /**
     * Clears the OauthToken
     */
    public logout(): void
    {
        this.tokenStorage.clear();
    }

    /**
     * Saves last interrupted url inside the services for further re-usage,
     * e.g. restoring interrupted page after logging in
     */
    public setInterruptedUrl(url: string): void
    {
        localStorage.setItem(this.INTERRUPTED_URL, url);
    }

    /**
     * Gets the last URL the user asked for before getting redirected
     */
    get interruptedUrlAndParams(): { path: any[], queryParams?: Params }
    {
        let url = localStorage.getItem(this.INTERRUPTED_URL);

        /**
         * We remove the INTERRUPTED_URL from the local storage now, to avoid having a pending INTERRUPTED_URL
         * that might mess up between both Alfred and Liberty Order (if both apps are accessed from the same computer)
         */
        localStorage.removeItem(this.INTERRUPTED_URL);

        /**
         * @see https://stackoverflow.com/questions/47150795/why-is-null-stored-as-a-string-in-localstorage
         * Since "localStorage" cannot store null values (it actually stores the STRING "null" (like, WTF Javascript)),
         * we have to ensure that the result value is not "null". In this case, we replace it with an empty string
         * (and we should probably not create a /null route ;) )
         */
        url = url !== null && url !== 'null' ? url : '';

        /**
         * We found out (hello dumb technos) that the QueryParams (that are inside the URL) cannot be passed as such
         * to the Angular Router. If we just return a URL string with QueryParams, Angular Router would just fail to
         * find a route /xxx?yyy=zzz instead of finding the route /xxx with the params yyy=zzz (again, WTF...)
         *
         * We need to pre-chew the food, like for the babies, in order to keep Angular happy
         *
         * And in this case, that means splitting the current URL (which is stored as a string, as defined in
         * NGX-Auth's setInterruptedUrl) to extract the actual Path and the optional QueryParams
         */
        const paramString = url.split('?')[ 1 ];
        const params = {};
        let indexOfQuestionMark = url.length;

        if (!!paramString) // If there is some GET parameters
        {
            indexOfQuestionMark = url.indexOf('?'); // We know that there is one, the "indexOf" won't return -1
            url.split('?')[ 1 ].split('&').forEach(keyAndValue =>
            {
                const key = keyAndValue.split('=')[ 0 ];
                const value = keyAndValue.split('=')[ 1 ];

                params[ key ] = value;
            });
        }

        /**
         * Now that we extracted the params, we can keep the "path" part of the URL, and return both parts
         */
        return { path: [ url.substr(0, indexOfQuestionMark) ], queryParams: params };
    }
}
