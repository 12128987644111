import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RegisterClientState } from '../../state/register-client.state';
import { Client, RoutingHelper } from 'mys-base';

@Component({
    selector: 'msl-post-register',
    templateUrl: './msl-post-register.component.html'
})
export class MslPostRegisterComponent
{
    @Select(RegisterClientState.client) client$: Observable<Client>;

    constructor(private store: Store)
    {
    }

    redirectToHomePage()
    {
        RoutingHelper.redirect(this.store, '/');
    }
}
