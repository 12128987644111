<div id="routing-menu-container" class="d-flex flex-column">
    <!-- "Login" and "Register" are only displayed if the client is not logged in -->
    <div *ngIf="!(client$ | async)">
        <mys-routing-menu-item [link]="'/register'" [titleKey]="'register.TITLE'"></mys-routing-menu-item>
        <mat-divider></mat-divider>

        <mys-routing-menu-item [link]="'/login'" [titleKey]="'login.TITLE'"></mys-routing-menu-item>
        <mat-divider></mat-divider>
    </div>

    <mys-routing-menu-item [link]="'/estimation'" [titleKey]="'estimation.TITLE'"></mys-routing-menu-item>

    <!-- "Log out" is only displayed if the client is logged in -->
    <div *ngIf="client$ | async">
        <mat-divider></mat-divider>
        <mys-routing-menu-item (click)="logout()" [titleKey]="'login.LOGOUT'"></mys-routing-menu-item>
    </div>

    <!-- Go back to the Wordpress website -->
    <div class="mt-auto">
        <div class="ms-3" id="mysam-logo-container">
            <a href="https://www.mysam.fr">
                <img src="/assets/img/transparent_logo_mysam.png"/>
            </a>
        </div>
        <mys-routing-menu-item [link]="'https://www.mysam.fr'"
                               [titleKey]="'nav.BACK-TO-WEBSITE'"></mys-routing-menu-item>
    </div>
</div>
