import { Component } from '@angular/core';
import { ClientSpecificConfig } from '../../../environments/liberty-order/client-specific-config';

@Component({
    selector: 'lo-layout',
    templateUrl: './lo-layout.component.html',
    styleUrls: [ './lo-layout.component.scss' ]
})
export class LoLayoutComponent
{
    // region Attributes

    SHOULD_DISPLAY_HEADER = ClientSpecificConfig.instance.shouldDisplayHeader;
    BACKGROUND_COLOR = ClientSpecificConfig.instance.backgroundColor;
    PATH_TO_LOGO = ClientSpecificConfig.instance.pathToLogo;
    BACKGROUND_LAYOUT_COLOR = ClientSpecificConfig.instance.backgroundLayoutColor;

    // endregion
}
