import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslTimePickerComponent } from './msl-time-picker.component';
import { MslSimpleSelectModule } from '../msl-simple-select/msl-simple-select.module';
import { TranslateModule } from '@ngx-translate/core';

const libraries = [ MslSimpleSelectModule ];
const components = [ MslTimePickerComponent ];

@NgModule({
    declarations: [ ...components ],
    imports: [
        CommonModule,
        ...libraries,
        TranslateModule
    ],
    exports: [ ...components ]
})
export class MslTimePickerModule
{
}
