import { $enum } from 'ts-enum-util';
import { Moment } from 'moment';
import { VehicleType } from '../../models/constants/vehicle-type';
import { Address } from '../../models/address';
import { Client } from '../../models/users/client';
import moment from 'moment';

/**
 * Created by Adrien Dos Reis on 31/10/2018
 * This class allows to send an estimation request to the server
 */
export class EstimationRequest
{
    // region Attributes

    static readonly DATE_FORMAT = 'YYYY-MM-DD HH:mm';

    /**
     * Added on 25/02/2019
     * https://mysamcab.atlassian.net/browse/MYS-3253
     *
     * Can be null, but can also be used to know whether the Client is bound to a Company or not
     */
    private clientId: string | null = null;

    /**
     * Added on 29/06/2020
     * https://mysamcab.atlassian.net/browse/MYS-4375
     *
     * Null on Alfred, it will be filled with the companyId of the Liberty Order Company (if given in the
     * ClientSpecificConfig)
     */
    companyId: number | null = null;

    /**
     * We don't want to use a LatLng object, since the backend expects the data to be formatted this way
     */
    private fromLatitude: number;
    private fromLongitude: number;

    private toLatitude: number;
    private toLongitude: number;

    private vehicleType: string = this.setVehicleType(VehicleType.CAR);
    private startDate: string | null; // Force the usage of the "bookingDate" setter

    /**
     * Those Addresses are not required to make an estimation (they are not attributes requested by the back-end),
     * but we can store them here in order to use them both in the Estimation and the Trip Request process
     */
    private _fromAddress: Address;
    private _toAddress: Address;

    /**
     * Not required to make an estimation but used on Trip Request process
     */
    private _estimationId: number;

    /**
     * Indicate the disability level of the Client doing the Trip :
     * - null --> No disability
     * - false --> Mild disability
     * - true --> Significant disability
     */
    private _significantDisability: boolean | null;

    // endregion

    /**
     * Builds an EstimationRequest with a start date given as a string
     * @param fromAddress Departure Address
     * @param toAddress Arrival Address
     * @param startDate Start Date given as a string formatted as such : 'YYYY-MM-DD HH:mm'
     * @param vehicleType Vehicle Type
     * @param client The client requesting the Estimation
     * @param significantDisability The level of disability of the client requesting the Estimation
     */
    static buildWithStringDate(
        fromAddress: Address, toAddress: Address, startDate: string | null, vehicleType: VehicleType,
        significantDisability: boolean | null, client?: Client): EstimationRequest
    {
        const estimReq = new EstimationRequest();
        estimReq.setAddresses(fromAddress, toAddress);
        estimReq.setVehicleType(vehicleType);
        estimReq.startDate = startDate;
        estimReq.clientId = !!client ? client.userId : null;
        estimReq._significantDisability = significantDisability;
        return estimReq;
    }

    setAddresses(fromAddress: Address, toAddress: Address): EstimationRequest
    {
        this._fromAddress = fromAddress;
        this._toAddress = toAddress;

        this.fromLatitude = fromAddress.latitude;
        this.fromLongitude = fromAddress.longitude;
        this.toLatitude = toAddress.latitude;
        this.toLongitude = toAddress.longitude;

        return this;
    }

    // region Getters / Setters

    get estimationId(): number
    {
        return this._estimationId;
    }

    setEstimationId(estimationId: number)
    {
        this._estimationId = estimationId;
    }

    get fromAddress(): Address
    {
        return this._fromAddress;
    }

    get toAddress(): Address
    {
        return this._toAddress;
    }

    set bookingDate(momentObj: Moment | null)
    {
        this.startDate = !!momentObj ? momentObj.format(EstimationRequest.DATE_FORMAT) : null;
    }

    get bookingDate(): Moment
    {
        return moment(this.startDate, EstimationRequest.DATE_FORMAT);
    }

    get startDateAsString(): string | null
    {
        return this.startDate;
    }

    get significantDisability(): boolean
    {
        return this._significantDisability;
    }

    getVehicleType(): VehicleType
    {
        return $enum(VehicleType).getValueOrDefault(this.vehicleType, VehicleType.CAR);
    }

    getVehicleTypeAsString(): string
    {
        return this.vehicleType;
    }

    setVehicleType(vehicleType: VehicleType | null): string
    {
        this.vehicleType = $enum(VehicleType).getKeyOrDefault(vehicleType, VehicleType.CAR);
        return this.vehicleType;
    }

    // endregion
}
