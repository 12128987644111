import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MysamLanguageHolder } from '../mysam-language-holder';
import { BASE_API_URL, MysamBaseInterceptor } from 'mys-base';

/**
 * Created by Adrien Dos Reis on 19/04/2022
 * Sends the current language of the user as a header to every request to our backend
 */
@Injectable({ providedIn: 'root' })
export class HeaderLanguageInterceptor extends MysamBaseInterceptor
{
    // region Constructor

    constructor(@Inject(BASE_API_URL) private BASE_URL: string, private mysamLanguageHolder: MysamLanguageHolder)
    {
        super(BASE_URL);
    }

    // endregion

    override interceptIfMySamRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        /**
         * https://mysamcab.atlassian.net/browse/MYS-5380
         * Adding the current language from MysamLanguageHolder to the header of the request
         */
        const userLanguage = this.mysamLanguageHolder.currentLang.code;
        request = request.clone({ headers: request.headers.set('Accept-Language',userLanguage) });

        return next.handle(request)
    }
}
