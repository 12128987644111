import { ExclusivityReason } from "./constants/exclusivity-reason";
import { $enum } from "ts-enum-util";

export class Exclusivity
{
    endDate: Date | null;
    reason: ExclusivityReason | null;

    static assign(uncastedExclusivity: Exclusivity): Exclusivity
    {
        const exclusivity: Exclusivity = Object.assign(new Exclusivity(), uncastedExclusivity);
        exclusivity.reason = $enum(ExclusivityReason).getValueOrDefault(exclusivity.reason);

        return exclusivity
    }
}
