<div>

    <!-- And if the user chose a Reservation, we display a Calendar -->
    <div *ngIf="isReservation">
        <div class="row mt-3" id="date-time-container">
            <msl-french-date-picker class="col-5" [placeholder]="'misc.START-DATE' | translate"
                                    [date]="selectedMoment.toDate()"
                                    (dateChange)="selectedMoment = $event; emitDateTime()">
            </msl-french-date-picker>

            <!-- Time picker -->
            <msl-time-picker [selectedHour]="selectedHour" [selectedMinutes]="selectedMinutes" class="col-6"
                             (hourOrMinuteChange)="emitDateTime($event)"></msl-time-picker>
        </div>
    </div> <!-- End of "ngIf='isReservation'" -->

    <!-- Vehicle Type layout -->
    <div class="row" id="vehicleTypeRow">
        <div [ngClass]="containerClass">

            <!-- Old way of displaying Vehicle Types -->
            <msl-vehicle-radio *ngIf="!useNewDisplay" class="row g-0 mt-3"
                               [vehicleType]="vehicleType" [disabled]="vehicleTypeDisabled" [displayTooltips]="true"
                               (vehicleTypeSelected)="vehicleTypeChange.emit($event)">
            </msl-vehicle-radio>

            <!--
                    New way of displaying Vehicle Types
                    For now, it is only used on Liberty Order (where it is not possible to order an Immediate trip).
                    That's why we hide it if "isReservation" is false.
                    In the future, it might be useful to have a boolean value specific for that....
            -->
            <msl-vehicle-radio-v2 *ngIf="useNewDisplay && isReservation"
                                  [vehicleType]="vehicleType"
                                  [estimations]="estimations"
                                  (vehicleTypeSelected)="vehicleTypeChange.emit($event)"></msl-vehicle-radio-v2>
        </div>
    </div>

</div>
