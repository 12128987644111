import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslClearableAutocompleteComponent } from './msl-clearable-autocomplete.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    declarations: [ MslClearableAutocompleteComponent ],
    exports: [ MslClearableAutocompleteComponent, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule,
        MatAutocompleteModule, ReactiveFormsModule, TranslateModule ]
})
export class MslClearableAutocompleteModule
{
}
