import { RegisterUserRequest } from './register-user-request';

export class RegisterClientRequest extends RegisterUserRequest
{
    companyId: number | null = null;
    companyName: string | null = null;

    /**
     * Can be set to true if the creator is a Driver bound to a Company, and if this Company of Drivers should be the referrer
     * of this Client
     */
    referredByGroupOfDrivers = false;
}
