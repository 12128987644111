import { RegisterClientRequest } from '../service/requests/register-client-request';

export const REGISTER_CLIENT = '[Register] Register client';
export const RESET_REGISTER_CLIENT = '[Register] Reset register client';

export class RegisterClient
{
    static readonly type = REGISTER_CLIENT;

    constructor(public payload: RegisterClientRequest)
    {
    }
}

export class ResetRegisterClientState
{
    static readonly type = RESET_REGISTER_CLIENT;

    constructor()
    {
    }
}
