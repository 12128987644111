import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyToZeroConverter } from './pipes/currency-to-zero.converter';
import { ZeroDCurrencyPipe } from './pipes/zero-d-currency.pipe';
import { CurrencyToZeroPipe } from './pipes/currency-to-zero.pipe';

const pipes = [ CurrencyToZeroPipe, ZeroDCurrencyPipe]

@NgModule({
  declarations: [ ...pipes ],
  imports: [
    CommonModule
  ],
  providers: [ ...pipes, CurrencyToZeroConverter ],
  exports: [ ...pipes ]
})
export class CurrencyPipesModule { }
