<div class="row">
    <div class="mx-auto col-auto" id="register-card-container">
        <mat-card id="register-card" class="p-4">

            <!--
                    This title will only be displayed while we are creating the client, not when displaying
                    MslPostRegisterComponent (or any other "success component")
            -->
            <mat-card-title *ngIf="!(client$ | async)">{{ 'register.CREATE-ACCOUNT' | translate }}</mat-card-title>

            <mat-card-content #registerContent>

                <mat-error class="p-0 p-sm-2 fw-bold" *ngIf="registerError$ | async as registerErr">
                    {{ registerErr.getTranslateCode() | translate }}
                </mat-error>

                <form *ngIf="!(client$ | async)" [formGroup]="registerForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <!-- Name infos -->
                    <div class="row p-sm-2">

                        <!-- First name -->
                        <div class="col-12 col-sm-auto">
                            <mat-form-field>
                                <input matInput type="text"
                                       [formControlName]="'firstNameInput'"
                                       [placeholder]="'words.FIRST-NAME' | translate">
                                <mat-error>{{ 'errors.inputs.REQUIRED' | translate }}</mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Last name -->
                        <div class="col-12 col-sm-auto">
                            <mat-form-field>
                                <input matInput type="text"
                                       [formControlName]="'lastNameInput'"
                                       [placeholder]="'words.LAST-NAME' | translate">
                                <mat-error>{{ 'errors.inputs.REQUIRED' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Contact infos -->
                    <div class="p-0 p-sm-2">
                        <!-- Email -->
                            <mat-form-field>
                                <input matInput type="text"
                                       [formControlName]="'emailInput'"
                                       [placeholder]="'words.EMAIL' | translate">
                                <mat-error *ngIf="emailInput.hasError(getEmailError())">
                                    {{ 'errors.inputs.EMAIL' | translate }}
                                </mat-error>
                                <mat-error *ngIf="emailInput.hasError(getEmailAlreadyUsedError())">
                                    {{ 'errors.user-register.EMAIL-ALREADY-EXISTS' | translate }}
                                </mat-error>
                                <mat-error *ngIf="emailInput.hasError(getRequiredError())">
                                    {{ 'errors.inputs.REQUIRED' | translate }}
                                </mat-error>
                            </mat-form-field>
                    </div>

                    <!-- Mobile -->
                    <msl-phone-number-and-prefix-input [phoneSuffixInputCtrl]="phoneSuffixInput"
                                                       [prefixInputCtrl]="phonePrefixIdInput"
                                                       [getPatternErrorFn]="getRequiredError"
                                                       [getRequiredErrorFn]="getRequiredError"></msl-phone-number-and-prefix-input>

                    <!-- Password -->
                    <div class="row p-0 p-sm-2 pb-2 pb-sm-4" [formGroup]="passwordGroup">
                        <!-- Password -->
                        <div class="col-12 col-sm-auto">
                            <mat-form-field>
                                <input matInput type="password"
                                       [formControlName]="'passwordInput'"
                                       [placeholder]="'words.PASSWORD' | translate">
                                <mat-error *ngIf="passwordInput.hasError(getPatternError())">
                                    {{ 'errors.inputs.password.PATTERN' | translate }}
                                </mat-error>
                                <mat-error *ngIf="passwordInput.hasError(getPasswordNoMatchError())">
                                    {{ 'errors.inputs.password.NO-MATCH' | translate }}
                                </mat-error>
                                <mat-error *ngIf="passwordInput.hasError(getEmptyError())">
                                    {{ 'errors.inputs.password.EMPTY' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Password confirm -->
                        <div class="col-12 col-sm-auto">
                            <mat-form-field>
                                <input matInput type="password"
                                       [formControlName]="'passwordConfirmInput'"
                                       [placeholder]="'words.PASSWORD-CONFIRM' | translate">
                                <mat-error *ngIf="passwordConfirmInput.hasError(getPatternError())">
                                    {{ 'errors.inputs.password.PATTERN' | translate }}
                                </mat-error>
                                <mat-error *ngIf="passwordConfirmInput.hasError(getPasswordNoMatchError())">
                                    {{ 'errors.inputs.password.NO-MATCH' | translate }}
                                </mat-error>
                                <mat-error *ngIf="passwordConfirmInput.hasError(getEmptyError())">
                                    {{ 'errors.inputs.password.CONFIRM' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!--
                            Sponsor code
                    -->
                    <div class="row p-0 p-sm-2" *ngIf="displaySponsorCode">
                        <div class="col-12 col-sm-auto">
                            <mat-form-field>
                                <span matPrefix><b>SAM-</b></span>
                                <input matInput type="text"
                                       [formControlName]="'sponsorCodeInput'"
                                       [placeholder]="'words.SPONSOR-CODE' | translate">
                                <mat-error>{{ 'errors.inputs.SPONSOR-CODE' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row p-0 p-sm-2">
                        <div class="col-12 col-sm-auto d-flex align-items-center mb-2">
                            <mat-checkbox [formControlName]="'acceptTermsInput'">
                                {{ 'register.actions.ACCEPT-TERMS' | translate }}

                                <!--
                                        Link opened in a new window when clicked
                                        noopener and noreferrer added for performance and security
                                        https://stackoverflow.com/a/15551842/2294082
                                -->
                                <a [href]="_termsOfUseUrl"
                                   target="_blank" rel="noopener noreferrer" class="accent-color">
                                    {{ 'register.actions.ACCEPT-TERMS-LINK' | translate }}
                                </a>
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row px-2 p-sm-2">
                        <button mat-raised-button type="submit" class="mx-2"
                                [color]="'primary'" [disabled]="!registerForm.valid">
                            {{ 'register.actions.CREATE' | translate }}
                        </button>

                        <mat-spinner *ngIf="isLoading$ | async" [diameter]="25" class="align-middle m-2"></mat-spinner>
                    </div>

                    <div class="row p-2">
                        <a class="mx-2 accent-color" (click)="RoutingHelper.redirect(store, '/login')">
                            {{ 'login.actions.GO-TO-LINK' | translate }}
                        </a>
                    </div>
                </form>
            </mat-card-content>

        </mat-card>
    </div>
</div>
