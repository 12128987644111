<!-- Departure and arrival addresses -->

<!--
        Since the Images cannot be used from their "file-system path" in the applications using this library,
        we encode them in Base64 (using https://www.base64-image.de), and we pass the String result to the <img> tag
-->
<div class="py-1">
    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAByCAMAAAC4A3VPAAAAtFBMVEUAAAA98MgVy7QUy7QUyrQUy7QVy7QVy7QVy7MVzLUWy7UVzLYVzrYXz7gY1Lsh08gVyrMVy7QVyrQUy7QVy7MVyrQVy7MUyrMWyrQVy7MWzLQUzLQWzLUWy7MVzLcazLsk27YXzLUUyrP////M8+6c6N8kzbiy7eaa6N7O9O9j3Mzu+/rk+faH5Ng60r8ay7Ut0LtP18a78Oms7OSn6+KV591D1cL4/f3X9vJ54NNv3tBY2clDuIBbAAAAInRSTlMABN767ebTto1yUUwkIRQLxsX34NvPzbyalIF9aF48Hgctefx06AAAAyVJREFUaN7Vm9lW2zAQhmN5Xxrb2XfgN9DSQMtOl/d/r6bFNHDwJBpbc3C+u1x9J7Y1+iWNOjysJA4nfu4px7Yd5eX+JIwTqyNFN5p6NiqwvWnUNe9bBUPsZBisTPqSmYIGapaY8VnLHNrky+Yv9nihwEItjhsJ09AFGzdM6xujPmrRj2oKP/mojf+pzlcz76EBvTn7OzrK0JDsiGeMHTTGiTkPNYARAu2Hm45hiHGqWb5HMMaoqzU2BjDIQGO0nLgwinuy9z+6MIy75392BzDOYOf7TEcQYJTSRmsMEcb0+AwgREBWOYgRE5XcgRhOZY23MgiSVb3OOUSZV9SAHkTpva8IPvS4vtrwG3z8d8kKmjwUG56+g0/01pj2oclZ8Zdz8Om/LUIhSgSVCN9kchdbxJTu6xy/AAGl/IY6LLZGS4GAUq4vXnH6ijvsQG3rwRJMJc1657e8/K/MjSkvH7GL/MWYwJTyct87TkrlzJTyxzX2MCuVypDydP/QUc/GFcwob3Wq4IqOH3zlPfQjydCI8gFaDP9FV5hQXkGTLjFtsZVrlGhNYVMQyPzL6UbpAQaUxRn08DYl3YYRZXGnlxRsi6h2YuMSSMqILlB9KOIyggjUWDqOTFAiO5NsmXR8k8pi/Yj9eTZDiWwq2JKVw5KffU6rOdMYmAo8vugmPHrKdEBDK89RG6djg0BKaX+A8iMerAKBlFIRg0RQ6RFJXVCZEwVPUOkTZV1QOSEmL0FlSEzRgsqYCCKCyoSIW3JK2yJCpZzSI6KzoHJKLBAElRGxDBJUdonFnpxySCxpBZUBsXAXVK7qbE80Uqo6mzC3jRLejLHVdPP1mYdyxfPr+ec1eCT6G2r3RTVPPGfO2Da8LAhuwGFJbI7KKZVFbAHLKRecje4Lwsj6dN1jznb+4/3nKu5+gkFIHFrI0U+Joxk5IuIASg6/Dcds4oeJ7TgyFT4Ybsvxt+Qhf5taGcQaNtrVliLTfNO+FiPzjVTtbBcz2xTX3tY/Yw2ObW/jNNGseggtuc0ajw+pvbpmE/nhtcozLwQc8rUHgcsd3Css2csVlqzOFZY/pFRM4LZicEUAAAAASUVORK5CYII="
         class="flag">{{ fromAddress | address }}
</div>
<div class="py-1">
    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAByCAMAAAC4A3VPAAAAvVBMVEUAAAD/QHT/M2f/M2b/NWf/OGj/NWv/OGn/N2//NGf/NGb/NGf/M2b/M2b/NGb/NGb/NGf/NGf/M2b/M2b/M2b/NGb/M2b/NGf/M2f/M2b/M2f/NGf/M2f/M2b/M2b/NGj/M2f/NGf/NGj/M2f/M2b/M2b/M2b/M2b/M2b/////1N//qL7/QXD/vM3/d5n/V4H/OGr/8fX/6O7/tcj/Snj/mLL/aI7/+vz/xNP/o7r/j6v/3ub/2+T/z9v/f5/65+1WAAAAKHRSTlMABN7TTCQhFAvGxY36+fft7Ofl4NvPzby4tJqUgX1zcWheU088Howtd3bhAQAAAypJREFUaN7Vm2lT4kAQhjMTYkjCJfd9i2/UXURAxT3+/89aLHRTlDSZSabL8Hzz01Pj0J2enh5LD+Hkht22b8tKsViRtt/uDnOOsLhwpz27gBMU7N7UNe+bBw2cpRHMTfqcvoQCsu+Y8YmJD2X8iUgvHEtoIcfppPlRFdpUR/nkxlkNiajNEgqvOkhM5yrJJg5KSEFpoL2lNx5S4t3oGXNlpKac0xCKBYywEMqh0YIhWorh4jZhjKarFBt1GKSuEC231zDK9W3sGiOjKWfMOt06jFM/u5/5Jhho5mmjaIGFFh2fAZgIyCwHNnJEJi+DjfLJHC88MOKd2s4BWBmcyAElsFL6mhE6UGPztt1un6FP50tlBUV24Z6XR+gzOzbma1DkPnznAfrUjpPQCBFcSoyOAqSKCDZlVVgRYxBQyiWSMI6MQoKAUq7uCNY4g4yWOYGmkmb1iDNM/it9Y8qnJc7hfxodmFI+bXAe50PZN6X8HWdE/0MpDSn/xIeOPBjnMKN8VcmCc7r80FeuH6FckjSMKHdQovFudGFCuYUi7l45BdIrV1Blulf2QMCzyt5eaQMGlOE91LAtSxQQwf+LRUEQ2Y4tLgHHyhlThncPUCrch+aU4a8N4hla3TRK+ttF07XaJpXhKt7ZtjxEMFYFEV4UlmZqn3vEB6ZMpFwiMdKqgCBdHUtTsYog4FIWv0H5Hf9YCQIupSSChFFpE5U6o9InEh6jsk2kdUZll/h4MSqHxCeaUZkjChFGpUOUW3zKgiCKSj6lTZTOjMoecUBgVE6JYxCj0iUOe3zKBnGkZVQGxMGdUTkn2hN8SpmkCfOaqsLra7Sann8e2L0cTjx/D39uoIej3lBbh6d50XP6Gm3Dp5DgGTpMiOYon1IKogXMpxzrNLrvKOUS6lSFTjt/uf5xivUbNBgRlxZ81PLE1QwfM+ICio9OFq7Z2C8Ts3FlynwxnJXrb8tagIlFlkYZ2AY2sjWWwjN8k70RI/ODVNkcF+MciqMRAYwQiCwPOBoa48z+sGrqkdxLGTxOMV59WUPkSUblL/NBgN6zh8t+3HH0hMX7fMLiJXnC8g9vfzlSZ3eV0gAAAABJRU5ErkJggg=="
         class="flag">{{ toAddress | address }}
</div>
<mat-divider [inset]="true"></mat-divider>

<!-- Departure time and vehicle type -->
<div class="py-1">
    <span *ngIf="!!bookingDate">
        {{ 'misc.summary.DEPARTURE-TIME' | translate :
        {
            date: bookingDate | date: 'dd/MM/yyyy',
            time: bookingDate | date: 'HH\'h\'mm'
        } }}
    </span>
    <span *ngIf="!bookingDate">{{ 'misc.summary.IMMEDIATE-RIDE' | translate }}</span>
    - {{ vehicleTypeKey | translate }}</div>
<mat-divider [inset]="true"></mat-divider>

<!-- Payment method, comment, passengers number and train/flight number -->
<div class="py-1">
    <div *ngIf="paymentMethod === PaymentMethod.ON_BOARD">
        {{ 'misc.summary.ON-BOARD-PAYMENT' | translate:
        {cashOrCB: (willBePaidInCash ? 'misc.summary.CASH' : 'misc.summary.CREDIT-CARD') | translate} }}
    </div>
    <div *ngIf="paymentMethod === PaymentMethod.IN_APP">{{ 'misc.summary.IN_APP' | translate }}</div>
    <div *ngIf="paymentMethod === PaymentMethod.DEFERRED">{{ 'misc.summary.DEFERRED' | translate }}</div>
</div>
<div class="py-1" *ngIf="!!passengers">
  {{ 'misc.summary.PASSENGERS' | translate: {passengers: passengers} }}
</div>
<div class="py-1" *ngIf="!!flightNumber">
  {{ 'misc.summary.FLIGHT-NUMBER' | translate: {flightNumber: flightNumber} }}
</div>
<div class="py-1" *ngIf="!!trainNumber">
  {{ 'misc.summary.TRAIN-NUMBER' | translate: {trainNumber: trainNumber} }}
</div>
<div class="py-1" *ngIf="!!comment">
    {{ 'misc.summary.COMMENT' | translate: {comment: comment} }}
</div>
