/**
 * Created by Sandra Bénard on 27/09/2022
 */

export class ZoneFlatRate
{
  firstZoneId: number;
  firstZoneName: string;

  secondZoneId: number;
  secondZoneName: string;
}
