export class ApiError
{
    private _type: string;
    private _description: string;
    private _httpStatus: number;
    private _extraParams: any;

    constructor(type: string, description: string, httpStatus: number, extraParams: any)
    {
        this._type = type;
        this._description = description;
        this._httpStatus = httpStatus;
        this._extraParams = extraParams;
    }

    get description(): string
    {
        return this._description;
    }

    set description(value: string)
    {
        this._description = value;
    }

    get httpStatus(): number
    {
        return this._httpStatus;
    }

    set httpStatus(value: number)
    {
        this._httpStatus = value;
    }

    get extraParams(): any
    {
        return this._extraParams;
    }


    get type(): string
    {
        return this._type;
    }

    set type(value: string)
    {
        this._type = value;
    }

    public getErrorMessage(translate: any): string
    {
        return this.description;
    }

    /**
     * To be re-defined into subclasses
     */
    public getTranslateCode(): string
    {
        return '';
    }
}
