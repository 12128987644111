import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'mys-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent
{
    @Output() menuClicked = new EventEmitter<MouseEvent>();

    constructor()
    {
    }
}
