import { Inject, Injectable } from '@angular/core';
import { ApiService, Client } from 'mys-base';
import { RegisterClientRequest } from './requests/register-client-request';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RegisterClientError } from '../errors/register-client.error';
import { CREATE_CLIENT_URL } from '../index';

@Injectable({ providedIn: 'root' })
export class RegisterService extends ApiService
{
    /**
     * @param httpClient The injected HttpClient
     * @param createClientURL The URL that shall be given by the application using this module
     */
    constructor(httpClient: HttpClient, @Inject(CREATE_CLIENT_URL) protected createClientURL: string)
    {
        super(httpClient, createClientURL);
    }

    /**
     * Register a user from the filled infos from the register form.
     * @param registerClientRequest
     */
    public register(registerClientRequest: RegisterClientRequest): Observable<Client>
    {
        return this.httpClient.post<Client>(this.createClientURL, registerClientRequest);
    }

    public buildClientRegisterError(err: HttpErrorResponse): RegisterClientError
    {
        const error = super.createApiError(err);
        return new RegisterClientError(error.type, error.description, error.httpStatus, error.extraParams);
    }
}
