/**
 * Created by Adrien Dos Reis on 05/07/2023
 */
import { OAuthToken } from 'msl-login';
import { ConnectableUser } from 'mys-base';

export const FEED_OAUTH_TOKEN_TO_STATE = '[AlfredLoginState] Feed OAuth Token';

/**
 * Action allowing to feed a given OAuthToken to our Login State
 */
export class FeedOauthTokenToStateAction<U extends ConnectableUser>
{
    static readonly type = FEED_OAUTH_TOKEN_TO_STATE;

    constructor(public oauthToken: OAuthToken<U>, public shouldRedirectAfterLogin: boolean = true)
    {
    }
}
