import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientSpecificConfig } from '../../environments/liberty-order/client-specific-config';
import { BASE_API_URL, MysamBaseInterceptor } from 'mys-base';

/**
 * Created by Adrien Dos Reis on 25/02/2022
 *
 * Adds the "companyId" of the ClientSpecificConfig as a new header, to every HTTP request
 */
@Injectable({ providedIn: 'root' })
export class HeaderLibertySourceInterceptor extends MysamBaseInterceptor
{
    // region Constructor

    constructor(@Inject(BASE_API_URL) private BASE_URL: string)
    {
        super(BASE_URL);
    }

    // endregion

    override interceptIfMySamRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        /**
         * We could place the ClientSpecificConfig.companyId into a class attribute instead of reading it
         * every time from ClientSpecificConfig...
         * HOWEVER, if we do that, Typescript will try to instantiate the attribute when creating this
         * HeaderLibertySourceInterceptor, that means before instantiating AppModule (which provides this
         * Interceptor). At this moment, ClientSpecificConfig is not ready yet, and it breaks the whole app (no more
         * translated files nor custom colors !!!!)
         *
         * That means : We need to ensure that ClientSpecificConfig.instance is not null, before adding our custom
         * header
         */
        if (!!ClientSpecificConfig.instance?.companyId)
        {
            req = req.clone({
                headers: req.headers.set('Liberty-Source-Company-Id', ClientSpecificConfig.instance.companyId + "")
            });
        }

        return next.handle(req);
    }
}
