/**
 * Created by Adrien Dos Reis on 29/09/2020
 */
export class DeviceSpecific
{
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
    static isIOSDevice(): boolean
    {
        /**
         * We remove the "window.MSStream" clause, since it is true on Safari iOS (even though it should not be......)
         */
        //@ts-ignore
        return /iPad|iPhone|iPod/.test(navigator.userAgent)/* && !window.MSStream*/;
    }
}