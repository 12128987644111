import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AlfredUser, Client, ConnectableUser } from 'mys-base';
import { Injectable } from '@angular/core';
import { loginInitialState, LogoutAction, MslLoginState, MslLoginStateModel, OAuthToken } from 'msl-login';
import { Navigate } from '@ngxs/router-plugin';

@State<MslLoginStateModel>({
    name: 'clientLoginState',
    defaults: loginInitialState
})
@Injectable()
export class ClientLoginState extends MslLoginState<Client>
{
    // region Selectors

    @Selector()
    static client(state: MslLoginStateModel): Client | null
    {
        return state.oauthTokenMetadata?.oauthToken?.user as Client;
    }

    // region Parent Selectors

    /**
     * Needed, otherwise our State won't work properly...
     */

    @Selector()
    static oauthToken(state: MslLoginStateModel): OAuthToken<Client> | null
    {
        return super.oauthToken(state) as OAuthToken<Client>;
    }

    @Selector()
    static isMySam(state: MslLoginStateModel): boolean | null
    {
        return super.isMySam(state);
    }

    @Selector()
    static isDriver(state: MslLoginStateModel): boolean | null
    {
        return super.isDriver(state);
    }

    @Selector()
    static isSupervisor(state: MslLoginStateModel): boolean | null
    {
        return super.isSupervisor(state);
    }

    // endregion Parent Selectors

    // endregion Selectors

    // region Actions

    @Action(LogoutAction)
    logout(ctx: StateContext<MslLoginStateModel>, _action: LogoutAction)
    {
        this.doLogout(ctx);
        return ctx.dispatch(new Navigate([ '' ]));
    }

    // endregion

    // region Overridden methods

    protected castUserIntoSubclass(user: ConnectableUser): Client
    {
        return Client.assign(user);
    }

    // endregion
}
