import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Client } from 'mys-base';
import { LogoutWithoutRedirectionAction } from 'msl-login';
import { ClientLoginState } from '../../login/login-without-routing/state/client-login.state';
import { Navigate } from '@ngxs/router-plugin';

@Component({
    selector: 'mys-routing-menu',
    templateUrl: './routing-menu.component.html',
    styleUrls: [ './routing-menu.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutingMenuComponent
{
    @Select(ClientLoginState.client) client$: Observable<Client>;

    constructor(private store: Store)
    {
    }

    logout()
    {
        this.store.dispatch(new LogoutWithoutRedirectionAction());
        this.store.dispatch(new Navigate([ '/login' ]));
    }

}
