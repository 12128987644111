import { AfterViewInit, Directive, DoCheck, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { ThemeUtils } from '../utils/theme-utils';

declare let require: any;
declare let $: any;

/**
 * Created by Adrien Dos Reis on 28/05/2020
 *
 * This parent class can be applied to subcomponents to enhance them with a Themeable behavior (they are able to change the Material theme).
 */
@Directive()
export abstract class ThemeableComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy
{
    // region Attributes

    primaryColorPalette: Color[] = [];
    accentColorPalette: Color[] = [];

    /**
     * Reading our palette colors requires we wait for two events :
     * - The View must be initialized (we can call our palette from ngAfterViewInit)
     * - The Client specific config must be loaded
     *
     * We define two booleans to track the status of those two events
     */
    private isViewInitialized$ = new BehaviorSubject<boolean>(false);

    protected subscription = new Subscription();

    // endregion

    // region Constructor

    protected constructor(protected router: Router, @Inject(DOCUMENT) protected document: Document)
    {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            tap((_event: NavigationEnd) =>
            {
                /**
                 * Added by Adrien Dos Reis on 02/06/2021
                 * This Router Event seems like the best place to hide our loader : It is triggered exactly
                 * when our View is displayed.
                 * However, if we just call "$(...).hide()" without "setTimeout", we can see the side menu from
                 * Alfred being opened and closed really quick (and I don't understand why...).
                 * To fix this, we wrap the "$(...).hide()" in a "setTimeout", and that fixes everything ! Thank you
                 * Javascript !
                 */
                setTimeout(() => $('#loader-before-any-angular-display').hide());
            })
        ).subscribe();
    }

    // endregion

    // region Abstract Methods

    /**
     * Returns the hex code of the Primary color of this app
     */
    protected abstract getPrimaryColor(): string

    /**
     * Returns the hex code of the Accent color of this app
     */
    protected abstract getAccentColor(): string

    /**
     * Should the app force the contrast to be bright (true), dark (false), or rely on the color contrast of the
     * primary color (null) ?
     */
    protected abstract forceLightContrast(): boolean | null

    /**
     * Contains the "font path" of the Font that should be used throughout the app.
     * Returned as an Observable in order to wait for the ClientSpecificConfig to been loaded
     */
    protected abstract getFontPathFromClientSpecificConfig$(): Observable<string>

    // endregion

    /**
     * The "fontPath" can vary between our apps (and between the subclasses of this ThemeableComponent), and should
     * be passed as parameter here
     */
    private loadThemeAndFont(fontPath: string)
    {
        this.setPrimaryColorAndCreatePalette();
        this.setAccentColorAndCreatePalette();
        this.setCustomFont(fontPath);
    }

    /**
     * Changes the font of the whole app using the given "fontPath" as our "CustomFont"
     */
    private setCustomFont(fontPath: string)
    {
        const newStyle = this.document.createElement('style');
        newStyle.appendChild(this.document.createTextNode("\
        @font-face {\
            font-family: CustomFont;\
            src: url('" + fontPath + "') format('truetype');\
        }\
        "));

        this.document.head.appendChild(newStyle);
    }

    // region Colors & Themes

    /**
     * Calls "getPrimaryColor()", then creates the palette from it and sets it as the primary color of our app
     */
    setPrimaryColorAndCreatePalette()
    {
        this.primaryColorPalette = new ThemeUtils(this.forceLightContrast()).getPaletteFromColor(this.getPrimaryColor());
        this.createPalette(this.primaryColorPalette, 'primary');
    }

    /**
     * Calls "getAccentColor()", then creates the palette from it and sets it as the accent color of our app
     */
    setAccentColorAndCreatePalette()
    {
        this.accentColorPalette = new ThemeUtils(this.forceLightContrast()).getPaletteFromColor(this.getAccentColor());
        this.createPalette(this.accentColorPalette, 'accent');
    }

    /**
     * Creates a Material palette based on the array of "colors", and for the given "theme" ("primary" or "accent").
     * Practically, it means that the method defines each color as a CSS variable (to match the CSS variables expected
     * in "msl-shared-styles/custom-theming.scss")
     */
    private createPalette(colors: Color[], theme: string)
    {
        colors.forEach(color =>
        {
            document.documentElement.style.setProperty(
                `--theme-${theme}-${color.name}`,
                color.hex
            );
            document.documentElement.style.setProperty(
                `--theme-${theme}-contrast-${color.name}`,
                color.darkContrast ? 'rgba(black, 0.87)' : 'white'
            );
        });
    }

    // endregion

    // region Lifecycle

    ngOnInit()
    {
        /**
         * Waits for the ClientSpecificConfig to be loaded (to get the font) and for the view to be initialized, then
         * loads the custom theme and fonts
         */
        this.subscription.add(combineLatest(this.getFontPathFromClientSpecificConfig$(), this.isViewInitialized$).pipe(
            filter(([fontPath, isViewLoaded]) => !!fontPath && !!isViewLoaded),
            map(([fontPath, _]) => this.loadThemeAndFont(fontPath))
        ).subscribe());
    }

    ngDoCheck()
    {
        /**
         * https://mysamcab.atlassian.net/browse/MYS-6222
         * See https://stackoverflow.com/a/50192615/2294082
         * On every Change Detection check, we want to gather all inputs that don't have the "data-lpignore" attribute,
         * and set it to "true" to prevent LastPass from automatically filling them
         */
        const elements = document.querySelectorAll("input:not([data-lpignore])");
        // @ts-ignore
        for (let element of elements)
        {
            element.setAttribute("data-lpignore", "true");
        }
    }

    ngAfterViewInit()
    {
        this.isViewInitialized$.next(true);
    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }

    // endregion
}

/**
 * Util interface to represent a color variation
 */
export interface Color
{
    name: string;
    hex: string;
    darkContrast: boolean;
}
