import { Action, State, StateContext } from '@ngxs/store';
import { AddressService } from '../../services/address.service';
import { AddressesState, AddressesStateModel } from './addresses.state';
import { SearchAddresses } from '../actions/addresses.action';
import { AddressType } from '../../constants/address-type';

import { Injectable } from '@angular/core';

@State({
    name: 'fromAddresses',
    defaults: AddressesState.initDefaultState()})
@Injectable()
export class FromAddressesState extends AddressesState
{
    constructor(addressService: AddressService)
    {
        super(addressService);
    }

    @Action(SearchAddresses)
    searchAddresses(ctx: StateContext<AddressesStateModel>, action: SearchAddresses)
    {
        if (action.addressType === AddressType.FROM)
        {
            return this.doSearchAddresses(ctx, action);
        }
    }
}
