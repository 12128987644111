import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Created by Adrien Dos Reis on 07/11/2018
 * Simple <select> allowing to display and select some raw data within a user-friendly UI
 */
@Component({
    selector: 'msl-simple-select',
    templateUrl: './msl-simple-select.component.html'
})
export class MslSimpleSelectComponent
{
    @Input() placeholder: string; // The select placeholder
    @Input('data') iterableData: Iterable<any>; // The data to display in the select (will be displayed as such, without any transformation)
    @Input() width: string; // The total width of the element (expressed as such : '80px')

    /**
     * Two-way data binding
     */
    @Input() model: any; // The models bound to the select
    @Output() modelChange = new EventEmitter<any>(); // The event allowing to track models changes
}
