import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { MslVehicleRadioItemComponent } from '../vehicle-radio-item/msl-vehicle-radio-item.component';
import { VehicleType } from 'mys-base';

@Component({
    selector: 'msl-vehicle-radio',
    templateUrl: './msl-vehicle-radio.component.html',
    styleUrls: [ './msl-vehicle-radio.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslVehicleRadioComponent implements AfterViewInit, OnChanges
{
    // region Attributes

    VehicleType = VehicleType;
    carTooltipKey: string | null = null;
    greenTooltipKey: string | null = null;
    motoTooltipKey: string | null = null;
    primeTooltipKey: string | null = null;
    vanTooltipKey: string | null = null;

    // endregion

    // region ViewChildren

    @ViewChildren(MslVehicleRadioItemComponent) vehicleRadioItems: QueryList<MslVehicleRadioItemComponent>;
    @ViewChild('carRadioItem', { read: ElementRef }) carRadioItem: ElementRef;

    // endregion

    // region Inputs/Outputs

    @Input() displayTooltips = false;
    @Input() disabled = false;
    @Input() vehicleType = VehicleType.CAR;
    @Output() vehicleTypeSelected = new EventEmitter<VehicleType>();

    // endregion

    // region Constructor

    constructor(private cdRef: ChangeDetectorRef)
    {
    }

    // endregion

    // region Update UI

    /**
     * When the window is resized, we want to trigger a change detection, in order to recalculate the width
     * of our car radio item
     */
    detectChanges()
    {
        this.cdRef.detectChanges();
    }

    /**
     * Updates the value of the "...tooltipKey" attributes, if the @Input "displayTooltips" is true
     */
    private displayTooltipsIfNeeded()
    {
        if (this.displayTooltips)
        {
            this.carTooltipKey = 'vehicle-type.CAR-TOOLTIP';
            this.greenTooltipKey = 'vehicle-type.GREEN-TOOLTIP';
            this.motoTooltipKey = 'vehicle-type.MOTO-TOOLTIP';
            this.primeTooltipKey = 'vehicle-type.PRIME-TOOLTIP';
            this.vanTooltipKey = 'vehicle-type.VAN-TOOLTIP';
        }
    }

    // endregion

    /**
     * The 3 elements at the top (CAR / VAN / PRIME) expand in order to take each 1/3 of the available space
     * We want the two elements at the bottom to be the same size than the 3 elements at the top.
     * This method gets the width of the CAR radio item (but it would work the same with the VAN or PRIME radio item),
     * in order to apply it on the two elements at the bottom
     */
    getWidthOfCarRadioItem(): string
    {
        if (!!this.carRadioItem)
        {
            return this.carRadioItem.nativeElement.clientWidth + 'px';
        }

        return '0px';
    }

    /**
     * Selects the given MslVehicleRadioItemComponent and resets the others (if they were highlighted by a previous selection)
     * @param selectedVehicleType The VehicleType wanted
     */
    selectItemAndResetOthers(selectedVehicleType: VehicleType)
    {
        this.vehicleRadioItems.forEach((vehicleRadioItem: MslVehicleRadioItemComponent) =>
            vehicleRadioItem.selected = vehicleRadioItem.vehicleType === selectedVehicleType);
        this.vehicleTypeSelected.emit(selectedVehicleType);
    }

    // region Lifecycle

    ngAfterViewInit()
    {
        // Initial value
        this.selectItemAndResetOthers(this.vehicleType);

        this.displayTooltipsIfNeeded();
    }

    ngOnChanges(changes: SimpleChanges)
    {
        if ('vehicleType' in changes && !changes.vehicleType.isFirstChange()) // If the change occurred on the "vehicleType" property
        {
            this.selectItemAndResetOthers(changes.vehicleType.currentValue);
        }
    }

    // endregion
}
