export class MySamPair<T, S>
{
    private readonly _first: T;
    private readonly _second: S;

    constructor(first: T, second: S)
    {
        this._first = first;
        this._second = second;
    }


    public get first(): T
    {
        return this._first;
    }

    public get second(): S
    {
        return this._second;
    }
}
