import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

/**
 * Created by Adrien Dos Reis on 17/02/2020
 * Dumb component allowing to easily create a MatSpinner centered horizontally
 */

@Component({
    selector: 'msl-centered-spinner',
    templateUrl: './centered-spinner.component.html'
})
export class CenteredSpinnerComponent
{
    @Input() rowClass: string | null;
    @Input() color: ThemePalette = 'primary';
}
