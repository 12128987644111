<mat-form-field>

    <mat-label>{{ label }}</mat-label>
    <!--
            Input component
            The "disabled" attribute is handled through the "formControl" attribute
    -->
    <input matInput type="text" [matAutocomplete]="auto" [formControl]="inputCtrl">
    <mat-error>{{ errorKey | translate }}</mat-error>

    <!-- "Close" button to clear the input -->
    <button *ngIf="input && !disabled" matSuffix mat-icon-button (click)="onInputCleared()"
            [attr.aria-label]="'estimation.CLEAR' | translate">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<!-- Autocomplete component -->
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)"
                  [displayWith]="displayFunctionInInput">
    <mat-option *ngFor="let value of values" [value]="value">
        {{ displayFunctionInOption(value) }}
    </mat-option>
</mat-autocomplete>
