/**
 * Created by Adrien Dos Reis on 20/01/2020
 */
import { $enum } from 'ts-enum-util';
import { VehicleType } from './constants/vehicle-type';

export class ZoneSurcharge
{
    // region Attributes

    id: number;
    vehicleType: VehicleType;
    zdSurcharge: number;
    zdThreshold: number;

    // Attributes from ShapefileZone
    zoneId: number;
    zoneName: string;

    // endregion

    /**
     * Deep casts (manually, so all fields might not be casted) the given "uncastedZoneSurcharge"
     * @param uncastedZoneSurcharge
     */
    static assign(uncastedZoneSurcharge: any): ZoneSurcharge
    {
        const zoneSurcharge: ZoneSurcharge = Object.assign(new ZoneSurcharge(), uncastedZoneSurcharge);
        zoneSurcharge.vehicleType = $enum(VehicleType).getValueOrDefault(zoneSurcharge.vehicleType);

        return zoneSurcharge;
    }
}
