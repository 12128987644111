import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CenteredSpinnerComponent } from './centered-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const components = [ CenteredSpinnerComponent ];
const material = [ MatProgressSpinnerModule ];

@NgModule({
    declarations: [ ...components ],
    imports: [
        CommonModule,
        ...material
    ],
    exports: [ ...components ]
})
export class MslCenteredSpinnerModule
{
}
