import { Injectable } from '@angular/core';
import { UserSource } from 'mys-base';
import { ClientSpecificConfig } from '../../../environments/liberty-order/client-specific-config';
import { UserSourceGenerator } from '../../../../../mys-base/src/lib/services/interfaces/UserSourceGenerator';

/**
 * Created by Adrien Dos Reis on 28/09/2020
 *
 * This class is used to generate the UserSource from the current ClientSpecificConfig, and to be injected in the
 * appropriate services (which don't know about Liberty Order or our ClientSpecificConfig)
 */
@Injectable({ providedIn: 'root' })
export class LibertyOrderUserSourceGenerator implements UserSourceGenerator
{
    getSource(): UserSource
    {
        return !!ClientSpecificConfig.instance.companyId ? UserSource.LIBERTY_ORDER : UserSource.WEBSITE
    }
}