import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslSelectOnboardPaymentMethodComponent } from './msl-select-onboard-payment-method.component';
import { MslOnboardPaymentItemComponent } from './payment-method-item/msl-onboard-payment-item.component';
import { TranslateModule } from '@ngx-translate/core';

const components = [ MslSelectOnboardPaymentMethodComponent, MslOnboardPaymentItemComponent ];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [ ...components ],
    exports: [ ...components ],
})
export class MslSelectOnboardPaymentMethodModule
{
}
