import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { environment } from '../environment';
import { catchError } from 'rxjs/operators';

/**
 * Created by Adrien Dos Reis on 29/05/2020
 */
export class ClientSpecificConfig
{
    // region Attributes

    /**
     * Will be set at runtime (in AppComponent) based on the current theme
     */
    static instance: ClientSpecificConfig;

    // endregion

    // region Constructor

    constructor(public primaryColor: string, public accentColor: string, public backgroundLayoutColor: string,
                public fleetName: string, public companyId: number | null,
                public shouldDisplayHeader: boolean, public backgroundColor: string, public pathToLogo: string,
                public pathToFavicon: string, public shouldDisplayMaps: boolean, public fontPath: string,
                public isLightContrast: boolean | null = null, public shouldTitleBeBold: boolean,
                public mobilePhoneNumber: string | null, public emailAddress: string | null,
                public presentationText: string | null, public shouldShowPhoneButton: boolean,
                public shouldShowSmsButton: boolean, public shouldShowMailButton: boolean)
    {
    }

    // endregion

    // region Static Methods

    static initCurrentConfig(hostname: string, httpClient: HttpClient): Observable<ClientSpecificConfig>
    {
        /**
         * Extracting the config name from the hostname, and gets the appropriate JSON configuration file name :
         * If the extraction returns an empty string, gets the JSON configuration file called "mysam.json"
         */
        console.log('hostname', hostname);
        const configFileName = (ClientSpecificConfig.extractConfigNameFromHostname(hostname) || 'mysam') + '.json';
        // const configFileName = (ClientSpecificConfig.extractConfigNameFromHostname(hostname) || 'abvtcpremiums') + '.json';
        console.log('configFileName', configFileName);

        /**
         * Loading asynchronously the content of the JSON configuration file
         *
         * We force the assignation as a ClientSpecificConfig, in order to handle any default or missing parameters
         * (that would then fallback to our default values, if any)
         */
        return httpClient.get(environment.SPECIFIC_CONFIG_URL + '/assets/json-configurations/' + configFileName).pipe(
            map(file =>
            {
                ClientSpecificConfig.instance = ClientSpecificConfig.assign(file);
                console.log('client config', ClientSpecificConfig.instance);
                return ClientSpecificConfig.instance;
            }),
            catchError((error, _caught) =>
            {
                ClientSpecificConfig.instance = ClientSpecificConfig.assign(error);
                console.log('Error while reading the Specific Config', error);
                return of(error);
            })
        );
    }

    /**
     * Extracts a config name from the URL used to access our app.
     * The main purpose is to extract, for example, "chauffeurtoulousain" from the URL
     * "chauffeurtoulousain.libertyorder.fr".
     * The URL can also be prepended with "demo" (e.g. demo.libertyorder.fr, demo.chauffeurtoulousain.libertyorder.fr),
     * in this case we want to remove the prepended string
     *
     * Finally, if no config name could be extracted (for example, in the URLs "libertyorder.fr",
     * "demo.libertyorder.fr", ...), we return an empty string
     *
     * @param hostname The complete URL used to access this Angular app
     */
    static extractConfigNameFromHostname(hostname: string): string
    {
        /**
         * First, we remove the server name (libertyorder.fr / mysam.fr / localhost)
         * If we are on any of those domains (without any subdomains), hostname is now an empty string
         *
         * Be careful ! In all other cases, hostname will have a trailing '.'
         */
        hostname = hostname.replace('libertyorder.fr', '')
            .replace('mysam.fr', '')
            .replace('localhost', '');

        /**
         * We remove any trailing dot(s) that might remain (there is none if we are on libertyorder.fr, but there is one
         * if we are on XXX.libertyorder.fr)
         */
        return hostname.replace(RegExp('\\.*$'), '');

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // The following block is supposed to remove any subdomain (demo.XXX...)
        // We comment it out for now, in order to have separate JSON files for separate environments (demo.XXX.json &
        // XXX.json are separate files)
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // /**
        //  * Now, we remove any subdomain name before our config name (demo.XXX should become XXX), and
        //  * we return the result
        //  * Regex : Removes any number of non-whitespace characters, following by a dot, and nothing after
        //  */
        // return hostname.replace('\S*\\.$', '');
    }

    /**
     * Gets an uncasted ClientSpecificConfig as parameter, and assigns its attributes to a real ClientSpecificConfig
     * object's attributes
     */
    static assign(csc: any): ClientSpecificConfig
    {
        /**
         * We extract all the attributes we are interested in, and we set some default placeholder values for
         * non-nullable attributes.
         * They should probably never be used (if they are, that probably means there is a configuration mistake from
         * our side), but they are here to ensure that the app does not crash with a blank screen if one configuration
         * attribute is missing from one JSON file
         */
        return new ClientSpecificConfig(
            csc.primaryColor ?? '#D3D3D3',
            csc.accentColor ?? '#D3D3D3',
            csc.backgroundLayoutColor ?? 'lightgrey',
            csc.fleetName ?? 'default',

            /**
             * Nullable values
             * If not specified, they are undefined. We need to force them to null if they are not in the JSON file
             */
            csc.companyId ?? null,

            csc.shouldDisplayHeader ?? false,
            csc.backgroundColor ?? 'white',

            csc.pathToLogo ?? null, csc.pathToFavicon ??'assets/img/favicon-default.png',

            csc.shouldDisplayMaps ?? true, csc.fontPath ?? null,
            csc.isLightContrast ?? null, csc.shouldTitleBeBold ?? false,
            csc.mobilePhoneNumber ?? null,
            csc.emailAddress ?? null,
            csc.presentationText ?? null,
            csc.shouldShowPhoneButton ?? false,
            csc.shouldShowSmsButton ?? false,
            csc.shouldShowMailButton ?? false);
    }

    // endregion
}
