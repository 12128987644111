export class LatLng
{
    private readonly _latitude: number;
    private readonly _longitude: number;

    constructor(latitude: number, longitude: number)
    {
        this._latitude = latitude;
        this._longitude = longitude;
    }

    get lat(): number
    {
        return this._latitude;
    }

    get lng(): number
    {
        return this._longitude;
    }
}
