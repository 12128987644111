/**
 * Representation of our backend's OAuth Token : An access token, a refresh token, and the actual user that is
 * logged in
 */
import { ConnectableUser } from 'mys-base';

export class OAuthToken<U extends ConnectableUser>
{
    access_token: string;
    refresh_token: string;
    user: U;

    static assign<U extends ConnectableUser>(uncastedOAuth: any, howToCast: (user: ConnectableUser) => U): OAuthToken<U>
    {
        const oauth = Object.assign(new OAuthToken<U>(), uncastedOAuth);
        oauth.user = howToCast(uncastedOAuth.user);

        return oauth;
    }
}
