import { MobileUser } from './mobile-user';
import { Company } from '../company';
import { ConnectableUser } from './connectable-user';

export class Client extends MobileUser
{
    // region Attributes

    /**
     * UPDATE 16/07/2019
     * https://mysamcab.atlassian.net/browse/MYS-3577
     * To avoid backwards compatibility issues, the backend does not send the Company of the Client through the "company" field if the
     * Company is not Premium.
     * To handle this as simply as possible, we define a new attribute "companyEvenIfNotPremium" which will replace "company" in our
     * codebase, and the old "company" field can still be used to know whether the "company" is premium or not
     */
    companyEvenIfNotPremium: Company | null; // MAIN ATTRIBUTE TO USE NOW
    referrer: MobileUser | null;
    zeroDecimalCashBackAmount: number;
    isReferredByGroupOfDrivers = false;

    /**
     * Attribute forced to "true", and present only in the Client class.
     * This way, we will be able to know whether a given MobileUser is a Client or not.
     */
    isClient = true;

    companyName: string | null = null;

    // endregion

    /**
     * Needed for OAuthToken to call dynamically the appropriate "assign" method based on the correct
     * ConnectableUser subtype
     */
    assign(unassignedClient: any): Client
    {
        return Client.assign(unassignedClient)
    }

    // region Static Methods

    static assign(unassignedClient: any): Client
    {
        /**
         * Calling the "parent" assign method
         */
        const mobileUser = MobileUser.assign(unassignedClient);

        /**
         * Now, processing the attributes related to a Client
         */
        const client = Object.assign(new Client(), mobileUser);

        /**
         * Nullable attributes
         */
        if (!!unassignedClient.referrer)
        {
            client.referrer = MobileUser.assign(unassignedClient.referrer);
        }

        return client;
    }

    /**
     * This method returns a boolean indicating whether the Company of the Client (if any) is a Premium one
     *
     * Since Javascript is still a dumb language in 2019, we can't use Object.assign() without losing ALL THE METHODS defined in the
     * Model Entity
     * In order to overcome this issue, we define a STATIC METHOD like in the good ol' days of "not-object" programming #backtothe80s
     */
    static isPremium(client: Client): boolean
    {
        /**
         * If the "company" field is filled, that means this Company is premium (otherwise, only "companyEvenIfNotPremium" would be filled)
         */
        return !!client.company;
    }

    /**
     * Returns a boolean indicating whether the given "maybeClient" is a Client or not
     * @param maybeClient
     */
    static isClient(maybeClient: any): boolean
    {
        /**
         * Checking if "maybeClient" is a Client consists in two things :
         * - "maybeClient" is a ConnectableUser (at least)
         * - "maybeClient" has the custom "isClient" attribute
         */
        return maybeClient instanceof ConnectableUser && !!(maybeClient as Client).isClient;
    }

    // endregion
}
