/**
 * Created by Adrien Dos Reis on 08/03/2019
 *
 * Representation of a Language
 *
 * It has a code (handleable by ngx-translate), a name, a HERECode that represents the code handleable by HERE
 * (which is not usually the same than the code for ngx-translate) and a locale that manage the display
 * of all type of strings (dates, times, pipes, etc...)
 *
 * An exhaustive list of HERE languages can be found here : https://developer.here.com/documentation/map-tile/topics/resource-info.html
 * (look for "languages")
 */
export class Language
{
    private _code: string;
    private _name: string;
    private _HERECode: string;
    private _locale: any;

    constructor(code: string, name: string, HERECode: string, locale: any)
    {
        this._code = code;
        this._name = name;
        this._HERECode = HERECode;
        this._locale = locale;
    }

    get code(): string
    {
        return this._code;
    }

    get name(): string
    {
        return this._name;
    }

    get HERECode(): string
    {
        return this._HERECode;
    }

    get locale(): any
    {
        return this._locale;
    }
}
