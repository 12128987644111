import {ChangeDetectorRef, Optional, Pipe, PipeTransform} from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MysamLanguageHolder } from './mysam-language-holder';
import { WordReplacer } from '../word-replacer';

/**
 * Displays a DriverDetails object, or an empty string if an empty driver details
 * object was passed to the "transform" method
 */
@Pipe({
    name: 'mysamTranslate',
    pure: false // Needed since the TranslatePipe is impure
})
export class MysamTranslatePipe extends TranslatePipe implements PipeTransform
{
    // region Constructor

    constructor(@Optional() private wordReplacerService: WordReplacer | null, private languageHolder: MysamLanguageHolder,
                private translatee: TranslateService, changeDetectorRef: ChangeDetectorRef)
    {
        super(translatee, changeDetectorRef);
    }

    // endregion

    transform(query: string, ...args: any[]): string
    {
        // args[0] contains the optional parameters if the string needs some interpolation
        const translated: string = super.transform(query, args[0]);

        /**
         * UPDATE 24/01/2020
         * In Liberty Driver, the Home Page might be loaded while the language is not ready yet
         * To avoid NPEs, we replace the terms only if the "currentLang" is set up.
         * Ideally, we should understand why "currentLang" is not defined, but I don't have the time :)
         */
        const language = this.languageHolder.currentLang;
        return !!language && !!this.wordReplacerService
          ? this.wordReplacerService.replaceTerms(translated, language.name)
          : translated;
    }
}
