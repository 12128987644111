import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Created by adrien.dosreis on 24/07/2023
 *
 * Applying some of our Interceptors to non-MySam requests might cause them to break
 * For example, adding our custom headers to Google Map JSONP request in EstimationWithGoogleMapComponent breaks
 * the request. Potentially, other requests to Stripe or Here might fail as well if they don't recognize our headers.
 *
 * To avoid that, we define this base interceptor which provides a "interceptIfMySamRequest" method, only called
 * if the requests contains the "baseUrl". Otherwise, the interceptor doesn't do anything
 */
export abstract class MysamBaseInterceptor implements HttpInterceptor
{
    protected constructor(private baseUrl: string)
    {
    }

    /**
     * To be defined by subclasses - Called only if "req" contains "baseUrl"
     */
    abstract interceptIfMySamRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>

    /**
     * Overrides the default "intercept", in order to apply an interception mechanism only if the "req.url"
     * contains "baseUrl"
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (req.url.includes(this.baseUrl))
        {
            return this.interceptIfMySamRequest(req, next)
        }
        else
        {
            return next.handle(req);
        }
    }
}
