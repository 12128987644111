import { NgModule } from '@angular/core';
import { MysamTranslateModule, MysamTranslateService } from 'msl-translate';

const modules = [ MysamTranslateModule ];

/**
 * Util module allowing to aggregate common behavior between Client and Driver registration
 * (for now : Only some words/translations shared)
 */
@NgModule({
    imports: [...modules],
    exports: [...modules]
})
export class MslRegisterModule
{
    /**
     * First steps towards a mechanism to provide translations directly from within our components modules :
     *
     * It is not possible to provide a JSON file from inside msl-components to be used by Alfred, RegisterLm,
     * LibertyOrder, etc... because this JSON file must be inside the final application. Therefore, we used to copy
     * the translations in each project (that's not good).
     * To avoid this issue, each component module (here, DriverSignupModule) will provide its own translations directly
     * through the MysamTranslateService.
     */
    constructor(translate: MysamTranslateService)
    {
        translate.setTranslation('fr', {
                "words": {
                    "EMAIL": "Email",
                    "FIRST-NAME": "Prénom",
                    "LAST-NAME": "Nom",
                    "MOBILE": "N° de mobile",
                    "PASSWORD": "Mot de passe",
                    "PASSWORD-CONFIRM": "Confirmer le mot de passe",
                    "PREFIX": "Préfixe",
                    "SPONSOR-CODE": "Code parrainage"
                }
            }
        );

        translate.setTranslation('en', {
                "words": {
                    "EMAIL": "Email",
                    "FIRST-NAME": "First name",
                    "LAST-NAME": "Last name",
                    "MOBILE": "Mobile number",
                    "PASSWORD": "Password",
                    "PASSWORD-CONFIRM": "Confirm password",
                    "PREFIX": "Prefix",
                    "SPONSOR-CODE": "Sponsor code"
                }
            }
        );
    }
}
