import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgxFaviconService } from 'ngx-favicon';
import { ThemeableComponent } from 'mys-base';
import { DOCUMENT } from '@angular/common';
import { ClientSpecificConfig } from '../../../environments/liberty-order/client-specific-config';
import { DeviceSpecific } from '../../../mysam/global/utils/device-specific';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';

@Component({
    selector: 'mys-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AppComponent extends ThemeableComponent implements OnInit, AfterViewInit
{
    // region Attributes

    fontPathFromClientSpecificConfig$ = new BehaviorSubject<string | null>(null);

    // endregion

    // region Constructor

    /**
     * Here, we use the Title services from Angular in order to set the <title> tag dynamically
     * @see https://angular.io/guide/set-document-title
     *
     * We also use the "ngx-favicon" library to set the Favicon dynamically
     */
    public constructor(private titleService: Title, private ngxFaviconService: NgxFaviconService<any>,
                       private httpClient: HttpClient, private meta: Meta, router: Router,
                       @Inject(DOCUMENT) document: Document)
    {
        super(router, document);

        /**
         * http://kihlstrom.com/2015/shrink-to-fit-no-fixes-zoom-problem-in-ios-9/
         * On Safari iOS, the page is slightly zoomed in, and that's ugly.
         * Based on this link, it is a bug that can be fixed by adding "shrink-to-fit=no" to the "meta viewport" tag
         * It is Safari specific, so it should not change the behavior on other browsers
         *
         * Safari also zooms in when an input field is focused, if the font-size is < 16px (that's the case in our app).
         * To prevent this behavior, the Internet advises to set "maximum-scale=1.0". However, it would prevent
         * Android devices from zooming in (https://stackoverflow.com/a/57527009/2294082)
         * To solve this, we only add this meta tag on iOS devices
         */
        const commonTag = 'width=device-width, initial-scale=1.0, minimum-scale=1.0';
        const suffixTag = DeviceSpecific.isIOSDevice() ? ', shrink-to-fit=no, maximum-scale=1.0' : '';

        this.meta.addTag({ name: 'viewport', content: commonTag + suffixTag });
    }

    // endregion

    // region Overridden methods (theme-specific)

    protected getPrimaryColor(): string
    {
        return ClientSpecificConfig.instance.primaryColor;
    }

    protected getAccentColor(): string
    {
        return ClientSpecificConfig.instance.accentColor;
    }

    protected forceLightContrast(): boolean | null
    {
        return ClientSpecificConfig.instance.isLightContrast;
    }

    protected getFontPathFromClientSpecificConfig$(): Observable<string>
    {
        return this.fontPathFromClientSpecificConfig$;
    }

    // endregion

    // region Lifecycle

    ngOnInit()
    {
        super.ngOnInit();

        this.subscription.add(
            ClientSpecificConfig.initCurrentConfig(this.document.location.hostname, this.httpClient)
                .subscribe(config =>
                {
                    this.fontPathFromClientSpecificConfig$.next(config.fontPath);
                    this.titleService.setTitle(config.fleetName);
                    this.ngxFaviconService.setFaviconByUrl(config.pathToFavicon);

                    /**
                     * update meta attributes
                     */
                    this.meta.addTag({ name: 'image', content: !!ClientSpecificConfig.instance.companyId ? config.pathToFavicon : "assets/img/liberty-order/logo_liberty_order.png" });

                    /**
                     * A few tags defined in French here, to help with the SEO
                     */
                    this.meta.addTag({ name: 'title', content: !!ClientSpecificConfig.instance.companyId ? config.fleetName : "Liberty Order by MySam - Application de commande de VTC à" +
                            " Toulouse, Bordeaux, Paris, Lille, Nice, Lyon..." });

                    if(!ClientSpecificConfig.instance.companyId) // only for MySam
                    {
                        let descriptionTextIsMySam = "Liberty Order est la première application de commande de VTC adressée aux chauffeurs !" +
                            "En quelques instants, créez votre application, avec vos couleurs, et permettez à vos clients de vous commander en exclusivité !" +
                            "Appuyez-vous sur le réseau MySam de plus de 6500 chauffeurs sur 200 villes, et déployez votre application de commande de VTC sur tout le" +
                            "territoire français (Toulouse, Bordeaux, Paris, Lille, Nice, Lyon...)";
                        this.meta.addTag({ name: 'description', content: descriptionTextIsMySam })
                    }

                    /** Here we add a <link> element to our DOM in order to support the favicon on ios
                     * We add it here because the path of the favicon will be defined dynamically
                     * See this jira ticket for more information : https://mysamcab.atlassian.net/browse/MYS-4911
                     *
                     * Warning IOS:
                     * the favicon with a transparent background will automatically be changed to black,
                     * to overcome this we have to look for solutions but the only one being to put
                     * the image on a white/colored background by yourself
                     * See this for example :
                     * https://realfavicongenerator.net/blog/apple-touch-icon-turns-black/
                     * */
                    const newLinkElementForIosFavicon = this.document.createElement('link');
                    newLinkElementForIosFavicon.rel = "apple-touch-icon";
                    newLinkElementForIosFavicon.href = config.pathToFavicon;

                    this.document.head.appendChild(newLinkElementForIosFavicon);
                })
        );
    }

    // endregion
}
