import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';

const pipes: any[] = [ AddressPipe ];

@NgModule({
    imports: [],
    declarations: [ ...pipes ],
    exports: [ ...pipes ],
    providers: [ ...pipes ]
})
export class PipesModule
{
}
