/**
 * Created by Adrien Dos Reis on 02/01/2019
 */
export enum UserSource
{
    API = 'enums.user-source.API',
    APP = 'enums.user-source.APP',
    B2B_ALFRED = 'enums.user-source.B2B_ALFRED',
    LIBERTY_DRIVER = 'enums.user-source.LIBERTY_DRIVER',
    LIBERTY_MOBILE = 'enums.user-source.LIBERTY_MOBILE',
    LIBERTY_ORDER = 'enums.user-source.LIBERTY_ORDER',
    MYSAM = 'enums.user-source.MYSAM',
    WEBSITE = 'enums.user-source.WEBSITE',
    XLSX_BATCH = 'enums.user-source.XLSX_BATCH'
}
