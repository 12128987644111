import { Pipe, PipeTransform } from '@angular/core';
import { MysamLanguageHolder } from 'msl-translate';

/**
 * Converts a zero decimal value into a given currency
 * Currently supports only euros
 */
@Pipe({
    name: 'zeroDCurrency'
})
export class ZeroDCurrencyPipe implements PipeTransform
{
    constructor(private languageHolder: MysamLanguageHolder)
    {
    }

    transform(amount: number, currency: string = '€'): string
    {
        /**
         * We currently support only euros, but this method is extensible
         */
        switch (currency)
        {
            case '€':
            {
                return new Intl.NumberFormat(
                    this.languageHolder.currentLang.code, { style: 'currency', currency: 'EUR' }
                ).format(amount / 100.0);
            }
        }

        return '';
    }
}
