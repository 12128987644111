/**
 * Created by Adrien Dos Reis on 07/08/2019
 *
 * Prefixed by "MS" to avoid conflicts with Javascript's Location
 */
export class MSLocation
{
    constructor(public latitude: number, public longitude: number, public locationDate: Date,
                public areaCode: number | null = null)
    {
    }
}
