import { SpringAuthority } from '../constants/spring-authority';
import { $enum } from 'ts-enum-util';

/**
 * Created by Adrien on 14/08/2019
 */
export class ConnectableUser
{
    // region Attributes

    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    enabled: boolean;
    created: Date;
    lastLoginDate: Date;

    /**
     * Authorities from Spring Security
     */
    authorities: SpringAuthority[];

    // endregion

    /**
     * Needed for OAuthToken to call dynamically the appropriate "assign" method based on the correct
     * ConnectableUser subtype
     */
    assign(uncastedConnectableUser: any): ConnectableUser
    {
        return ConnectableUser.assign(uncastedConnectableUser)
    }

    /**
     * Deep casts (manually, so all fields might not be casted) the given "uncastedConnectableUser"
     * @param uncastedConnectableUser
     */
    static assign(uncastedConnectableUser: any): ConnectableUser
    {
        const connectableUser: ConnectableUser = Object.assign(new ConnectableUser(), uncastedConnectableUser);

        /**
         * Handling the Authorities here
         * Some might be unknown (since there is other Authorities than the ones for Alfred), in this case we don't map them
         */
        if (!!uncastedConnectableUser.authorities)
        {
            /**
             * The SpringAuthorities are wrapped in an array of { authority: xxx } objects.
             * We want to get the "xxx" value and map it to out SpringAuthority enum
             */
            connectableUser.authorities = uncastedConnectableUser.authorities

            // We get the "authority" attribute
                .map((authObject: { authority: string }) => authObject.authority)

            /**
             * We ensure it can be mapped to a valid value (since some authorities on backend-side might not be of interest for Alfred)
             * We avoid using "!!$enum...", because $enum(...) can be equals to 0 (and that's a valid value).
             * So we have to check explicitly if $enum... is !== undefined
             */
                .filter((authority: string) => $enum(SpringAuthority).getValueOrDefault(authority) !== undefined)

                // And if we have a valid value, we put it in the array
                .map((authority: string) => $enum(SpringAuthority).getValueOrDefault(authority));
        }

        return connectableUser;
    }

    getDisplayName(): string
    {
        return this.firstName + ' ' + this.lastName;
    }
}
