<mat-list-item [ngClass]="active ? 'active' : ''">

            <div class="row m-0 w-100 h-100"
                 (click)="this.clicked.emit(this.vehicleType)">

                <div class="row w-100 align-items-center">

                    <div class="col-3">
                        <img id="vehicleTypeIcon" [src]="getIconFromVehicleType(this.vehicleType)" class="p-1"/>
                    </div>

                    <!--
                            This "col" is defined as a row itself, in order to keep the vehicle name and the passengers
                            info on the same row.
                            However, we remove the default negative margin of a row, by applying the "m-0" class
                    -->
                    <div class="col-6 row m-0">

                        <!--
                                "col-auto" needed on Bootstrap 5, to override the default behavior which would
                                give both the vehicle name and the block below a width of 100% (and therefore, the
                                "nbPassengersAndIcon" block would be displayed below the vehicle name instead of
                                next to it.
                        -->
                        <div id="vehicleTypeName" class="col-auto">{{ this.vehicleType | translate }}</div>

                        <!--
                                "d-flex & align-items-center" -> Vertically align the number of passengers
                                and the icon next to it
                                "col" allows this div to expand in the same row as the "vehicle name", expanding in
                                all the remaining space at the right of the vehicle name
                        -->
                        <div class="ps-1 d-flex align-items-center col">
                            {{ getNbPassengersFromVehicleType(this.vehicleType)}}
                            <img id="passengerIcon"
                                 [src]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAyElEQVRIie2UMQrCMBiFP11108FB5x7FwUk8glcQC97IUnB28DKKTlZoR4fUIX8RQ2xjG4dCH7wlj34v/GkCndqiGRADmfgABD7hCZAbfkjWWLEFXjjyUZCVFKRVH/cbluc+Ck4l2dF9L98VoA/UHM8dmPooAP23ROiZp8DeJ/yvGgJrYGTJxpIN6sJXwA0979CS7yS7Astf4VtA8T7Qp6xNxKGsFbkCNq7wuQF3tQIWLgWXGvDCZxPWsxRU3s4KfTCbPhWdWqAXqAxUU0+GUDEAAAAASUVORK5CYII='"/>
                        </div>
                    </div>

                    <div class="col-3 me-0">
                        <b class="h5 float-end">{{ zdPrice | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}</b>
                    </div>

                </div>

                <div class="row w-100 mx-4 justify-content-center" *ngIf="active">
                    <i>{{ getPresentationTextKeyFromVehicleType(this.vehicleType) | translate }}</i>
                </div>
            </div>

</mat-list-item>
