import { createSelector, State } from '@ngxs/store';
import { GenericState, GenericStateModel } from './generic-state';

export class TranslatedStateModel extends GenericStateModel
{
    translatedError: string | null;
}

import { Injectable } from '@angular/core';

@State({
    name: 'translated',
    defaults: TranslatedState.init()})
@Injectable()
export class TranslatedState
{
    static init(): TranslatedStateModel
    {
        return { ...GenericState.init(), translatedError: null };
    }

    static load(): TranslatedStateModel
    {
        return { ...GenericState.load(), translatedError: null };
    }

    static success(): TranslatedStateModel
    {
        return { ...GenericState.success(), translatedError: null };
    }

    static error(error: string): TranslatedStateModel
    {
        return this.errorAndTranslation(error, error);
    }

    static errorAndTranslation(error: any, translatedErrorAsString: string): TranslatedStateModel
    {
        return { loaded: false, loading: false, error, translatedError: translatedErrorAsString };
    }

    /**
     * SELECTORS
     */

    static translatedErrorSelector<T extends TranslatedStateModel>(stateClass)
    {
        // If the selector is created then returned directly, an errors occurs while "ng build".......
        // @see https://github.com/ng-packagr/ng-packagr/issues/696#issuecomment-387114613
        const result = createSelector([ stateClass ], (state: T) =>
        {
            return state.translatedError;
        });

        return result;
    }
}
