import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { AUTH_SERVICE, AuthModule, PROTECTED_FALLBACK_PAGE_URI, PUBLIC_FALLBACK_PAGE_URI } from 'ngx-auth';
import { AuthenticationService } from './services/authentication.service';
import { TokenStorage } from './services/token-storage.service';

@NgModule({
    imports: [
        AuthModule
    ]
})
export class MslLoginModule
{
    // This method allow 3rd-party apps to specify additional providers (Guards in Alfred)
    static forRoot(additionalProviders: Provider[] = []): ModuleWithProviders<MslLoginModule>
    {
        return {
            ngModule: MslLoginModule,
            providers: [
                AuthenticationService,
                TokenStorage,
                { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
                { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
                { provide: AUTH_SERVICE, useClass: AuthenticationService },
                ...additionalProviders
            ]
        };
    }
}
