import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { RoutingMenuModule } from '../mysam/routing-menu/routing-menu.module';
import { LayoutComponent } from './components/layout/layout.component';
import { DownloadAppHeaderComponent } from './components/download-app-header/download-app-header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutContainerComponent } from './components/layout-container/layout-container.component';
import { LoLayoutComponent } from './components/lo-layout/lo-layout.component';
import { MysamTranslateModule } from 'msl-translate';
import { MatCardModule } from "@angular/material/card";
import {MatTooltipModule} from "@angular/material/tooltip";
import { MslButtonModule } from 'msl-components';

const dependencies = [ DownloadAppHeaderComponent, FooterComponent, HeaderComponent, LayoutComponent,
    LayoutContainerComponent, LoLayoutComponent ];
const materialDependencies = [ MatCardModule, MatSidenavModule, MatTooltipModule ];

@NgModule({
    imports: [
        MslButtonModule,
        MysamTranslateModule,
        SharedModule,
        RoutingMenuModule,
        ...materialDependencies
    ],
    declarations: [ ...dependencies ],
    exports: [
        ...dependencies,
        RouterModule,
        SharedModule,
        ...materialDependencies
    ],

    /**
     * See https://stackoverflow.com/a/63881579 - Option 2
     * Needed to avoid Angular complaining that 'ion-icon' is not a known element.
     */
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LayoutModule
{
}
