import { ApiError } from 'mys-base';
import { MysamTranslateService } from 'msl-translate';

/**
 * Created by Adrien Dos Reis on 23/11/2021
 */
export class LoginError extends ApiError
{
    constructor(apiError: ApiError)
    {
        super(apiError.type, apiError.description, apiError.httpStatus, apiError.extraParams);
    }

    public getErrorMessage(translate: MysamTranslateService): string
    {
        switch (this.httpStatus)
        {
            case 0 : // Server unreachable
                return translate.instant('login-form.errors.SERVER-UNREACHABLE');

            case 400 :  // Bad credentials
                return translate.instant('login-form.errors.BAD-CREDENTIALS');

            default:
                return translate.instant('login-form.errors.DEFAULT');
        }
    }
}
