import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslDateAndVehicleTypeComponent } from './msl-date-and-vehicle-type.component';
import { MslFrenchDatePickerModule } from '../msl-french-date-picker/msl-french-date-picker.module';
import { MslVehicleRadioModule } from '../msl-vehicle-radio/msl-vehicle-radio.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { MslVehicleRadioV2Module } from '../msl-vehicle-radio-v2/msl-vehicle-radio-v2.module';
import { MslTimePickerModule } from '../msl-time-picker/msl-time-picker.module';

const components = [ MslDateAndVehicleTypeComponent ];
const dependencies = [ MslFrenchDatePickerModule, MslTimePickerModule,
    MslVehicleRadioModule, MslVehicleRadioV2Module ];

@NgModule({
    imports: [
        CommonModule,
        ...dependencies,
        TranslateModule,
        MatRadioModule
    ],
    declarations: [ ...components ],
    exports: [ ...components, ...dependencies ]
})
export class MslDateAndVehicleTypeModule
{
}
