// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json.old`.

export const environment = {
    production: false,
    BASE_URL: 'https://api.demo.mysam.fr',
    SPECIFIC_CONFIG_URL: 'https://demo.libertyorder.fr', // Base path to the ClientSpecificConfig JSON files

    googleMapKey: 'AIzaSyBH-jb-ieEksjnh17tJoTt_jireCgIaUNA',    /**
     * Key from jc.ricard@mysam.fr HERE Account (dev key)
     */
    hereApiKey: 'mpRP3apgMo4qTIVAMdUfdvtKI_e-X_TP21f5YwG1cJI'
};
