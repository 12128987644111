import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
    selector: 'msl-swal-buttons',
    templateUrl: './msl-swal-buttons.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Created by Sandra Bénard on 17/05/2023
 * to stop using SweetAlert2 [preConfirm] due to strange behavior
 * like freezing of browser tabs if the method is defined directly in the HTML template or
 * instant closing of the keyboard on Android (https://mysamcab.atlassian.net/browse/MYS-6205)
 *
 * So we decided not to use SweetAlert2 action buttons anymore and to use our own
 */
export class MslSwalButtonsComponent implements OnInit, OnDestroy
{

    // region Attributes

    @Input({ required: true }) confirmBtnText: string;
    @Input({ required: true }) cancelBtnText: string;

    /**
     * If provided, will close the Swal when switched to true
     */
    @Input() closeWhenTrue$: Observable<boolean> = of(false);
    @Input() displayLoader$: Observable<boolean> = of(false);
    @Input({ required: true }) preConfirmFn: () => void;
    @Input() onCancel: () => void = () => {};

    private subscription = new Subscription();

    // endregion

    protected closeSwal()
    {
        Swal.close();
    }

    // region Lifecycle

    ngOnInit()
    {
        /**
         * SPECIFIC WORKAROUND FOR "closeWhenTrue$" HERE
         *
         * We want to be able to re-open the Swal and not close it right away because "closeWhenTrue$" is
         * still true (simple scenario : I update a User last name, I validate -> The backend confirms the update
         * and "closeWhenTrue$" becomes true. If I try to reopen the Swal, "closeWhenTrue$" is still true -> The Swal
         * will open and close right away).
         *
         * To be smart and handle those situations properly, we define a second observable : "canClose$", which
         * controls whether the Swal can be closed by "closeWhenTrue$".
         * By default, when opening the Swal, it is always false.
         */
        let canClose$ = new BehaviorSubject(false);

        /**
         * Then, when "closeWhenTrue$" is FALSE, "canClose$" becomes true :
         * - First opening of the Swal : "closeWhenTrue$" is false, "canClose$" becomes true directly
         * - Second opening : "closeWhenTrue$" is true, "canClose$" stays false and prevents the Swal from being
         * closed automatically. Then, if the user clicks the "Confirm button", "closeWhenTrue$" should go back
         * to false (that's up to our business implementation and that's the developer responsibility, but that's
         * usually what we do). In this case, "canClose$" becomes true
         */
        this.subscription.add(this.closeWhenTrue$.pipe(
            filter(closeWhenTrue => !closeWhenTrue),
            tap(_ => canClose$.next(true))
        ).subscribe());

        /**
         * Finally, here is the actual "closing" mechanism, which relies on both "canClose$" and "closeWhenTrue$"
         * to be true to allow the closing of the Swal
         */
        this.subscription.add(combineLatest(canClose$, this.closeWhenTrue$).pipe(
            filter(([canClose, closeWhenTrue]) => canClose && closeWhenTrue),
            tap(_ => this.closeSwal())
        ).subscribe());
    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
