import { UserSource } from 'mys-base';
import { $enum } from 'ts-enum-util';

export class RegisterUserRequest
{
    email: string;
    password: string;
    phonePrefixCountryId: number;
    mobilePhoneNumber: string;
    firstName: string;
    lastName: string;
    referrer: string;
    private source: string = this.setSource(UserSource.WEBSITE);

    /**
     * Sets the "source" attribute (that should be a String) from a given UserSource
     * @param userSource The UserSource we want to use as a source
     */
    setSource(userSource: UserSource): string
    {
        this.source = $enum(UserSource).getKeyOrDefault(userSource);
        return this.source;
    }
}
