import { ComponentFactoryResolver, ElementRef, EventEmitter, Input, Output, Renderer2, Type, ViewContainerRef, Directive } from '@angular/core';

/**
 * Created by Adrien Dos Reis on 27/12/2018
 * This class offers a "successComponent" input, which allows to redirect to a given component after
 * a "success" event has been emitted
 */
@Directive()
export abstract class AbstractSuccess
{

    // region Inputs / Outputs

    @Input() successComponent: Type<any>;
    @Output() successEvent = new EventEmitter();

    // endregion

    protected constructor(protected componentFactoryResolver: ComponentFactoryResolver, protected renderer: Renderer2)
    {
    }

    /**
     * Removes the content of the given container, and replaces it with an instance of the given "successComponent"
     */
    public displaySuccessComponent(container: ViewContainerRef)
    {
        if (!!this.successComponent) // If a SuccessComponent is given
        {
            const factory = this.componentFactoryResolver.resolveComponentFactory(this.successComponent);
            const successComponentRef = container.createComponent(factory);

            this.removeChildren(container.element);
            this.renderer.appendChild(container.element.nativeElement, successComponentRef.location.nativeElement);
        }

        // We emit an event for the outer components to know that the job was successful
        this.successEvent.emit();
    }

    /**
     * Removes all children from the given element
     * @param element
     */
    protected removeChildren(element: ElementRef): void
    {
        for (const child of element.nativeElement.children)
        {
            this.renderer.removeChild(element.nativeElement, child);
        }
    }
}
