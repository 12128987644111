<!-- Flex-basis 100% forces a line wrap AFTER displaying the image -->
<div style="cursor: pointer" class="d-flex flex-wrap col justify-content-center py-1" (click)="selectCurrentItem()">

  <!-- when the primary color of a client is too close to its "unselected" color we do not see very well when it is selected or not
  this is why we have made a border which is displayed when we select the payment method -->
  <div [ngClass]="_selected ? 'borderChoicePayment primary-border-color primary-color' : ''">
    <div style="flex-basis: 100%" class="d-flex justify-content-center">

        <!--
             If the image is selected the image will be tinted by the client color
             (if not selected, the image will not be tinted)
             see css for more information : msl-onboard-payment-item.component.scss
        -->

      <!-- The actual image -->
      <div class="divImg " [ngClass]=" [cash ? ' imgCash' : ' imgCreditCard', _selected ? ' primary-bg-color' : '']" ></div>

    </div>
    <div class="text-center mt-2" [ngClass]="selected ? 'green' : 'black'">
        {{ cash ? ('misc.CASH-PAYMENT' | translate) : ('misc.CB-PAYMENT' | translate) }}
    </div>
  </div>
</div>
