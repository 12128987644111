import { MatPaginatorIntl } from '@angular/material/paginator';
import { MysamTranslateService } from './mysam-translate/mysam-translate.service';
import { Injectable } from '@angular/core';

/**
 * Created by Adrien Dos Reis on 15/03/2019
 * This class allows to automatically translate any <mat-paginator> anywhere in the app
 */
@Injectable({ providedIn: 'root' })
export class MysamPaginatorTranslator extends MatPaginatorIntl
{
    // This translation will be translated in the constructor, but used in "generateRangeLabelString()"
    private of: string;

    constructor(private translate: MysamTranslateService)
    {
        super();
        this.translate.get([ 'paginator.FIRST-PAGE', 'paginator.ITEMS-PER-PAGE', 'paginator.LAST-PAGE', 'paginator.NEXT-PAGE',
            'paginator.PREVIOUS-PAGE', 'paginator.OF' ]).subscribe(translated =>
        {
            this.firstPageLabel = translated[0];
            this.itemsPerPageLabel = translated[1];
            this.lastPageLabel = translated[2];
            this.nextPageLabel = translated[3];
            this.previousPageLabel = translated[4];
            this.of = translated[5];

            /**
             * Now that we have all our translations OK, we can now force the re-drawing of the Paginator
             */
            this.changes.next();
        });

        /**
         * The range is a little bit more specific, since it's a method that takes parameters
         */
        this.getRangeLabel = this.generateRangeLabelString;
    }

    /**
     * Copied/pasted from https://github.com/angular/material2/issues/5573#issuecomment-358305725
     * @param page The page number
     * @param pageSize The page size
     * @param length The total length of the collection
     */
    private generateRangeLabelString(page: number, pageSize: number, length: number): string
    {
        if (length === 0 || pageSize === 0)
        {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.of} ${length}`;
    }
}
