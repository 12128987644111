import { Injectable } from '@angular/core';
import { WordReplacer } from 'msl-translate';
import { ClientSpecificConfig } from '../../environments/liberty-order/client-specific-config';

/**
 * Created by Adrien Dos Reis on 27/05/2020
 *
 * Service allowing to replace some words from any translated string, based on our Website business rules
 */
@Injectable({ providedIn: 'root' })
export class WebsiteWordReplacerService extends WordReplacer
{
    /**
     * In this WordReplacer, we replace each mention of "My Sam" by the actual Fleet Name of the current environment
     */
    replaceTerms(translatedString: string, language: string): string
    {
        /**
         * For a few ms, while the custom configuration is being loaded, the fleet name might be undefined.
         * We replace "My Sam" by the fleet name only when the configuration is fully loaded
         */
        const config = ClientSpecificConfig.instance
        const fleetName = config?.fleetName;

        if (!!fleetName)
        {
            translatedString = this.replaceCaseSensitive(translatedString, 'My Sam', fleetName);
            translatedString = this.replaceCaseSensitive(translatedString, 'MySam', fleetName);
        }

        return translatedString;
    }
}
