import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Created by Adrien Dos Reis on 21/01/2019
 * TODO This class is a lot like MslVehicleRadioItemComponent, but I'm lacking some time to refactor them...
 */
@Component({
    selector: 'msl-onboard-payment-item',
    templateUrl: './msl-onboard-payment-item.component.html',
    styleUrls: [ './msl-onboard-payment-item.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush // Doesn't work with "ngClass=selected", so, removed for now
})
export class MslOnboardPaymentItemComponent
{
    /**
     *  we delete "src" attribute because its unused after this jira ticket https://mysamcab.atlassian.net/browse/MYS-4985
     *  Before the src attribute was used to contain an image (in data / string format) but today this value is directly used in the css
     */
    @Input() selectedColor = '#14cab3';
    @Input() cash: boolean;
    @Output() clicked = new EventEmitter<void>();

    /**
     * The "selected" attribute must be bound to the image selected.
     * In order-button to do that, we define getters/setters instead of a simple attribute
     */
    _selected: boolean;
    @Input() get selected(): boolean
    {
        return this._selected;
    }

    set selected(newValue: boolean)
    {
        this._selected = newValue;

        /**
         * Since our "OnPush" change detection does not detect the selected image change,
         * we need to trigger it manually
         * Source : https://stackoverflow.com/questions/13104494/does-javascript-pass-by-reference/13104500#13104500
         */
        this.changeDetectorRef.detectChanges();
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    /**
     * Selects the current item, and triggers the "change" event if it wasn't already selected
     */
    selectCurrentItem(): void
    {
        this.selected = true;
        this.clicked.emit();
    }
}
