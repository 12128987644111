const GET_COUNTRIES = '[Countries] Get Countries';

/**
 * Created by Adrien Dos Reis on 05/02/2021
 */
export class GetCountriesAction
{
    static readonly type = GET_COUNTRIES;

    constructor()
    {
    }
}