/**
 * Created by Adrien Dos Reis on 23/11/2021
 *
 * Holds the current OAuthToken and all its metadata (is the current user a My Sam user ? a Driver ? a Supervisor ?)
 */
import { OAuthToken } from './oauth-token';
import { ConnectableUser } from 'mys-base';

export class OAuthTokenMetadata<U extends ConnectableUser>
{
    constructor(public oauthToken: OAuthToken<U>, public isMySam: boolean, public isDriver: boolean,
                public isSupervisor: boolean)
    {
    }
}