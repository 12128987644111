import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'mys-routing-menu-item',
    templateUrl: './routing-menu-item.component.html',
    styleUrls: [ './routing-menu-item.component.scss' ]
})
export class RoutingMenuItemComponent implements OnInit
{

    @Input() link: string;
    @Input() titleKey: string;
    @Output() click = new EventEmitter<MouseEvent>();

    // An Observable tracking the routing, to update the menu based on the current router states
    @Select(RouterState.state) routerNavigation: Observable<any>;

    constructor(private store: Store, private renderer: Renderer2)
    {
    }

    ngOnInit()
    {
    }

    /**
     * Called when the item is clicked in the menu
     */
    menuItemClicked(event: MouseEvent)
    {
        if (!!this.link)
        {
            /**
             * Two cases :
             * Either "link" starts with "http", and we need to do an actual redirection
             * Or we delegate the routing to the "NGXS Router Plugin"
             */
            if (this.link.startsWith("http"))
            {
                window.location.href = this.link;
            }
            else
            {
                this.store.dispatch(new Navigate([ this.link ]));
            }
        }

        this.click.emit(event);
        event.stopPropagation(); // To avoid triggering the "menu close" event
    }

    /**
     * Changes the CSS classes of this item if it is selected
     * An item is considered "selected" if the given URL is equals to the link of this item
     * @return Observable<boolean> true if the current item is selected
     */
    isSelected(): Observable<boolean>
    {
        return this.routerNavigation.pipe(map(router => this.link === router.url));
    }
}
