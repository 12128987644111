import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RegisterClient, ResetRegisterClientState } from '../actions/register.action';
import { RegisterService } from '../service/register.service';
import { catchError, map } from 'rxjs/operators';
import { Client, GenericState } from 'mys-base';
import { RegisterClientError } from '../errors/register-client.error';
import { clientInitialState, ClientStateModel } from './register-client.state.model';

import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@State({
    name: 'register',
    defaults: clientInitialState
})
@Injectable()
export class RegisterClientState
{
    constructor(private registerService: RegisterService)
    {
    }

    // region Selectors

    @Selector()
    static client(state: ClientStateModel): Client
    {
        return state.client;
    }

    @Selector()
    static loading(state: ClientStateModel)
    {
        return state.loading;
    }

    @Selector()
    static registerClientError(state: ClientStateModel): RegisterClientError
    {
        return state.error as RegisterClientError;
    }

    // endregion

    @Action(RegisterClient)
    registerClient(ctx: StateContext<ClientStateModel>, action: RegisterClient)
    {
        ctx.patchState({
            ...GenericState.load()
        });

        return this.registerService.register(action.payload).pipe(
            map((client: Client) => this.registerClientSuccess(ctx, client)),
            catchError((error: any) => of(this.registerClientFail(ctx,
                this.registerService.buildClientRegisterError(error))))
        );
    }

    // noinspection JSMethodCanBeStatic
    registerClientFail(ctx: StateContext<ClientStateModel>, error: RegisterClientError)
    {
        ctx.patchState({
            ...GenericState.error(error)
        });
    }

    // noinspection JSMethodCanBeStatic
    registerClientSuccess(ctx: StateContext<ClientStateModel>, client: Client)
    {
        ctx.patchState({
            client: client,
            ...GenericState.success()
        });
    }

    @Action(ResetRegisterClientState)
    resetRegisterClientState(ctx: StateContext<ClientStateModel>, action: ResetRegisterClientState)
    {
        return ctx.setState(clientInitialState);
    }
}
