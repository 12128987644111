/**
 * Created by Adrien Dos Reis on 07/01/2019
 */
import { GenericStateModel, GenericState, Client } from 'mys-base';

export interface ClientStateModel extends GenericStateModel
{
    client: Client | null;
}

export const clientInitialState = {
    client: null,
    ...GenericState.init()
};
