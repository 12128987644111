import { Language } from '../language';
import { Injectable } from '@angular/core';

/**
 * Created by Adrien Dos Reis on 14/01/2020
 *
 * The sole purpose of this class is to hold the current language of the app
 * - Since it is defined on runtime, it can't be stored in the module
 * - Since it is used in both MysamTranslateService and MysamTranslatePipe, we can't store it in any of them (otherwise
 * it would result in a circular reference)
 *
 * Therefore, we store it in a dedicated class, whose only goal is to keep track of the current language
 */
@Injectable({ providedIn: 'root' })
export class MysamLanguageHolder
{
    currentLang: Language;
}
