import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslSimpleSelectComponent } from './msl-simple-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
    ],
    declarations: [ MslSimpleSelectComponent ],
    exports: [ MslSimpleSelectComponent, MatFormFieldModule, MatSelectModule, FormsModule ]
})
export class MslSimpleSelectModule
{
}
