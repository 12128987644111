import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslAddressAutocompleteComponent } from './msl-address-autocomplete/msl-address-autocomplete.component';
import { MslAutocompleteWrapperModule } from 'msl-components';
import { NgxsModule } from '@ngxs/store';
import { FromAddressesState } from './store/state/from.addresses.state';
import { ToAddressesState } from './store/state/to.addresses.state';
import { ADDRESSES_URL } from './index';
import { GenericAddressesState } from './store/state/generic.addresses.state';
import { AddressService } from './services/address.service';

@NgModule({
    imports: [
        CommonModule,
        MslAutocompleteWrapperModule,
        NgxsModule.forFeature([ FromAddressesState, ToAddressesState, GenericAddressesState ])
    ],
    declarations: [ MslAddressAutocompleteComponent ],
    providers: [ AddressService ],
    exports: [ MslAddressAutocompleteComponent, MslAutocompleteWrapperModule ]
})
export class MslAddressesModule
{
    /**
     * This method allow 3rd-party apps to specify the URL that this module should use to look for addresses
     */
    static forRoot(addressesURL: string): ModuleWithProviders<MslAddressesModule>
    {
        return {
            ngModule: MslAddressesModule,
            providers: [
                {
                    provide: ADDRESSES_URL,
                    useValue: addressesURL
                }
            ]
        };
    }
}
