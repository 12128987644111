import {InjectionToken} from '@angular/core';

/**
 * Created by Adrien Dos Reis on 28/09/2020
 */
export const BASE_API_URL = new InjectionToken<string>('BASE_API_URL');
export const USER_SOURCE_GENERATOR = new InjectionToken<string>('USER_SOURCE_GENERATOR');

/**
 * Added for https://mysamcab.atlassian.net/browse/MYS-6527 - Indicates whether a shared module/service is invoked
 * from Alfred or from another of our frontends
 */
export const IS_FROM_ALFRED = new InjectionToken<boolean>('IS_FROM_ALFRED');
