import { $enum } from 'ts-enum-util';
import { Company } from './company';
import { Address } from './address';
import { TripType } from './constants/trip/trip-type';
import { VehicleType } from './constants/vehicle-type';

/**
 * Maps to FlatFeeMatrix backend class
 */
export class FlatFee
{
    id: number;
    fromAddress: Address;
    toAddress: Address;
    private tripType: string;
    private vehicleType: string;
    zdPrice: number;

    getTripType(): TripType
    {
        return $enum(TripType).getValueOrDefault(this.tripType);
    }

    getVehicleType(): VehicleType
    {
        return $enum(VehicleType).getValueOrDefault(this.vehicleType);
    }
}
