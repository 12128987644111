import { ModuleWithProviders, NgModule, Optional, SkipSelf, Type } from '@angular/core';

import { BASE_API_URL, throwIfAlreadyLoaded } from 'mys-base';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule, HttpInterceptor } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { HttpBasicInterceptor } from '../http/interceptors/http-basic.interceptor';
import { BASIC_AUTH_STRING, REALM } from 'msl-login';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,

        /**
         * Required to enable the jsonp() method on EstimationWithGoogleMapComponent, in order to load Google Map
         * capabilities.
         * If imported in the EstimationModule, it doesn't worK... (the Google Map stays blank).
         * It seems that we need to import it at the same time as HttpClientModule... So we do that.
         */
        HttpClientJsonpModule
    ]
})
export class MysamCoreModule
{
    constructor(@Optional() @SkipSelf() parentModule: MysamCoreModule)
    {
        throwIfAlreadyLoaded(parentModule, 'MysamCoreModule');
    }

    static forRoot(basicAuth: string, realm: string, baseUrl: string,
                   httpInterceptors: Type<HttpInterceptor>[] = []): ModuleWithProviders<MysamCoreModule>
    {
        return {
            ngModule: MysamCoreModule,
            providers: [
                {
                    provide: BASIC_AUTH_STRING,
                    useValue: basicAuth
                },
                {
                    provide: BASE_API_URL,
                    useValue: baseUrl
                },
                {
                    provide: REALM,
                    useValue: realm
                },
                /**
                 * Now we add our HttpInterceptors :
                 * - The default one : HttpBasicInterceptor
                 * - All the other ones, provided by the caller, and mapped into "interceptorProviders"
                 */
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpBasicInterceptor,
                    multi: true
                },

                /**
                 * For each element of "httpInterceptors", we define a Provider object, to add in the "providers"
                 * attribute of our MySamCodeModule
                 * Note : We HAVE to run this "httpInterceptors.map(...)" here, we CANNOT run it before the return {}
                 * block and place it into a variable for easier reading ! If we do that, we would have an errors
                 * "Uncaught Error: Angular JIT compilation failed: '@angular/compiler' not loaded!"
                 * It seems that it is NOT possible to create variables/constants in this "forRoot" block, before
                 * instantiating the very first module of the app...
                 */
                httpInterceptors.map(interceptor =>
                {
                    return {
                        provide: HTTP_INTERCEPTORS,
                        useClass: interceptor,
                        multi: true
                    };
                })
            ]
        };
    }
}
