import { Address } from './address';
import {CompanyInvoiceMarker} from './constants/company-invoice-marker';

export class Company
{
    id: number;
    name: string;
    registrationNumber: string;
    billingAddress: Address;
    created: Date;

    // CompanySettings attributes are declared in this class, because of the @JsonUnwrapped
    // for the field Company.settings server side
    // That's why we don't created CompanySettings class here
    useOnlyAllowedDrivers: boolean;
    premium: boolean;
    invoiceMarker: CompanyInvoiceMarker;
    daysUntilPayment: number;
    mustProvideExternalReference: boolean;
    areClientsAllowedOnMobileApp: boolean;
    shouldSendTripsSummaryByDefault: boolean;

    static assign(uncastedCompany: any): Company
    {
        const company: Company = Object.assign(new Company(), uncastedCompany);
        company.id = uncastedCompany.id;

        return company;
    }
}
