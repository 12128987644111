import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, map, Observable, of } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { deepmerge } from 'deepmerge-ts';
import { computed, signal } from '@angular/core';
import { MysamTranslateService } from 'msl-translate';

/**
 * Created by adrien.dosreis on 20/07/2023
 *
 * VERY strongly inspired by https://github.com/rbalet/ngx-translate-multi-http-loader, but this library
 * would erase all previously defined translations when loading the JSON translations files.
 * That means : If we define a translation in a module loaded when the application starts, this translation would be
 * set synchronously, then the JSON files would be loaded asynchronously and erase the previously defined translations.
 *
 * To avoid that, we copied/pasted the main code of https://github.com/rbalet/ngx-translate-multi-http-loader, and we
 * added a signal when all the translations are loaded (in order for our modules to wait until all JSON files are loaded
 * before adding their own translations)
 */
export class MysamTranslateHttpLoader extends TranslateLoader
{
    constructor(private httpBackend: HttpBackend, private filePaths: string[])
    {
        super();
    }

    public getTranslation(lang: string): Observable<any>
    {
        const requests: Observable<Object | {}>[] = this.filePaths.map((resource) =>
        {
            let path: string = `${resource}${lang}.json`;

            return new HttpClient(this.httpBackend).get(path).pipe(
                catchError((res) =>
                {
                    console.group();
                    console.error('Something went wrong for the following translation file:', path);
                    console.error(res);
                    console.groupEnd();
                    return of({});
                })
            );
        });

        return forkJoin(requests).pipe(
            map((response) => deepmerge(...response)),

            /**
             * Added by Adrien Dos Reis
             * This is the line allowing our magic to happen : We simply count how many translation files
             * have been loaded.
             * Since "forkJoin" has been used, this signal will only increment once per language of the app (and not
             * once per JSON file) : As of today, since we handle "English" and "French, "howManyLanguagesLoaded"
             * should contain "2" when all JSON files have been loaded (even if we loaded dozens of them)
             */
            tap(_ => howManyLanguagesLoaded.update(val => val + 1))
        );
    }
}

/**
 * Our two magic signals :
 * - The first one contains the number of languages loaded
 * - The second one becomes true when we finished loading all the languages
 *
 * Then, "areAllAsyncTranslationsLoaded" can be used by our MysamTranslateService to wait for completion before adding
 * new translations synchronously
 */
const howManyLanguagesLoaded = signal(0);
export const areAllAsyncTranslationsLoaded = computed(() =>
    howManyLanguagesLoaded() === MysamTranslateService.availableLangs.length);
