import { Address, GenericState, GenericStateModel } from 'mys-base';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { ResetSearchAddresses, SearchAddresses } from '../actions/addresses.action';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { AddressService } from '../../services/address.service';

export interface AddressesStateModel extends GenericStateModel
{
    data: Address[];
}

export abstract class AddressesState
{
    protected constructor(protected addressService: AddressService)
    {
    }

    static initDefaultState()
    {
        return {
            data: [],
            ...GenericState.init()
        };
    }

    static addresses<T extends AddressesStateModel>(stateClass)
    {
        // If the selector is created then returned directly, an errors occurs while "ng build".......
        // @see https://github.com/ng-packagr/ng-packagr/issues/696#issuecomment-387114613
        const result = createSelector([ stateClass ], (state: T) =>
        {
            return state.data;
        });
        return result;
    }

    /**
     * This method is supposed to be invoked in one of the child classes, with an extra check on
     * "actions.isFromAddress"
     *
     * @param ctx The states context
     * @param action The actions dispatched
     * @return The current Address
     */
    protected doSearchAddresses(ctx: StateContext<AddressesStateModel>, action: SearchAddresses)
    {
        ctx.patchState({
            ...GenericState.load()
        });
        return this.addressService.getAddressesFromUserSearch(action.addressAsString, action.mapViewport).pipe(
            map((addresses: Address[]) => this.searchAddressesSuccess(ctx, addresses)),
            catchError((error: any) => of(this.searchAddressesFail(ctx, error)))
        );
    }

    private searchAddressesFail(ctx: StateContext<AddressesStateModel>, error: any)
    {
        return ctx.patchState({
            ...GenericState.error(error)
        });
    }

    private searchAddressesSuccess(ctx: StateContext<AddressesStateModel>, addresses: Address[])
    {
        return ctx.patchState({
            data: addresses,
            ...GenericState.success()
        });
    }

    @Action(ResetSearchAddresses)
    private resetAddresses(ctx: StateContext<AddressesStateModel>, action: ResetSearchAddresses)
    {
        return ctx.setState(AddressesState.initDefaultState());
    }
}
