import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { MslPhoneNumberAndPrefixInputComponent } from "./msl-phone-number-and-prefix-input.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MysamTranslateModule } from "msl-translate";
import { CountriesState } from '../../../../mys-base/src/lib/countries/state/countries.state';

const angularDependencies = [ CommonModule, ReactiveFormsModule, TranslateModule, MysamTranslateModule ];
const components = [ MslPhoneNumberAndPrefixInputComponent ];

@NgModule({
    declarations: [ ...components ],
    imports: [
        ...angularDependencies,
        NgxsModule.forFeature([ CountriesState ]),
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule
    ],
    exports: [ ...components ]
})
export class MslPhoneNumberAndPrefixInputModule {}
