import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslVehicleRadioComponent } from './vehicle-radio/msl-vehicle-radio.component';
import { MslVehicleRadioItemComponent } from './vehicle-radio-item/msl-vehicle-radio-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

const exportedComponents = [MslVehicleRadioComponent, MslVehicleRadioItemComponent];
const materialDependencies = [MatIconModule, MatTooltipModule];

@NgModule({
    imports: [
        CommonModule,
        ...materialDependencies,
        TranslateModule
    ],
    declarations: [...exportedComponents],
    exports: [...exportedComponents]
})
export class MslVehicleRadioModule
{
}
