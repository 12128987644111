import { LayoutContainerComponent } from '../layout/components/layout-container/layout-container.component';
import { Routes } from "@angular/router";

export const routes: Routes = [
    {
        path: '',
        component: LayoutContainerComponent,
        children: [
            { path: '', redirectTo: 'estimation', pathMatch: 'full' },
            { path: 'estimation', loadChildren: () => import('../mysam/estimation-and-order/estimation/estimation.module').then(m => m.EstimationModule) },
            { path: 'login', loadChildren: () => import('../mysam/login/login.module').then(m => m.LoginModule) },
            { path: 'logout', loadChildren: () => import('../mysam/login/logout/logout.module').then(m => m.LogoutModule) },
            { path: 'register', loadChildren: () => import('../mysam/register-client-lazy-loader/register-client-lazy-loader.module').then(m => m.RegisterClientLazyLoaderModule) },
        ]
    },

    // Not found
    // { path: '**', redirectTo: 'register' }
];
