<div class="header-container">

    <!-- A black top header like the Wordpress Website -->
    <div id="black-top-header"></div>
    <nav class="container-fluid">
        <div class="d-flex g-0"> <!-- g-0 removes the padding between cols -->

            <!-- Open menu -->
            <div class="col-auto g-0" (click)="menuClicked.emit($event)">
                <img src="/assets/img/menu-black.png" id="menu-button"/>
            </div>

            <!-- MySam logo (as a link allowing to go back to the Home Page) -->
            <div class="col-3 col-lg-2 d-none d-sm-inline-block g-0">
                <a href="/" class="float-end">
                    <img src="/assets/img/transparent_logo_mysam.png" id="mysam-logo"/>
                </a>
            </div>

            <!-- Button allowing to download the Client app -->
            <!--
                    Float-End doesn't work in a row, "ml-auto" needed
                    @see https://github.com/twbs/bootstrap/issues/25829#issuecomment-372241764
            -->
            <div class="center-children-vertically float-end ms-auto g-0">
                <mys-download-app-header></mys-download-app-header>
            </div>
        </div>
    </nav>
</div>
