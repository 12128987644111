import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MslPrimaryButtonComponent } from './msl-primary-button/msl-primary-button.component';
import { CommonModule } from '@angular/common';
import { MslAccentButtonComponent } from './msl-accent-button/msl-accent-button.component';
import { MslSwalButtonsComponent } from "./msl-swal-buttons/msl-swal-buttons.component";

const components = [ MslPrimaryButtonComponent, MslAccentButtonComponent, MslSwalButtonsComponent ];
const dependencies = [ MatButtonModule, MatProgressSpinnerModule ];

@NgModule({
    imports: [
        CommonModule,
        ...dependencies
    ],
    declarations: [ ...components ],

    /**
     * UPDATE 20/05/2020
     * The "exports" block has been completely removed !
     * That's because of this issue : https://github.com/angular/angular/issues/24143
     * As in this post, I encountered the same issue while trying to import MslButtonModule from the MslRegister Library
     * --> Using a component from a Library A into a Library B.
     *
     * A partial solution was found here : https://github.com/angular/angular/issues/24143#issuecomment-422755085
     * Just remove the "exports" block and rely on the Javascript exportation in "public_api.ts"
     *
     * However, in this case, the Components of this Module (= our buttons) cannot be used in the other library...
     * So, back to our starting point.
     *
     * Finally, it seems that with Angular 9 (https://github.com/angular/angular/issues/24143#issuecomment-589407121), this issue
     * will be fixed :
     * "In Ivy, the compiler will yell at you if you try to export a module (from an Angular Package Format library) that isn't exported
     * from the entrypoint. So I'm closing this as fixed!"
     *
     * So maybe in Angular 9, the exports block is needed... We will see when we will migrate !
     */
    exports: [ ...components ]
})
export class MslButtonModule
{
}
