import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslAutocompleteWrapperComponent } from './msl-autocomplete-wrapper.component';
import { MslClearableAutocompleteModule } from '../msl-clearable-autocomplete/msl-clearable-autocomplete.module';

@NgModule({
    imports: [
        CommonModule,
        MslClearableAutocompleteModule
    ],
    declarations: [ MslAutocompleteWrapperComponent ],
    exports: [ MslAutocompleteWrapperComponent, MslClearableAutocompleteModule ]
})
export class MslAutocompleteWrapperModule
{
}
