export class StringsUtil
{
    /**
     * Adds an isBlank function to the String class. Returns true if the string is null, the length of the
     * string is 0 or if it's filled with whitespaces.
     */
    static isBlank(str: string): boolean
    {
        return (!str || str.trim().length === 0);
    }
}

