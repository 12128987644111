import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MySamPair } from 'mys-base';

@Component({
    selector: 'msl-time-picker',
    templateUrl: './msl-time-picker.component.html',
    styleUrls: [ './msl-time-picker.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslTimePickerComponent
{
    // region Attributes

    /**
     * Hour and minute lists
     */
    hours = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23 ];
    minutes = [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 ];

    // endregion

    // region Inputs / Outputs

    @Input() selectedHour: number;
    @Input() selectedMinutes: number;

    @Output() hourOrMinuteChange = new EventEmitter<MySamPair<number, number>>();

    // endregion

    /**
     * When the "hour" or "minutes" fields are changed, emit both values
     */
    emitHourOrMinuteChange()
    {
        this.hourOrMinuteChange.emit(new MySamPair<number, number>(this.selectedHour, this.selectedMinutes));
    }
}
