/**
 * Created by Adrien Dos Reis on 13/08/2019
 */
export enum SpringAuthority
{
    ROLE_ADMIN,
    ROLE_COMPANY, // Added on 17/12/2018
    ROLE_FREE_DRIVER, // Added on 13/08/2019 - Driver that is not currently paying for a Subscription
    ROLE_PAYING_DRIVER, // Added on 13/08/2019 - Driver that is currently paying for a Subscription
    ROLE_SUPERVISOR_DRIVER // Added on 24/04/2020 - Driver who is also a Supervisor of a Group of Drivers
}
