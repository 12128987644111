<mat-form-field [ngStyle]="{ width: width }">
    <mat-label>{{ placeholder }}</mat-label>
    <!--
        Starting with Material 15, by default, the selected option is displayed in the list with a checkmark
        next to it. We don't want that -> "hideSingleSelectionIndicator" is set to true -->
    <mat-select [(ngModel)]="model" (selectionChange)="modelChange.emit($event.value)"
                [hideSingleSelectionIndicator]="true">
        <mat-option *ngFor="let data of iterableData" [value]="data">{{ data }}</mat-option>
    </mat-select>
</mat-form-field>
