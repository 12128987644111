import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { Country } from "mys-base";
import { FormControl } from "@angular/forms";
import { GetCountriesAction } from "../../../../mys-base/src/lib/countries/actions/countries.action";
import { CountriesState } from "../../../../mys-base/src/lib/countries/state/countries.state";

@Component({
    selector: 'msl-phone-number-and-prefix-input[phoneSuffixInputCtrl][prefixInputCtrl][getPatternErrorFn][getRequiredErrorFn]',
    templateUrl: './msl-phone-number-and-prefix-input.component.html'
})
export class MslPhoneNumberAndPrefixInputComponent implements OnInit {

    // region Attributes

    @Input() phoneSuffixInputCtrl: FormControl<string>;
    @Input() prefixInputCtrl: FormControl<number>;
    @Input() getPatternErrorFn: () => string;
    @Input() getRequiredErrorFn: () => string;

    // region selector

    @Select(CountriesState.countries) countries$: Observable<Country[]>;

    // endregion

    // endregion

    // region Constructor

    constructor(private store: Store) {
    }

    // endregion

    // region Lifecycle

    ngOnInit() {
        // Get countries data
        this.store.dispatch(new GetCountriesAction());
    }

    // endregion

}
