import { NgModule } from '@angular/core';
import { MysamTranslatePipe } from './mysam-translate.pipe';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpBackend } from '@angular/common/http';
import { TranslateModuleConfig } from '@ngx-translate/core/dist/public-api';

const pipes: any[] = [ MysamTranslatePipe ];

@NgModule({
    declarations: [ ...pipes ],
    exports: [ ...pipes ],
    providers: [ ...pipes ]
})
export class MysamTranslateModule
{
    /**
     * This object shall be used in all TranslateModule.forChild() calls
     */
    static translateConfiguration(method): TranslateModuleConfig
    {
        return {
            loader: {
                provide: TranslateLoader,
                useFactory: (method),
                deps: [ HttpBackend ]
            }
        };
    }
}
