import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Select } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'mys-layout',
    templateUrl: './layout.component.html',
    styleUrls: [ './layout.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements AfterViewInit, OnDestroy
{
    // region Attributes

    // The Drawer Menu itself
    @ViewChild('drawerMenu') drawerMenu: MatSidenav;

    // An Observable tracking the routing, allowing to close the menu on page change
    @Select(RouterState.state) routerNavigation: Observable<any>;

    private subscription = new Subscription();

    // endregion

    constructor()
    {
    }

    toggleMenu(): void
    {
        this.drawerMenu.toggle();
    }

    // region Lifecycle

    ngAfterViewInit(): void
    {
        // When we navigate to another page, closes the menu
        this.subscription.add(
            this.routerNavigation.subscribe(router => this.drawerMenu.close())
        );
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
