import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Estimation, VehicleType } from 'mys-base';
import { $enum } from 'ts-enum-util';

@Component({
    selector: 'msl-vehicle-radio-v2',
    templateUrl: './msl-vehicle-radio-v2.component.html',
    styleUrls: [ './msl-vehicle-radio-v2.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslVehicleRadioV2Component
{
    // region Attributes

    VehicleType = VehicleType;

    // endregion

    // region Inputs / Outputs

    @Input() vehicleType = VehicleType.CAR;
    @Input() estimations: Estimation[] = []; // Price estimations for each vehicle type
    @Output() vehicleTypeSelected = new EventEmitter<VehicleType>();

    // endregion

    // region Get Vehicle Type Values

    /**
     * Gets the Vehicle Types that we want to display (= hides the deprecated ones (like LUXE) and the ones used
     * only on backend side (like GREEN PRIME))
     */
    getVehicleTypeValues(): VehicleType[]
    {
        return [ VehicleType.CAR, VehicleType.PRIME, VehicleType.VAN, VehicleType.GREEN, VehicleType.MOTO ]
            .filter(vt=> this.isVehicleTypeInOurEstimationsList(vt));
    }

    getZdPriceFromVehicleType(vt: VehicleType): number | null
    {
        const estimation = this.getEstimationFromVehicleType(vt);
        if (!!estimation)
        {
            return estimation.price;
        }

        return null;
    }

    private getEstimationFromVehicleType(vt: VehicleType): Estimation | null
    {
        const estimations = !!this.estimations ? this.estimations : [];
        return estimations.filter(esti => esti.vehicleType === vt)[0];
    }

    private isVehicleTypeInOurEstimationsList(vt: VehicleType): boolean
    {
        return !!this.getEstimationFromVehicleType(vt);
    }

    // endregion

    // region UI Interaction

    selectCurrentAndResetOthers(vt: VehicleType)
    {
        this.vehicleType = vt;
        this.vehicleTypeSelected.emit(vt);
    }

    // endregion
}
