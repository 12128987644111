import { SpringAuthority } from 'mys-base';

/**
 * Created by Aurélien Rieu on 20/08/2019
 * Utility class for current User connected
 */
export class CurrentUserUtils
{
    /**
     * Check if the current User have Admin role
     * @returns {boolean}
     */
    static isRoleAdmin(authorities: SpringAuthority[]): boolean
    {
        return authorities.includes(SpringAuthority.ROLE_ADMIN);
    }

    /**
     * Check if the current User have the Supervisor role (Driver supervising a group of Drivers)
     * @returns {boolean}
     */
    static isRoleSupervisorDriver(authorities: SpringAuthority[]): boolean
    {
        return authorities.includes(SpringAuthority.ROLE_SUPERVISOR_DRIVER);
    }

    /**
     * Check if the current User have paying Driver role
     * @returns {boolean}
     */
    static isRolePayingDriver(authorities: SpringAuthority[]): boolean
    {
        return authorities.includes(SpringAuthority.ROLE_PAYING_DRIVER);
    }

    /**
     * Check if the current User have free Driver role
     * @returns {boolean}
     */
    static isRoleFreeDriver(authorities: SpringAuthority[]): boolean
    {
        return authorities.includes(SpringAuthority.ROLE_FREE_DRIVER);
    }
}
