import { Color } from 'mys-base';
const tinycolor = require('tinycolor2');

/**
 * Created by adrien.dosreis on 18/08/2023
 */
export class ThemeUtils
{
    constructor(private shouldForceLightContrast: boolean | null = null)
    {
    }

    /**
     * Creates a Color object from the given "tinycolorValue" and with the given "name"
     */
    createColor = (tinycolorValue, name): Color =>
    {
        const c = tinycolor(tinycolorValue);
        return {
            name: name,
            hex: c.toHexString(),
            darkContrast: this.shouldForceLightContrast ?? c.isLight()
        };
    };

    /**
     * Defines a palette from the given hex color code
     */
    getPaletteFromColor(hex: string): Color[]
    {
        /**
         * Copied/Pasted from stackblitz.com/edit/angular-material-theming-playground
         */
        return [
            this.createColor(tinycolor(hex).lighten(52), '50'),
            this.createColor(tinycolor(hex).lighten(37), '100'),
            this.createColor(tinycolor(hex).lighten(26), '200'),
            this.createColor(tinycolor(hex).lighten(12), '300'),
            this.createColor(tinycolor(hex).lighten(6), '400'),
            this.createColor(tinycolor(hex), '500'),
            this.createColor(tinycolor(hex).darken(6), '600'),
            this.createColor(tinycolor(hex).darken(12), '700'),
            this.createColor(tinycolor(hex).darken(18), '800'),
            this.createColor(tinycolor(hex).darken(24), '900'),
            this.createColor(tinycolor(hex).lighten(50).saturate(30), 'A100'),
            this.createColor(tinycolor(hex).lighten(30).saturate(30), 'A200'),
            this.createColor(tinycolor(hex).lighten(10).saturate(15), 'A400'),
            this.createColor(tinycolor(hex).lighten(5).saturate(5), 'A700')
        ];
    }
}
