import { $enum } from 'ts-enum-util';
import moment from 'moment';
import { PaymentMethod } from '../../models/constants/payment-method';
import { Address } from '../../models/address';
import { VehicleType } from '../../models/constants/vehicle-type';
import { UserSource } from '../../models/constants/user-source';
import { TripType } from '../../models/constants/trip/trip-type';
import { EstimationRequest } from './estimation-request';

/**
 * Created by Adrien Dos Reis on 05/12/2018
 * This class allows to send a trip request to the server
 */
export class TripRequest
{
    // region Attributes

    fromAddress: Address;
    toAddress: Address;

    /**
     * "bookingDate" is really a Date, while "startDate" is a string (but should be called "startDate" because the
     * server expects it that way...)
     */
    bookingDate: Date;
    startDate: string;

    vehicleType: string; // VehicleType, but softly typed in order to actually send the "key" value of the enum
    type: string; // TripType, but softly typed in order to actually send the "key" value of the enum
    nbPassengers = 1;

    paymentMethod: string; // Always ON_BOARD for now

    /**
     * - WEBSITE for our Reservation website
     * - LIBERTY_ORDER for Liberty Order
     * - LIBERTY_DRIVER for Liberty Driver
     * - B2B_ALFRED for Alfred Pro / Liberty Business (Companies using Alfred)
     * - MYSAM for Alfred
     */
    source: string;

    /**
     * Indicate the disability level of the Client doing the Trip :
     * - null --> No disability
     * - false --> Mild disability
     * - true --> Significant disability
     */
    significantDisability: boolean | null = null;

    /**
     * Mandatory for Companies with mustProvideExternalReference set to true
     * Null for Companies with mustProvideExternalReference set to false
     */
    externalReference: string | null = null;

    /**
     * https://mysamcab.atlassian.net/browse/MYS-3507
     * This boolean determines if the Trip will have to be sent in a summary mail, using a CRON
     */
    shouldSendInEmailSummary = false;

    /**
     * Added on 24/05/2023
     * To resolve the differences between the price displayed and the price of the trip after creation
     */
    estimationId: number;

    /**
     * Those three attributes are the only ones that can be customized AFTER doing the estimation
     */
    zdForcedPrice: number = null;
    willBePaidInCash: boolean | null = null;
    comment: string;
    flightNumber: string | null = null;
    trainNumber: string | null = null;

    autoAssignToDriver = false;

    // endregion

    /**
     * The PaymentMethod can be updated AFTER the TripForClientRequest has been created, so we provide this setter
     * @param paymentMethod The new PaymentMethod
     */
    setPaymentMethod(paymentMethod: PaymentMethod): void
    {
        this.paymentMethod = $enum(PaymentMethod).getKeyOrDefault(paymentMethod);
    }

    /**
     * Builds the base of a TripRequest, from a given EstimationRequest
     */
    static buildFromEstimation(estimationReq: EstimationRequest, source: UserSource): TripRequest
    {
        // When estimating from the website, we don't need to provide an external reference :
        // This data is just used to be displayed in client and company invoices
        // We don't need either to send an email summary, that's why we set shouldSendInEmailSummary with the value false
        // The forced price can only be set once the Estimation is done, that's why the TripRequest is build with the value
        // forcedPrice = null
        // The attribute autoAssignToDriver is only used after the Estimation and when the current User connected is a Driver
        return TripRequest.build(
            estimationReq.fromAddress, estimationReq.toAddress, estimationReq.startDateAsString,
            $enum(VehicleType).getValueOrDefault(estimationReq.getVehicleTypeAsString()), source,
            null, null, 1, null, estimationReq.significantDisability,
            null, false, estimationReq.estimationId,null, false, null, null
        );
    }

    /**
     * Builds a TripRequest from the given elements
     * @param fromAddress
     * @param toAddress
     * @param startDate
     * @param vehicleType
     * @param source
     * @param paymentMethod
     * @param willBePaidInCash
     * @param passengers
     * @param comment
     * @param significantDisability
     * @param externalReference
     * @param shouldSendInEmailSummary
     * @param estimationId
     * @param zdForcedPrice
     * @param autoAssignToDriver
     * @param flightNumber
     * @param trainNumber
     * @return The created TripRequest, ready to be sent to the server
     */
    static build(fromAddress: Address, toAddress: Address, startDate: string | null, vehicleType: VehicleType,
                 source: UserSource, paymentMethod: PaymentMethod | null, willBePaidInCash: boolean | null,
                 passengers: number = 1, comment: string | null, significantDisability: boolean | null,
                 externalReference: string | null, shouldSendInEmailSummary: boolean | false, estimationId: number,
                 zdForcedPrice: number | null, autoAssignToDriver: boolean, flightNumber: string | null, trainNumber: string | null): TripRequest
    {
        const tripRequest = new TripRequest();
        tripRequest.fromAddress = fromAddress;
        tripRequest.toAddress = toAddress;

        tripRequest.startDate = startDate;
        tripRequest.bookingDate = !!tripRequest.startDate
            ? moment(tripRequest.startDate, EstimationRequest.DATE_FORMAT).toDate() : null;

        const tripType = tripRequest.startDate == null ? TripType.IMMEDIATE : TripType.RESERVATION;
        tripRequest.type = $enum(TripType).getKeyOrDefault(tripType);

        tripRequest.vehicleType = $enum(VehicleType).getKeyOrDefault(vehicleType);
        tripRequest.nbPassengers = passengers;

        tripRequest.source = $enum(UserSource).getKeyOrDefault(source);
        tripRequest.paymentMethod = $enum(PaymentMethod).getKeyOrDefault(paymentMethod);
        tripRequest.willBePaidInCash = willBePaidInCash;
        tripRequest.comment = comment;
        tripRequest.significantDisability = significantDisability;
        tripRequest.externalReference = externalReference;
        tripRequest.shouldSendInEmailSummary = shouldSendInEmailSummary;
        tripRequest.estimationId = estimationId;
        tripRequest.zdForcedPrice = zdForcedPrice;
        tripRequest.autoAssignToDriver = autoAssignToDriver;

        tripRequest.flightNumber = flightNumber;
        tripRequest.trainNumber = trainNumber;

        return tripRequest;
    }
}
