import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslTripSummaryComponent } from './msl-trip-summary.component';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'mys-base';

@NgModule({
    imports: [
        CommonModule,
        MatDividerModule,
        TranslateModule,
        PipesModule
    ],
    declarations: [ MslTripSummaryComponent ],
    exports: [ MslTripSummaryComponent ]
})
export class MslTripSummaryModule
{
}
