import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';

@Injectable()
export class TokenStorage
{
    private readonly ACCESS_TOKEN_KEY: string = 'accessToken';
    private readonly REFRESH_TOKEN_KEY: string = 'refreshToken';

    /**
     * Get access token
     * @returns {Observable<string>}
     */
    public getAccessToken(): Observable<string>
    {
        const token: string = <string>localStorage.getItem(this.ACCESS_TOKEN_KEY);
        return of(token);
    }

    /**
     * Get refresh token
     * @returns {Observable<string>}
     */
    public getRefreshToken(): Observable<string>
    {
        const token: string = <string>localStorage.getItem(this.REFRESH_TOKEN_KEY);
        return of(token);
    }

    /**
     * Set access token
     * @returns {TokenStorage}
     */
    public setAccessToken(token: string): TokenStorage
    {
        localStorage.setItem(this.ACCESS_TOKEN_KEY, token);

        return this;
    }

    /**
     * Set refresh token
     * @returns {TokenStorage}
     */
    public setRefreshToken(token: string): TokenStorage
    {
        localStorage.setItem(this.REFRESH_TOKEN_KEY, token);

        return this;
    }

    /**
     * Remove tokens
     */
    public clear()
    {
        localStorage.removeItem(this.ACCESS_TOKEN_KEY);
        localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    }
}
