import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'msl-trip-type-radio',
    templateUrl: './msl-trip-type-radio.component.html'
})
export class MslTripTypeRadioComponent
{
    private _isReservation = false;
    @Input() set isReservation(isReservationSelected: boolean)
    {
        this._isReservation = isReservationSelected;
        this.isReservationChange.emit(isReservationSelected);
    }

    get isReservation(): boolean
    {
        return this._isReservation;
    }

    // Disables both buttons
    @Input() disabled = false;

    // Disables immediate Trips
    @Input() shouldHideTripTypeButtons = false;

    @Output() isReservationChange = new EventEmitter<boolean>();
}
