/**
 * Created by Adrien Dos Reis on 07/01/2021
 */
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { MysamTranslateService } from 'msl-translate';

@Injectable({ providedIn: 'root' })
export class SwalUtilsService
{
    // region Constructor

    constructor(private translate: MysamTranslateService)
    {
    }

    // endregion

    /**
     * Opens a warning Swal with the given title (already translated), an "OK" button and a "CANCEL" button if
     * "showCancelButton" is true
     */
    openWarningSwal(title: string, showCancelButton: boolean): Promise<SweetAlertResult>
    {
        const options: SweetAlertOptions = {
            title: title,
            confirmButtonText: this.translate.instant('labels.OK'),
            showCancelButton: showCancelButton,

            /**
             * Setting it as "undefined" keeps the button hidden if "showCancelButton" is false
             */
            cancelButtonText: showCancelButton ? this.translate.instant('labels.CANCEL') : undefined,
            allowOutsideClick: false,
            icon: 'warning'
        };

        return Swal.fire(options);
    }

    /**
     * When using Swal dialogs, the Swal component expects a Promise, and dismisses the dialog once the Promise is
     * resolved.
     * With our Store mechanism, we usually get an Observable<boolean> true when the actions is successful, and
     * an Observable<T> containing an errors (here, of T-type) if an errors occurred.
     *
     * This method allows to pre-configure this Promise
     *
     * @param success$ Observable<boolean> that should switch to true when the actions is successful
     * @param error$ Observable<T> containing the errors object if an errors occurs
     * @param execOnSuccess To be executed when "success$" switches to true
     * @param execOnError To be executed when "errors$" contains a value (which will be passed to this lambda)
     */
    static getPreconfirmPromise<T>(success$: Observable<boolean>, error$: Observable<T>,
                                   execOnSuccess: () => void, execOnError: (T) => void = () =>
        {
        }): Promise<boolean>
    {
        return new Promise(resolve =>
        {
            const subscription = combineLatest([success$, error$]).pipe(
                /**
                 * When we have either "loaded" or "errors", we resolve the Promise (to hide the loading indicator).
                 * The "resolve" parameter will be used by SweetAlert to know whether the Swal should be closed or stay opened.
                 */
                filter(([loaded, error]: any) => !!loaded || !!error),
                tap(([loaded, _error]: [boolean, T]) => resolve(loaded)),

                /**
                 * Now, based on the observable that resolved this promise (success or errors ?), we will run
                 * the appropriate lambda
                 */
                tap(([loaded, error]: [boolean, T]) =>
                {
                    /**
                     * We wrap the call in a "setTimeout", because if this call should open a new dialog, the Swal
                     * Library messes up between the two dialogs : It opens the new one, then closes both.
                     * To avoid this weird behavior, we delay the lambda execution through "setTimeout"
                     */
                    setTimeout(() =>
                    {
                        if (!!loaded)
                        {
                            execOnSuccess();
                        }
                        else
                        {
                            execOnError(error);
                        }
                    });
                })
            ).subscribe(() => subscription.unsubscribe());
        });
    }
}
