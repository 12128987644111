import { Action, Selector, StateContext } from '@ngxs/store';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { Navigate } from '@ngxs/router-plugin';
import { ConnectableUser, TranslatedState, TranslatedStateModel } from 'mys-base';
import { Observable } from 'rxjs';
import { MysamTranslateService } from 'msl-translate';
import { OAuthTokenMetadata } from '../models/oauth-token-metadata';
import { LoginService } from '../services/login.service';
import { AuthenticationService } from '../services/authentication.service';
import { OAuthToken } from '../models/oauth-token';
import { LoginError } from '../errors/login.error';
import {
    LoginAction,
    LogoutWithoutRedirectionAction,
    RefreshTokenAction,
    RefreshTokenFailedAction
} from '../actions/login.action';
import { CurrentUserUtils } from '../utils/current-user-utils';
import { Injectable } from '@angular/core';
import { FeedOauthTokenToStateAction } from '../actions/feed-oauth-token-to-state.action';

export interface MslLoginStateModel extends TranslatedStateModel
{
    oauthTokenMetadata: OAuthTokenMetadata<ConnectableUser> | null;
}

export const loginInitialState: MslLoginStateModel = {
    oauthTokenMetadata: null,
    ...TranslatedState.init()
}

/**
 * This State is abstract : It should not be used as it is, but rather extended by our apps to force the type of the
 * user inside the OAuthToken
 */
@Injectable()
export abstract class MslLoginState<U extends ConnectableUser>
{
    // region Constructor

    constructor(private loginService: LoginService, private authenticationService: AuthenticationService,
                private translateService: MysamTranslateService)
    {
    }

    // endregion

    // region Selectors

    @Selector()
    static oauthToken(state: MslLoginStateModel): OAuthToken<ConnectableUser> | null
    {
        return state.oauthTokenMetadata?.oauthToken;
    }

    @Selector()
    static isMySam(state: MslLoginStateModel): boolean | null
    {
        return state.oauthTokenMetadata?.isMySam;
    }

    @Selector()
    static isDriver(state: MslLoginStateModel): boolean | null
    {
        return state.oauthTokenMetadata?.isDriver;
    }

    @Selector()
    static isSupervisor(state: MslLoginStateModel): boolean | null
    {
        return state.oauthTokenMetadata?.isSupervisor;
    }

    // endregion

    @Action(LoginAction)
    login(ctx: StateContext<MslLoginStateModel>, action: LoginAction)
    {
        return this.doLoginAndHandleResult(ctx, action.shouldRedirectAfterLogin, () =>
        {

            // First of all, we clear any remaining auth data
            this.authenticationService.logout();

            // And we trigger the login process
            return this.loginService.login(action.payload.email, action.payload.password, this.castUserIntoSubclass);
        });
    }

    /**
     * When an OAuthToken is generated from another place (e.g. NonceModule), it can be fed
     * into this Login State using this FeedOauthTokenToStateAction
     */
    @Action(FeedOauthTokenToStateAction)
    feedOAuthToken(ctx: StateContext<MslLoginStateModel>, action: FeedOauthTokenToStateAction<U>)
    {
        return this.loginSuccess(ctx, action.oauthToken, action.shouldRedirectAfterLogin);
    }

    // region Logout

    @Action(LogoutWithoutRedirectionAction)
    logoutWithoutRedirection(ctx: StateContext<MslLoginStateModel>, _action: LogoutWithoutRedirectionAction)
    {
        this.doLogout(ctx);
    }

    // endregion

    // region Refresh Token Actions

    @Action(RefreshTokenAction)
    refreshToken(ctx: StateContext<MslLoginStateModel>, action: RefreshTokenAction)
    {
        return this.doLoginAndHandleResult(ctx, false,
            () => this.loginService.refreshToken(action.refreshToken, this.castUserIntoSubclass));
    }

    @Action(RefreshTokenFailedAction)
    refreshTokenFailed(ctx: StateContext<MslLoginStateModel>, _action: RefreshTokenFailedAction)
    {
        return this.loginFail(ctx);
    }

    // endregion

    // region Private methods

    /**
     * Defines how to cast a ConnectableUser stored into this State into a subclass of ConnectableUser
     */
    protected abstract castUserIntoSubclass(user: ConnectableUser): U

    /**
     * "shouldRedirectAfterLogin" is true when login from email/password -> We redirect the user after the login form
     * was filled successfully
     * "shouldRedirectAfterLogin" is false when refreshing the token -> this process is transparent for the user
     * and should not move him away from the page he is trying to load
     *
     * "castUserOnSuccess" represents how the ConnectableUser hold by OAuthToken should be cast (into a subclass
     * of ConnectableUser)
     */
    private doLoginAndHandleResult(ctx: StateContext<MslLoginStateModel>, shouldRedirectAfterLogin: boolean,
                                   loginProcess: () => Observable<OAuthToken<U>>)
    {
        ctx.patchState({ ...TranslatedState.load() });

        return loginProcess().pipe(
            map(oauth => this.loginSuccess(ctx, oauth, shouldRedirectAfterLogin)),
            catchError(error => of(this.loginFail(ctx, this.loginService.createApiError(error))))
        );
    }

    private loginSuccess(ctx: StateContext<MslLoginStateModel>, oauthToken: OAuthToken<U>, shouldRedirectAfterLogin: boolean)
    {
        this.authenticationService.saveAccessData(oauthToken);

        const user = oauthToken.user;

        ctx.patchState({
            ...TranslatedState.success(),

            /**
             * We store the OAuthToken, along with the metadata bound to the "user"
             */
            oauthTokenMetadata: new OAuthTokenMetadata(
                oauthToken,
                CurrentUserUtils.isRoleAdmin(user.authorities),
                CurrentUserUtils.isRolePayingDriver(user.authorities) || CurrentUserUtils.isRoleFreeDriver(user.authorities),
                CurrentUserUtils.isRoleSupervisorDriver(user.authorities)
            )
        });

        /**
         * If a redirection is required, we use AuthenticationService.interruptedUrlAndParams to know where to go
         * from there
         */
        if (shouldRedirectAfterLogin)
        {
            // Redirection to the requested URL
            const urlAndParams = this.authenticationService.interruptedUrlAndParams;
            return ctx.dispatch(new Navigate(urlAndParams.path, urlAndParams.queryParams));
        }
    }

    private loginFail(ctx: StateContext<MslLoginStateModel>, error?: LoginError)
    {
        /**
         * When "errors" is not provided (when calling explicitly RefreshTokenFailedAction), the "translatedError" is
         * not used. However, it should be filled with a non-null value, in order for CurrentUserLoadedGuard to trigger
         * its " if (!!errors) " condition.
         *
         * Then, a LogoutAction is called, and clears this State. So, whatever the value we put inside, it will be
         * cleared. It only needs to be a "true-ish" value
         */
        const translatedError = !!error ? error.getErrorMessage(this.translateService) : 'error';

        return ctx.setState({
            ...loginInitialState,
            ...TranslatedState.error(translatedError)
        });
    }

    /**
     * Actual process of logging out : Clears this State and "logs out" from the Authentication Service
     */
    protected doLogout(ctx: StateContext<MslLoginStateModel>)
    {
        ctx.setState(loginInitialState);
        this.authenticationService.logout();
    }

    // endregion
}
