import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslHereMapComponent } from './msl-here-map.component';
import { HERE_API_KEY, IS_PRODUCTION } from './here-maps-injection-token';

const exportedComponents = [ MslHereMapComponent ];

@NgModule({
    declarations: [ ...exportedComponents ],
    imports: [
        CommonModule
    ],
    exports: [...exportedComponents]
})
export class MslHereMapModule
{
    // This method allow 3rd-party apps to pass their own HERE Api Key
    static forRoot(hereApiKey: string, isProduction: boolean): ModuleWithProviders<MslHereMapModule>
    {
        return {
            ngModule: MslHereMapModule,
            providers: [
                {
                    provide: HERE_API_KEY,
                    useValue: hereApiKey
                },
                {
                    provide: IS_PRODUCTION,
                    useValue: isProduction
                }
            ]
        };
    }
}
