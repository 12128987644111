import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiError, Country, GenericState, GenericStateModel } from 'mys-base';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { CountriesService } from '../services/countries.service';
import { GetCountriesAction } from '../actions/countries.action';

import { Injectable } from '@angular/core';

/**
 * Created by Adrien Dos Reis on 05/02/2021
 */
export interface CountriesStateModel extends GenericStateModel
{
    countries: Country[];
}

export const countriesInitialState = {
    countries: [],
    ...GenericState.init()
};

@State({
    name: 'countries',
    defaults: countriesInitialState
})
@Injectable()
export class CountriesState
{
    constructor(private countriesService: CountriesService)
    {
    }

    @Selector()
    static countries(state: CountriesStateModel): Country[]
    {
        return state.countries;
    }

    @Selector()
    static openedCountries(state: CountriesStateModel): Country[]
    {
        return state.countries.filter(country => country.opened);
    }

    @Action(GetCountriesAction)
    getCountries(ctx: StateContext<CountriesStateModel>, action: GetCountriesAction)
    {
        ctx.patchState({ ...GenericState.load() });

        return this.countriesService.getCountries().pipe(
            map(countries => this.getCountriesSuccess(ctx, countries)),
            catchError((error: any) => of(this.getCountriesFail(ctx, this.countriesService.createApiError(error))))
        );
    }

    // noinspection JSMethodCanBeStatic
    getCountriesSuccess(ctx: StateContext<CountriesStateModel>, countries: Country[])
    {
        return ctx.patchState({
            countries,
            ...GenericState.success()
        });
    }

    // noinspection JSMethodCanBeStatic
    getCountriesFail(ctx: StateContext<CountriesStateModel>, error: ApiError)
    {
        return ctx.patchState({
            ...GenericState.error(error)
        });
    }
}
