import { Inject, Injectable } from '@angular/core';
import { ApiService, BASE_API_URL, Country, IS_FROM_ALFRED } from 'mys-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

/**
 * Created by Adrien Dos Reis on 05/02/2021
 */
@Injectable({ providedIn: 'root' })
export class CountriesService extends ApiService
{
    // region Attributes

    private readonly BASE_COUNTRIES_URL = this.BASE_URL;

    // endregion

    // region Constructor

    constructor(httpClient: HttpClient, @Inject(BASE_API_URL) BASE_URL: string,
                @Inject(IS_FROM_ALFRED) private isFromAlfred: boolean)
    {
        super(httpClient, BASE_URL);
    }

    // endregion

    /**
     * Gets all the countries of the DB, ordered by "opened" then by "name"
     */
    public getCountries(): Observable<Country[]>
    {
        /**
         * Added on 19/10/2023 - https://mysamcab.atlassian.net/browse/MYS-6527
         * Based on whether the request comes from Alfred or from another front-end, we adapt the endpoint we want to
         * call (= the sort applied on backend side won't be the same for Alfred and for other frontends)
         */
        let countriesUrl = this.isFromAlfred ? '/alfred/countries' : '/countries';

        return this.httpClient.get<Country[]>(this.BASE_COUNTRIES_URL + countriesUrl).pipe(
            // Enforcing the return type here
            map((countries: any[]) => countries.map(country => Country.assign(country)))
        );
    }
}
