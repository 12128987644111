<!--
    A few explanations about this "mainContainer" :
    - We defined a "smallScreensTooltipKey", which will only be filled if "tooltipKey" is filled, AND if the screen
    is small (defined by "switchTooltipsBasedOnScreenWidth")
    - Everytime the window is resized, we force this component to check if the screen is now considered "small"

    Then, if the "small screen" threshold is crossed (from small to big, or the opposite), the UI will be updated
    accordingly (display/hide the "questionMarkTooltip" and placing/removing the tooltip on this container
-->
<div id="mainContainer" class="d-flex align-items-center"
     [ngClass]="[ selected ? 'active' : '', disabled ? 'disabled': '' ]"
     [matTooltip]="smallScreensTooltipKey | translate"
     (window:resize)="switchTooltipsBasedOnScreenWidth()"
     (click)="selectCurrentItem()">

    <div class="col p-0" id="imageContainer"><img class="ms-1 ms-sm-2" [src]="src"></div>
    <div class="col text-center pt-1" id="vehicleTypeName">{{ vehicleType | translate }}</div>

    <!--
        If a Tooltip should be displayed, we display a question mark icon here, and the tooltip when hovered
    -->
    <mat-icon class="me-2" *ngIf="questionMarkTooltipKey" [style.color]="'#AAAAB2'"
              [matTooltip]="questionMarkTooltipKey | translate">help_outline</mat-icon>
</div>
