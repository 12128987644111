import { ModuleWithProviders, NgModule } from '@angular/core';
import { PipesModule } from './pipes/pipes.module';
import { BASE_API_URL } from './index';

@NgModule({
    imports: [ PipesModule ],
    exports: [ PipesModule ],
    declarations: []
})
export class MySamBaseModule
{
    // This method allow 3rd-party apps to specify the base URL that this module should use
    static forRoot(baseURL: string): ModuleWithProviders<MySamBaseModule>
    {
        return {
            ngModule: MySamBaseModule,
            providers: [
                {
                    provide: BASE_API_URL,
                    useValue: baseURL
                }
            ]
        };
    }
}
