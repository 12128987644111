<div class="container-fluid">
    <div class="row">
        <div class="offset-1">
            <ul>
                <li><a href="https://mysam.fr/conditions-dutilisation-driver/">
                    {{ 'footer.tou.DRIVER' | mysamTranslate }}
                </a></li>
                <li><a href="https://mysam.fr/condition-dutilisations/">
                    {{ 'footer.tou.USER' | mysamTranslate }}
                </a></li>
                <li><a href="https://mysam.fr/mentions-legales/">
                    {{ 'footer.tou.LEGAL-NOTICE' | mysamTranslate }}
                </a></li>
                <li><a href="https://mysam.fr/contact/">
                    {{ 'footer.CONTACT' | mysamTranslate }}
                </a></li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="offset-1">©MySamCab 2018</div>
    </div>


    <!--<div class="row">-->
    <!--&lt;!&ndash; Links to download the app from the Apple Store and the Google Play Store &ndash;&gt;-->
    <!--<div class="col" id="store-links-container">-->
    <!--&lt;!&ndash;<a href="/" class="float-end">&ndash;&gt;-->
    <!--<img src="/assets/img/appstore.png"/>-->
    <!--&lt;!&ndash;</a>&ndash;&gt;-->
    <!--&lt;!&ndash;&ndash;&gt;-->
    <!--&lt;!&ndash;<a href="/" class="float-end">&ndash;&gt;-->
    <!--<img src="/assets/img/googleplay.png"/>-->
    <!--&lt;!&ndash;</a>&ndash;&gt;-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="row">-->
    <!--<div class="col">-->
    <!--{{ 'footer.SOCIAL-JOIN-US' | mysamTranslate }}-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="row">-->
    <!--&lt;!&ndash; Block containing the Social Network logos &ndash;&gt;-->
    <!--<div class="col" id="social-networks-container">-->
    <!--<a href="https://www.facebook.com/mysamcab/">-->
    <!--<img src="/assets/img/social/facebook_logo.png"/>-->
    <!--</a>-->

    <!--<a href="https://twitter.com/@MysamCab">-->
    <!--<img src="/assets/img/social/twitter_rounded_square_logo.png"/>-->
    <!--</a>-->

    <!--<a href="https://www.linkedin.com/company/mysam/">-->
    <!--<img src="/assets/img/social/linkedin_in_logo.png"/>-->
    <!--</a>-->
    <!--</div>-->
    <!--</div>-->
</div>
