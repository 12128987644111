<div class="layout-container sidebar-offcanvas">

    <!-- top navbar-->
    <mys-header (menuClicked)="toggleMenu()"></mys-header>

    <!-- Container containing both the Drawer Menu and the Main Content -->
    <mat-sidenav-container [hasBackdrop]="true">
        <!-- Side menu -->
        <mat-sidenav #drawerMenu mode="push" (click)="toggleMenu()" >
            <mys-routing-menu></mys-routing-menu>
        </mat-sidenav>

        <!-- Main Content -->
        <mat-sidenav-content>

            <!-- Main section-->
            <main class="main-container overflow-hidden overflow-auto"> <!-- overflow hidden and auto for remove useless scrollbar-->
                <!-- Page content-->
                <section>
                    <router-outlet></router-outlet>
                </section>

                <!-- Page footer-->
                <mys-footer></mys-footer>
            </main>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
