/*
 * Public API Surface of msl-login
 */
import { protectedGuard, publicGuard } from 'ngx-auth';

// MODULE
export * from './lib/msl-login.module';

// ACTIONS
export * from './lib/actions/login.action';

// ERRORS
export * from './lib/errors/login.error';

// MODELS
export * from './lib/models/oauth-token';
export * from './lib/models/oauth-token-metadata';

// MISC
export * from './injection-tokens';

// SERVICES
export * from './lib/services/authentication.service';
export * from './lib/services/login.service';

// STATES
export * from './lib/states/msl-login.state';

// UTILS
export * from './lib/utils/current-user-utils';

/**
 * We export some of NGX-Auth Guards, in order for Alfred to be able to access them and use them without
 * knowing about NGX-Auth
 */
export const MySamProtectedGuard = protectedGuard;
export const MySamPublicGuard = publicGuard;
