export enum DriverStatus
{
    PENDING_APPROVAL = 'enums.driver-status.PENDING_APPROVAL',
    AVAILABLE = 'enums.driver-status.AVAILABLE',
    AVAILABLE_RESERVATIONS_ONLY = 'enums.driver-status.AVAILABLE_RESERVATIONS_ONLY',
    AVAILABLE_IMMEDIATES_ONLY = 'enums.driver-status.AVAILABLE_IMMEDIATES_ONLY',
    UNAVAILABLE = 'enums.driver-status.UNAVAILABLE',
    ASSIGNED = 'enums.driver-status.ASSIGNED',
    DRIVING = 'enums.driver-status.DRIVING',
    SUSPENDED = 'enums.driver-status.SUSPENDED'
}
