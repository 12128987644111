<div class="swal2-actions mb-3">
    <!-- Confirm button -->
    <msl-primary-button class="pe-4" [displayLoader]="displayLoader$ | async" (click)="preConfirmFn()">
        {{ confirmBtnText }}
    </msl-primary-button>

    <!-- Cancel button  -->
    <msl-accent-button (click)="onCancel(); closeSwal()">
        {{ cancelBtnText }}
    </msl-accent-button>
</div>
