<!-- A first container, which will be as big as possible -->
<!-- If the driver does not wish to display a map, he can choose a background layout color to replace it
     if he has not chosen a color then a default color will be applied (lightgrey) -->
<div id="layout-container" [ngStyle]="{ 'background-color' : BACKGROUND_LAYOUT_COLOR }">
    <!--
            A second container, just big enough to fit its content, and centered in the first container
            This container is needed, because the "display: flex" property applied on "layout-container"
            needs to have only one child (otherwise, all the children are on the same horizontal row)
    -->
    <div>

        <!-- Header -->
        <section id="layout-header" *ngIf="SHOULD_DISPLAY_HEADER" [ngStyle]="{ 'background-color' : BACKGROUND_COLOR }">
            <img [src]="PATH_TO_LOGO"/>
        </section>

        <!-- Actual content -->
        <section>
            <router-outlet></router-outlet>
        </section>
    </div>
</div>
