import { Component } from '@angular/core';

@Component({
  selector: 'mys-download-app-header',
  templateUrl: './download-app-header.component.html',
  styleUrls: ['./download-app-header.component.scss']
})
export class DownloadAppHeaderComponent {

  constructor() { }
}
