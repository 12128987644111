import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';

/**
 * Date formatting
 */
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'll'
    },
    display: {
        dateInput: 'll',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'll',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

/**
 * Created by Adrien Dos Reis on 07/11/2018
 * This component allows to define a Material DatePicker handling the Date in a French format, and with some nice
 * small improvements
 */
@Component({
    selector: 'msl-french-date-picker',
    templateUrl: './msl-french-date-picker.component.html',
    styleUrls: [ './msl-french-date-picker.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class MslFrenchDatePickerComponent implements OnInit, OnChanges
{
    // region Attributes

    @Input() placeholder: string; // The input  placeholder
    @Input() width: string; // The total width of the element (expressed as such : '80px')
    @Input() date = new Date();
    @Output() dateChange = new EventEmitter<Moment>();
    dateInput: FormControl<Date>;

    // endregion

    // region Constructor

    /**
     * @param breakpointObserver Will be used to track if the layout is smaller or bigger than
     * a given value (in order-button to display the DatePicker as a Touch UI Component if the layout is very small)
     * Found here : https://stackoverflow.com/a/47484128/2294082
     */
    constructor(private breakpointObserver: BreakpointObserver)
    {
    }

    // endregion

    /**
     * @return true if the layout is currently considered small
     */
    get isSmallLayout(): boolean
    {
        return this.breakpointObserver.isMatched('(max-width: 600px)');
    }

    private fillDateInput(date: Date | null)
    {
        const dateOrDefault = !!date ? date : new Date();
        this.dateInput = new FormControl<Date>(dateOrDefault);
    }

    // region Lifecycle

    ngOnInit()
    {
        this.fillDateInput(this.date);
    }

    ngOnChanges(changes: SimpleChanges)
    {
        if (!!changes.date)
        {
            this.fillDateInput(changes.date.currentValue);
        }
    }

    // endregion
}
