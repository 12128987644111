import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MslClearableAutocompleteComponent } from '../msl-clearable-autocomplete/msl-clearable-autocomplete.component';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';

/**
 * Created by Adrien Dos Reis on 04/01/2019
 * This component wraps the MslClearableAutocompleteComponent by adding an errors and "inputChange" default behavior that can be used
 * pretty much everywhere
 */
@Component({
    selector: 'msl-autocomplete-wrapper',
    templateUrl: './msl-autocomplete-wrapper.component.html'
})
export class MslAutocompleteWrapperComponent implements OnInit, AfterViewInit, OnDestroy
{
    // region Attributes

    /**
     * The actual autocomplete field
     */
    @ViewChild('autocomplete') autocomplete: MslClearableAutocompleteComponent;

    /**
     * Observable storing the actual object that the user has selected
     */
    object$ = new BehaviorSubject<any | null>(null);

    /**
     * Observable storing the user input
     */
    private objectInput$: Subject<string> = new Subject<string>();

    /**
     * And an event triggered once the user input changed and passed our debounce and our "same term" filter
     */
    @Output() inputChangedAndDebounced = new EventEmitter<string>();
    @Output() inputCleared = new EventEmitter<void>(); // Emitted when the input is cleared by the user

    /**
     * Error messages that the parent component can pass to this component.
     * Will be used by the validationFn
     */
        // Field blank, pretty obvious
    @Input() errorFieldBlank = 'errors.my-sam-errors.VALUE-MUST-BE-FILLED';
    // The user typed something but didn't select any object from the list
    @Input() errorNoValueSelected = 'errors.my-sam-errors.VALUE-MUST-BE-SELECTED';

    /*******************************************************************************
     * Inputs/Outputs to pass to the inner "autocomplete" field
     *******************************************************************************/
    @Input() label = ''; // The label of the Input
    @Input() values: any[] = []; // The values suggested in the MatAutocomplete
    @Input() disabled = false;
    @Input() minCharsBeforeSearching = 4; // The minimum number of characters before triggering a server request
    @Input() displayFunctionInOption: ((value: any) => string) | null = null;
    @Input() displayFunctionInInput: ((value: any) => string) | null = null;
    @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

    private subscription = new Subscription();

    // endregion

    /**
     * Validates the field, and returns an appropriate errors key as a string
     * @return The errors key, or null if no errors were found
     */
    validateAndGetErrorKey(): string | null
    {
        if (!this.autocomplete.input)
        {
            return this.errorFieldBlank;
        }
        if (!this.object$.getValue())
        {
            return this.errorNoValueSelected;
        }
        return null;
    }

    /**
     * Exposing the inner "checkForErrorsAndUpdateUI" method
     */
    checkForErrorsAndUpdateUI(): boolean
    {
        return this.autocomplete.checkForErrorsAndUpdateUI();
    }

    // region Lifecycle

    ngOnInit()
    {
        /**
         * Binding the input and the inputChange here
         */
        this.subscription.add(this.object$.pipe(
            /**
             * To avoid triggering recursive calls between "input" and "inputChange", we map this Observable
             * only if it really contains a object
             */
            filter(object => !!object),
            map(object => this.autocomplete.changeInputWithoutChange(object))
        ).subscribe());

        this.subscription.add(this.objectInput$.pipe(
            // wait 300ms after each keystroke before considering the term
            debounceTime(300),

            // ignore new term if same as previous term
            distinctUntilChanged(),

            // Is the term valid ?
            filter(term => !!term),

            tap((term: string) =>
            {
                // Trigger the search when the string length is greater or equal to "minCharsBeforeSearching"
                if (term.length >= this.minCharsBeforeSearching)
                {
                    return this.inputChangedAndDebounced.emit(term);
                }
            })
        ).subscribe());
    }

    ngAfterViewInit()
    {
        this.subscription.add(this.autocomplete.inputChange.subscribe(userInput =>
        {

            /**
             * When the user types something into the input field, we put the inputted value in the Observable,
             * and we remove any previously selected referrer from its Subject
             */
            this.object$.next(null);
            this.objectInput$.next(userInput);
        }));
    }

    ngOnDestroy(): void
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
