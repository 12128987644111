import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslSignificantDisabilityRadioComponent } from './msl-significant-disability-radio.component';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatRadioModule,
        TranslateModule
    ],
    declarations: [ MslSignificantDisabilityRadioComponent ],
    exports: [ MslSignificantDisabilityRadioComponent, MatRadioModule, TranslateModule ]
})
export class MslSignificantDisabilityRadioModule
{
}
