import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { NavigationExtras } from '@angular/router';

/**
 * Created by Adrien Dos Reis on 07/12/2018
 */
export class RoutingHelper
{
    /**
     * Redirects to the given URL by dispatching an actions through the current Store
     * @param store
     * @param url
     * @param extras
     */
    public static redirect(store: Store, url: string, extras?: NavigationExtras)
    {
        store.dispatch(new Navigate([ url ], null, extras));
    }
}
