import { EventEmitter, HostBinding, Input, Output, Directive } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Directive()
export abstract class MslButtonComponent
{
    @Input() displayLoader = false; // Used to switch between the text and the loader

    /**
     * The "class" elements will be added to the "class" of the inner button
     * The "hostClass" elements will be added as the "class" of the binding element (the MslButtonComponent)
     */
    @Input() class = '';
    @Input() hostClass = '';

    @Input() color: ThemePalette;
    @Input() disabled = false;
    @Input() inactive = false; // Displays the button on a much clearer background-color hue
    @Output() click = new EventEmitter<MouseEvent>();

    /**
     * @see https://stackoverflow.com/questions/50805832/how-to-prevent-clicks-to-custom-button-component-in-angular-4
     * Disables the "msl-button" (not just the inner <button>), in order to prevent clicks on it
     * This annotation will add the "disabled" property to the HTML element if our "disabled" Input is true
     */
    @HostBinding('class')
    get hostBindingClass(): string
    {
        return this.disabled ? 'disabled ' + this.hostClass : this.hostClass;
    }

    constructor()
    {
    }

    doClick(event: MouseEvent)
    {
        /**
         * We propagate the event ONLY if the loader is not displayed
         */
        if (!this.displayLoader)
        {
            this.click.emit(event);
        }

        // And we stop the propagation anyway
        event.stopPropagation();
    }
}
