import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WordReplacer } from 'msl-translate';
import { WebsiteWordReplacerService } from './translator/website-word-replacer.service';

const modules = [ CommonModule, FormsModule, ReactiveFormsModule ];
@NgModule({
    imports: [
        ...modules
    ],
    providers: [ { provide: WordReplacer, useClass: WebsiteWordReplacerService } ],
    exports: [
        ...modules,
        RouterModule
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule
{
    static forRoot(): ModuleWithProviders<SharedModule>
    {
        return {
            ngModule: SharedModule
        };
    }
}
