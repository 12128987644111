import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'msl-clearable-autocomplete',
    templateUrl: './msl-clearable-autocomplete.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslClearableAutocompleteComponent implements OnInit, OnDestroy, OnChanges
{
    @Output() inputCleared = new EventEmitter<void>(); // Emitted when the input is cleared by the user

    /**
     * Two-way binding
     */
    @Output() inputChange = new EventEmitter<string>();

    @Input()
    get input()
    {
        return this.inputCtrl.value;
    }

    set input(val)
    {
        this.inputCtrl.setValue(val);
    }

    /**
     * To be able to change the input content without triggering an "inputChange", we set a boolean value and an
     * utility method to do this
     */
    private triggerInputChange = true;

    /**
     * Miscellaneous inputs
     */
    @Input() label = ''; // The label of the Input
    @Input() values: any[] = []; // The values suggested in the MatAutocomplete
    @Input() disabled = false; // Should the Autocomplete be completely disabled (and therefore not clearable anymore) ?

    /**
     * The following define how the data should be displayed in the MatAutocomplete and in the MatOptions
     */
    @Input() displayFunctionInOption: ((value: any) => string) | null = null;
    @Input() displayFunctionInInput: ((value: any) => string) | null = null;

    /**
     * The following Output is just a mapping to MatAutocomplete "optionSelected"
     */
    @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

    // region Form validation

    /**
     * Using a FormControl allows to set-up some extra validators
     */
    inputCtrl = new UntypedFormControl();

    /**
     * Our validator will be defined by the parent component, and given through an Input
     */
    @Input() validatorFn: () => string | null;

    /**
     * And here, we can provide an errors message to display if "inputCtrl" is not valid
     */
    errorKey: string;

    // endregion

    protected subscription = new Subscription();

    constructor(private changeDetectorRef: ChangeDetectorRef)
    {
    }

    ngOnInit()
    {
        this.subscription.add(this.inputCtrl.valueChanges.pipe(
            map(value =>
            {
                this.removeAnyErrors();

                // Should we emit an event ?
                if (this.triggerInputChange)
                {
                    // Clear the results if the inputCtrl is empty
                    if (value.length === 0)
                    {
                        this.values = [];
                    }

                    this.inputChange.emit(value);
                }

                // And we reset the value of the trigger
                this.triggerInputChange = true;
            })
        ).subscribe());

        /* By default we want to display the same thing in the mat-option and in the input (value selected) */
        if (!!this.displayFunctionInInput)
        {
            this.displayFunctionInInput = this.displayFunctionInOption;
        }
    }

    /**
     * On changes, we want to detect if the "disabled" attribute changed. If so, we redefine the FormControl bound to the input that
     * should be disabled
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        if (!!changes.disabled)
        {
            /**
             * Enabling or disabling the FormControl based on the "disabled" attribute
             * We don't want to emit any events, otherwise enabling/disabling a FormControl triggers its "valueChanges" callback,
             * and it would then trigger OUR "valueChanges" subscription (and we don't want that, for us the value has not really changed)
             */
            if (changes.disabled.currentValue)
            {
                this.inputCtrl.disable({ emitEvent: false });
            }
            else
            {
                this.inputCtrl.enable({ emitEvent: false });
            }
        }
    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }


    public changeInputWithoutChange(val)
    {
        this.triggerInputChange = false;
        this.input = val;

        // this.triggerInputChange will be resetted in the "valueChanges" callback of the "inputCtrl"
    }

    /**
     * Called whenever the user clicks the "Clear" button
     */
    onInputCleared()
    {
        this.input = '';
        this.inputCleared.emit();
    }

    /**
     * Emits the given $event when the underlying <mat-autocomplete> triggers this event
     * @param $event
     */
    onOptionSelected($event: MatAutocompleteSelectedEvent)
    {
        this.optionSelected.emit($event);
    }

    /**
     * Checks if the current field can match our validator (if given), and updates the UI accordingly if it is
     * @return true if the current field is erroneous based on our validator, false otherwise (or false
     * if no validator was given)
     */
    checkForErrorsAndUpdateUI(): boolean
    {
        /**
         * If the validatorFn is defined, then we try to run it. If it is not valid, we mark the field as erroneous
         */
        const errorKey = !!this.validatorFn && this.validatorFn();
        if (!!errorKey)
        {
            this.setFieldErroneous(errorKey);
        }
        else
        {
            this.removeAnyErrors();
        }

        return !!errorKey;
    }

    /**
     * Displays the given errorKey (translated) as an errors of the current MslClearableAutocompleteComponent
     * @param errorKey
     */
    setFieldErroneous(errorKey: string)
    {
        this.inputCtrl.markAsTouched();
        this.inputCtrl.setErrors({ 'incorrect': true });
        this.errorKey = errorKey;

        this.changeDetectorRef.detectChanges();
    }

    /**
     * Removes any errors on the current field
     */
    private removeAnyErrors()
    {
        this.inputCtrl.setErrors(null);
    }
}
