export class FileLoader
{
    /**
     * Loads the given File, and executes "onLoad" when the loading is completed.
     * The parameter of "onLoad" is the string content of the loaded File (e.g. for an Image : Its base64 representation)
     */
    load(file: Blob | File, onLoad: (string) => void)
    {
        /**
         * Now, we will create a FileReader, which will load asynchronously the file into our preview
         */
        const reader = new FileReader();

        /**
         * Step 1 - Defining a callback to execute once the loading is finished
         */
        reader.onload = (e: any) => onLoad(e.target.result);

        /**
         * Step 2 - Trigger the actual loading
         */
        reader.readAsDataURL(file);
    }
}
