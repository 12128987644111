export class Discussion
{
    id: number;

    /**
     * The Connectable Users of a Discussion are serialized using only their IDs/first/last names, to lighten the JSON
     */
    operator: { userId: string, firstName: string; lastName: string };
    client: { userId: string, firstName: string; lastName: string };
    driver: { userId: string, firstName: string; lastName: string };

    created: Date;
    comment: string;
    tripId: number | null;
}
