export enum VehicleType
{
    CAR = 'enums.vehicle-type.CAR',
    GREEN = 'enums.vehicle-type.GREEN',
    GREEN_PRIME = 'enums.vehicle-type.GREEN-PRIME',
    MOTO = 'enums.vehicle-type.MOTO',
    PRIME = 'enums.vehicle-type.PRIME',
    VAN = 'enums.vehicle-type.VAN',

    // Deprecated
    LUXE = 'enums.vehicle-type.LUXE'
}

export class VehicleTypeUtils
{

    /**
     * Gets the number of passengers that the given VehicleType can handle
     */
    static getNbPassengersFromVehicleType(vt: VehicleType | string): number
    {
        switch (vt)
        {
            case VehicleType.MOTO:
                return 1;
            case VehicleType.VAN:
                return 6;
            default:
                return 4;
        }
    }
}
