import { Company } from '../company';
import { AlfredUserCategory } from '../constants/alfred-user-category';
import { $enum } from 'ts-enum-util';
import { ConnectableUser } from './connectable-user';

export class AlfredUser extends ConnectableUser
{
    // region Attributes

    company: Company | null;
    category: AlfredUserCategory;

    // endregion

    /**
     * Needed for OAuthToken to call dynamically the appropriate "assign" method based on the correct
     * ConnectableUser subtype
     */
    assign(uncastedAlfredUser: any): AlfredUser
    {
        return AlfredUser.assign(uncastedAlfredUser)
    }

    /**
     * Deep casts (manually, so all fields might not be casted) the given "uncastedAlfredUser"
     * @param uncastedAlfredUser
     */
    static assign(uncastedAlfredUser: any): AlfredUser
    {
        const alfredUser: AlfredUser = Object.assign(new AlfredUser(), ConnectableUser.assign(uncastedAlfredUser));


        /**
         * Casting non-nullable fields
         */
        alfredUser.category = $enum(AlfredUserCategory).getValueOrDefault(uncastedAlfredUser.category);

        /**
         * Casting nullable fields here
         */
        if (!!uncastedAlfredUser.company)
        {
            alfredUser.company = Object.assign(new Company(), uncastedAlfredUser.company);
        }

        return alfredUser;
    }
}
