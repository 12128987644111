import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/address';

@Pipe({
    name: 'address'
})
export class AddressPipe implements PipeTransform
{
    transform(address: Address | null): string
    {
        if (!!address)
        {
            let displayable: string = address.address;

            if (!!address.zipCode)
            {
                displayable += ', ' + address.zipCode;
            }

            if (!!address.city)
            {
                displayable += ' ' + address.city;
            }

            // if (!!address.country)
            // {
            //     displayable += ', ' + address.country;
            // }

            return displayable;
        }

        return null;
    }
}
