import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { MatDividerModule } from '@angular/material/divider';
import { RoutingMenuComponent } from './routing-menu/routing-menu.component';
import { RoutingMenuItemComponent } from './routing-menu-item/routing-menu-item.component';
import { MysamTranslateModule } from 'msl-translate';

const materialDependencies = [ MatDividerModule ];
const dependencies = [ RoutingMenuComponent, RoutingMenuItemComponent ];

@NgModule({
    imports: [
        MysamTranslateModule,
        SharedModule,
        ...materialDependencies
    ],
    declarations: [
        ...dependencies
    ],
    exports: [
        ...dependencies, ...materialDependencies
    ]
})
export class RoutingMenuModule
{
}
