import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { Moment } from 'moment';
import { Estimation, MySamPair, VehicleType } from 'mys-base';

@Component({
    selector: 'msl-date-and-vehicle-type',
    templateUrl: './msl-date-and-vehicle-type.component.html',
    styleUrls: [ './msl-date-and-vehicle-type.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslDateAndVehicleTypeComponent implements OnInit, OnChanges
{
    // region Inputs / Outputs

    @Input() containerClass = 'col'; // To block the negative margin from the outer "row"
    @Input() vehicleTypeDisabled = false; // To disable the vehicle selection (but keeping the date selection active)

    @Input() vehicleType = VehicleType.CAR;
    @Input() isReservation = false;
    @Input() estimations: Estimation[] = []; // Price estimations for each vehicle type

    /**
     * Added on 26/06/2020
     * If "false" (the default value), the vehicle types will be displayed using the "old display" (msl-vehicle-radio)
     * Otherwise, a "new display" will be used (msl-vehicle-radio-v2)
     */
    @Input() useNewDisplay = false;

        // This output will hold the booking date and time of a Reservation, or null if Immediate Trip
    @Output() dateTimeChanged = new EventEmitter<Moment | null>();
    @Output() vehicleTypeChange = new EventEmitter<VehicleType>();

    // endregion

    /**
     * Booking date and Vehicle Type
     */
    selectedMoment: Moment;

    // region Hour/minutes lists and default values

    /**
     * Hour and minute models values
     */
    selectedHour: number;
    selectedMinutes: number;

    /**
     * Gets the value of the "default" date value (in a bit more than 30mn)
     * @return The default date value
     */
    private static defaultDate(): Date
    {
        const momentIn30mn: Moment = moment().add(30, 'minutes');
        const minutesIn30mn = momentIn30mn.minutes();

        /**
         * We want "minutesRounded" to be a multiple of 5 (in order-button to be one of the values of the "minutes" array
         * First, we increment "minutesIn30mn", to have a date in the future
         * Then, we divide by 5, and we multiply by 5 and rounding to the highest number by using "ceil"
         */
        const minutesRounded = Math.ceil((minutesIn30mn + 1) / 5) * 5;

        // Now, we add the difference between "minutesRounded" and "minutesIn30mn" to our Moment object
        return momentIn30mn.add(minutesRounded - minutesIn30mn, 'minutes').toDate();
    }

    /**
     * Fills our models with the given "date"
     * This method is public, it needs to be accessible from the outside world (used in mys-website)
     */
    fillDateTimeFields(date: Date | null)
    {
        this.selectedMoment = moment(date);
        this.selectedHour = date.getHours();
        this.selectedMinutes = date.getMinutes();
        this.emitDateTime();
    }

    // endregion

    // region Lifecycle

    ngOnInit(): void
    {
        /**
         * Initializing our models based on the provided date (or the default one)
         */
        this.fillDateTimeFields(MslDateAndVehicleTypeComponent.defaultDate());
    }

    ngOnChanges(changes: SimpleChanges)
    {
        if ('isReservation' in changes && !changes.isReservation.firstChange) // If the change occurred on the "isReservation" property
        {
            this.emitDateTime();
        }
    }

    // endregion

    // region UI Interaction

    /**
     * Whenever the date or time changes, we need to rebuild our current Moment object to be able to trigger an
     * estimation any time
     */
    emitDateTime(optionalHourAndMinutes: MySamPair<number, number> | null = null): Moment | null
    {
        if (!!optionalHourAndMinutes)
        {
            this.selectedHour = optionalHourAndMinutes.first;
            this.selectedMinutes = optionalHourAndMinutes.second;
        }

        let selectedDateAndTime = null;
        if (this.isReservation) // If the user explicitly requested a Reservation, we set the "Booking Date"
        {
            selectedDateAndTime = moment(this.selectedMoment);
            selectedDateAndTime.hours(this.selectedHour);
            selectedDateAndTime.minutes(this.selectedMinutes);
        }

        this.dateTimeChanged.emit(selectedDateAndTime);
        return selectedDateAndTime;
    }

    // endregion
}
