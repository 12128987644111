import { RegisterUserError } from './register-user.error';

export class RegisterClientError extends RegisterUserError
{
    
}

namespace RegisterClientError
{

}
