import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MslFrenchDatePickerComponent } from './msl-french-date-picker.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatMomentDateModule
    ],
    declarations: [ MslFrenchDatePickerComponent ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'fr' }
    ],
    exports: [ MslFrenchDatePickerComponent, ReactiveFormsModule, MatDatepickerModule, MatInputModule, MatFormFieldModule,
        MatMomentDateModule ]
})
export class MslFrenchDatePickerModule
{
}
