import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Address, AddressPipe } from 'mys-base';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MslAutocompleteWrapperComponent, MslClearableAutocompleteComponent } from 'msl-components';
import { BehaviorSubject } from 'rxjs';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
    selector: 'msl-address-autocomplete',
    templateUrl: 'msl-address-autocomplete.component.html'
})
export class MslAddressAutocompleteComponent
{
    /**
     * The actual autocomplete field
     */
    @ViewChild('autocomplete') autocomplete: MslAutocompleteWrapperComponent;

    // region Inputs / Outputs

    @Input() label = '';
    @Input() values: any[] = [];
    @Input() disabled = false;
    @Input() errorFieldBlank = 'errors.DEFAULT-BODY';
    @Input() errorNoValueSelected = 'estimation.errors.NO-ADDRESS-SELECTED';
    @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();
    @Output() inputChangedAndDebounced = new EventEmitter<string>();
    @Output() inputCleared = new EventEmitter<void>(); // Emitted when the input is cleared by the user

    // endregion
    // region Constructor

    constructor(private addressPipe: AddressPipe)
    {
    }

    // endregion

    /**
     * This method is passed to the ClearableAutocomplete Component to tell it how to display an Address
     *
     * Since MatAutocomplete uses this method, we need it to handle the display of both Address objects and
     * Address-as-string objects
     * @param address The Address to display
     * @return An user-friendly address as a string
     */
    displayAddressInAutocomplete(address: Address | string): string
    {
        const addressPiped = this.addressPipe.transform(address as Address);
        if (!!addressPiped)
        {
            return addressPiped;
        }

        return address as string;
    }

    onInputCleared()
    {
        this.object$.next(null);
        this.inputCleared.emit();
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent)
    {
        this.object$.next(event.option.value);
        this.optionSelected.emit(event);
    }

    /**
     * Exposing the inner "autocomplete" object$ to the outside world
     */
    get object$(): BehaviorSubject<any>
    {
        return this.autocomplete.object$;
    }
}