/**
 * See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html
 * This annotation, along with the "@types/heremaps" NPM package given in "package.json", allows Webstorm to
 * know all about the types of the "H" object from HERE Maps
 *
 * This annotation must be at the top of the containing file (see the link above)
 */

/// <reference types="@types/heremaps" />

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    Input,
    Renderer2,
    ViewChild
} from '@angular/core';
import { HERE_API_KEY, IS_PRODUCTION } from './here-maps-injection-token';
import { LatLng } from 'mys-base';

/**
 * To use this component, don't forget to add the following at the end of the <body> tag in your index.html :
 *
 * <script src="https://js.api.here.com/v3/3.1/mapsjs-core.js" type="text/javascript" charset="utf-8"></script>
 * <script src="https://js.api.here.com/v3/3.1/mapsjs-service.js" type="text/javascript" charset="utf-8"></script>
 *
 * <!--
 *         https://developer.here.com/blog/displaying-places-on-a-here-map-in-an-angular-web-application
 *         Some more JS files, needed to display markers
 * -->
 * <script src="https://js.api.here.com/v3/3.1/mapsjs-mapevents.js" type="text/javascript" charset="utf-8"></script>
 * <script src="https://js.api.here.com/v3/3.1/mapsjs-ui.js" type="text/javascript" charset="utf-8"></script>
 */
@Component({
    selector: 'msl-here-map',
    templateUrl: './msl-here-map.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslHereMapComponent implements AfterViewInit
{
    // region Attributes

    // The UI map DOM Element
    @ViewChild('map') public mapElement: ElementRef;

    // endregion

    // region HERE-specific

    private platform: H.service.Platform;
    public hereMap: H.Map; // made public so you can add an event listener
    protected hereMapUi: H.ui.UI; // A UI object allowing to display extra data (info bubbles...)

    /**
     * We keep track of both markers, in order to remove them if the client changes his from/to address
     * However, we also need to place them into a Group, in order to use it as a Bounding Box for the map (zoom
     * while keeping both markers displayed on the screen)
     */
    protected markersGroup = new H.map.Group();
    private hereFromMarker: H.map.DomMarker | null = null;
    private hereToMarker: H.map.DomMarker | null = null;

    // endregion

    // region Inputs

    @Input() width = '400px';
    @Input() height = '400px';

    /**
     * Default params :
     * It's more or less the center of France, with a zoom small enough to display the whole country
     */
    @Input() lat = 46.6;
    @Input() lng = 1.9;
    @Input() zoom = 5;

    @Input() lang: string | null = null;

    // endregion

    // region Constructor

    public constructor(protected renderer2: Renderer2, @Inject(HERE_API_KEY) protected hereApiKey: string,
                       @Inject(IS_PRODUCTION) protected isProd: boolean)
    {
        this.platform = new H.service.Platform({
            "apikey": hereApiKey,
            "useHTTPS": isProd
        });
    }

    // endregion

    // region Markers

    /**
     * The DomMarker icon must be created dynamically, when adding it.
     * This method generates a <img> tag, with the appropriate "src" attribute, based on the "isFromAddress" value
     */
    private createDomMarkerIcon(isFromAddress: boolean): any
    {
        const imageColor = isFromAddress ? 'green' : 'red';
        const img = this.renderer2.createElement('img');
        this.renderer2.setAttribute(img, 'src',
            `https://maps.google.com/mapfiles/ms/icons/${imageColor}-dot.png`);

        return img;
    }

    displayMarker(latLng: LatLng, isFromAddress: boolean, shouldAnimate: boolean = true)
    {
        let hereMarker = isFromAddress ? this.hereFromMarker : this.hereToMarker;

        /**
         * If the corresponding marker already exists on the map, we remove it
         */
        if (!!hereMarker)
        {
            this.markersGroup.removeObject(hereMarker);
        }

        /**
         * https://developer.here.com/documentation/examples/maps-js/markers/zoom-to-set-of-markers
         *
         * First, we create an icon, displayable by HERE on the map
         */
        const icon = new H.map.DomIcon(this.createDomMarkerIcon(isFromAddress));

        /**
         * Then, we create a marker (at a specific location), that we bind to the icon previously created
         */
        hereMarker = new H.map.DomMarker({ lat: latLng.lat, lng: latLng.lng }, { icon: icon });
        if (isFromAddress)
        {
            this.hereFromMarker = hereMarker;
        }
        else
        {
            this.hereToMarker = hereMarker;
        }

        /**
         * Now we add it to our group
         */
        this.markersGroup.addObject(hereMarker);

        /**
         * Finally, we update the zoom level of our map in order to display all the markers
         */
        this.hereMap.getViewModel().setLookAtData({
            bounds: this.markersGroup.getBoundingBox()
        }, shouldAnimate);
    }

    // endregion

    // region Lifecycle

    public ngAfterViewInit()
    {
        /**
         * Building language parameters for HERE
         */
        const params = !!this.lang ? { lg: this.lang } : {};
        let defaultLayers = this.platform.createDefaultLayers(params);

        this.hereMap = new H.Map(
            this.mapElement.nativeElement,

            /**
             * Switched from "defaultLayers.vector.normal.map" to "defaultLayers.raster.normal.map", hoping to fix
             * https://mysamcab.atlassian.net/browse/MYS-6332
             */
            defaultLayers.raster.normal.map,
            {
                zoom: this.zoom,
                center: { lat: this.lat, lng: this.lng },

                /**
                 * Some padding is required to ensure that the markers will still be displayed if we resize the map
                 * using BoundingBox
                 */
                padding: {top: 50, left: 50, bottom: 50, right: 50}
            }
        );

        /**
         * Now, we add our group to the Map
         */
        this.hereMap.addObject(this.markersGroup);

        this.execAfterViewInit(defaultLayers);
    }

    /**
     * Method that can be overridden in subclasses to execute some more specific actions in afterViewInit
     * (using the DefaultLayers created in our ngAfterViewInit method)
     */
    protected execAfterViewInit(layers: H.service.DefaultLayers) {}

    // endregion
}
