<div class="row">
    <!-- Prefix selector -->
    <div class="col-sm-6">
        <mat-form-field *ngIf="countries$ | async">
            <mat-label>{{ 'words.PREFIX'| mysamTranslate }}</mat-label>
            <mat-select [formControl]="prefixInputCtrl">
                <ng-container *ngFor="let country of countries$ | async">
                    <mat-option [value]="country.id">
                        {{ country.phonePrefix }} ({{ country.name }})
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Phone number-->
    <div class="col-sm-6">
        <mat-form-field>
            <mat-label>{{ 'words.MOBILE' | mysamTranslate }}</mat-label>
            <input matInput type="text"
                   [formControl]="phoneSuffixInputCtrl">
            <mat-error *ngIf="phoneSuffixInputCtrl.hasError(getPatternErrorFn())">
                {{ 'errors.inputs.mobile.PATTERN' | mysamTranslate }}
            </mat-error>

            <mat-error *ngIf="phoneSuffixInputCtrl.hasError(getRequiredErrorFn())">
                {{ 'errors.inputs.REQUIRED' | mysamTranslate }}
            </mat-error>
        </mat-form-field>
    </div>
</div>
