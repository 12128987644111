import {NgModule} from '@angular/core';

import {LayoutModule} from '../layout/layout.module';
import {AppComponent} from './components/app/app.component';
import {routes} from '../routes/routes';
import {RouterModule} from '@angular/router';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {ToastrModule} from 'ngx-toastr';
import {MslClientRegisterModule} from 'msl-register';
import {IS_FROM_ALFRED, MySamBaseModule} from 'mys-base';
import {MslAddressesModule} from 'msl-addresses';
import {NgxFaviconModule} from 'ngx-favicon';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderLanguageInterceptor, MysamTranslateHttpLoader, MysamTranslateModule} from 'msl-translate';
import {HttpBackend} from '@angular/common/http';
import {Constants} from '../mysam/global/models/constants/constants';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {LibertyOrderUserSourceGenerator} from '../mysam/global/services/liberty-order-user-source-generator.service';
import {MysamCoreModule} from '../../../mys-base/src/lib/modules/mysam-core.module';
import {MslLoginModule} from 'msl-login';
import {ClientLoginState} from '../mysam/login/login-without-routing/state/client-login.state';
import {HeaderLibertySourceInterceptor} from './interceptors/header-liberty-source-interceptor';

/**
 * https://github.com/ocombe/ng2-translate/issues/218
 * If the method is not exported, the production build fails with the following message :
 * Error during template compile of 'AppModule'
 * Reference to a non-exported function.
 *
 * UPDATE 19/04/2022 - Usage of HttpBackend instead of HttpClient, in order to bypass our custom Interceptors.
 * If we don't, the "Accept-Language" of the HeaderLanguageInterceptor breaks the translation files loading (wtf...)
 */
export function createTranslateLoader(http: HttpBackend)
{
    return new MysamTranslateHttpLoader(http, [
        './assets/i18n/',
        './assets/i18n/register-client/',

        /**
         * Libraries JSON keys
         */
        './assets/i18n/login/',
        './assets/i18n/msl-create-trip/'
    ]);
}

const ngxsStates = [ClientLoginState];

const mslModules = [
    MslAddressesModule.forRoot(environment.BASE_URL + '/ws/addresses'),
    MslLoginModule.forRoot(),
    MslClientRegisterModule.forRoot(environment.BASE_URL + '/client/register', LibertyOrderUserSourceGenerator),
    MySamBaseModule.forRoot(environment.BASE_URL),
    MysamCoreModule.forRoot(Constants.AUTHENTICATION_BASIC, 'website-client', environment.BASE_URL,
        [HeaderLanguageInterceptor, HeaderLibertySourceInterceptor]),

    TranslateModule.forRoot(MysamTranslateModule.translateConfiguration(createTranslateLoader)),
    MysamTranslateModule
];

@NgModule({
    imports: [
        ...mslModules,
        BrowserAnimationsModule,
        SweetAlert2Module.forRoot(),
        ToastrModule.forRoot({
            maxOpened: 1,
            autoDismiss: true,
            positionClass: 'toast-top-center',
            preventDuplicates: true
        }),
        RouterModule.forRoot(routes, { enableTracing: false }),
        LayoutModule,
        SharedModule,

        NgxFaviconModule.forRoot<any>({
            faviconElementId: 'favicon',
            defaultUrl: 'assets/img/favicon.png',
            custom: {}
        }),
        NgxsModule.forRoot([...ngxsStates], { developmentMode: !environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ maxAge: 25, disabled: environment.production }),
        NgxsRouterPluginModule.forRoot()
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: [{ provide: IS_FROM_ALFRED, useValue: false } ]
})
export class AppModule
{
}
