import { Inject, Injectable } from '@angular/core';
import { Address, ApiService } from 'mys-base';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ADDRESSES_URL } from '../index';

@Injectable({ providedIn: 'root' })
export class AddressService extends ApiService
{
    /**
     * @param httpClient the HttpClient used in this Service
     * @param addressesURL The URL that shall be given by the application using this module
     */
    constructor(httpClient: HttpClient, @Inject(ADDRESSES_URL) protected addressesURL: string)
    {
        super(httpClient, addressesURL);
    }

    public getAddressFromLatLng(latitude: number, longitude: number): Observable<Address>
    {
        return this.httpClient.get<Address>(
            this.addressesURL + '/reverse-geocode?latitude=' + latitude + '&longitude=' + longitude
        );
    }

    public getAddressesFromUserSearch(userSearch: string, mapViewport: string | null): Observable<Address[]>
    {
        let url = this.addressesURL + '/search?query=' + userSearch;

        if (!!mapViewport)
        {
            url += '&viewport=' + mapViewport;
        }

        return this.httpClient.get<Address[]>(url);
    }
}
