import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { MslRegisterComponent } from './components/msl-register/msl-register.component';
import { MslPostRegisterComponent } from './components/msl-post-register/msl-post-register.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RegisterClientState } from './state/register-client.state';
import { RegisterService } from './service/register.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CREATE_CLIENT_URL } from './index';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserSourceGenerator } from '../../../mys-base/src/lib/services/interfaces/UserSourceGenerator';
import { USER_SOURCE_GENERATOR } from 'mys-base';
import { MslRegisterModule } from './msl-register.module';
import { MslPhoneNumberAndPrefixInputModule } from "msl-components";

const angularDependencies = [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule];
const materialDependencies = [MatCardModule, MatInputModule, MatFormFieldModule, MatCheckboxModule,
    MatButtonModule, MatProgressSpinnerModule, MatTooltipModule];
const components = [MslRegisterComponent, MslPostRegisterComponent];

@NgModule({
    imports: [
        ...angularDependencies,
        ...materialDependencies,
        MslPhoneNumberAndPrefixInputModule,
        MslRegisterModule,
        NgxsModule.forFeature([RegisterClientState])
    ],
    declarations: [...components],
    providers: [RegisterService],
    exports: [...components]
})
export class MslClientRegisterModule
{
    // This method allow 3rd-party apps to specify the URL that this module should use to create a client
    static forRoot(createClientURL: string, userSourceGenerator: Type<UserSourceGenerator>): ModuleWithProviders<MslClientRegisterModule>
    {
        return {
            ngModule: MslClientRegisterModule,
            providers: [
                {
                    provide: CREATE_CLIENT_URL,
                    useValue: createClientURL
                },
                {
                    provide: USER_SOURCE_GENERATOR,
                    useClass: userSourceGenerator
                }
            ]
        };
    }
}
