import { Component } from '@angular/core';
import { isLibertyOrder } from '../../../environments/liberty-order/website.environment';
import { MysamTranslateService } from 'msl-translate';

@Component({
    selector: 'mys-layout-container',
    templateUrl: './layout-container.component.html'
})
export class LayoutContainerComponent
{
    isLibertyOrder = isLibertyOrder;

    /**
     * Using the MysamTranslateService here actually initializes the TranslateModule and loads the translation files
     */
    constructor(translateService: MysamTranslateService)
    {
    }
}
