// region Action types

export const LOGIN = '[Alfred] Login';
export const LOGOUT = '[Alfred] Logout';
export const LOGOUT_WITHOUT_REDIRECTION = '[Alfred] Logout without Redirection';
export const REFRESH_TOKEN = '[Alfred] Refresh token';
export const REFRESH_TOKEN_FAILED = '[Alfred] Refresh token failed';

// endregion

export class LoginAction
{
    static readonly type = LOGIN;

    constructor(public payload: { email: string, password: string }, public shouldRedirectAfterLogin: boolean = true)
    {
    }
}

// region Logout

export class LogoutWithoutRedirectionAction
{
    static readonly type = LOGOUT_WITHOUT_REDIRECTION;
}

/**
 * Action defined here, but used in our sub-states (in Alfred & Liberty Order)
 */
export class LogoutAction
{
    static readonly type = LOGOUT;
}

// endregion

// region Refresh Tokens

export class RefreshTokenAction
{
    static readonly type = REFRESH_TOKEN;

    constructor(public refreshToken: string)
    {
    }
}

export class RefreshTokenFailedAction
{
    static readonly type = REFRESH_TOKEN_FAILED;

    constructor()
    {
    }
}

// endregion
