import { Driver } from './users/driver';
import { TripAppealType } from './constants/trip/trip-appeal-type';
import { TripStatus } from './constants/trip/trip-status';
import { $enum } from 'ts-enum-util';
import { Client } from './users/client';
import { Address } from './address';
import { TripType } from './constants/trip/trip-type';
import { VehicleType } from './constants/vehicle-type';
import { AlfredUser } from './users/alfred-user';
import { PaymentMethod } from './constants/payment-method';
import { ZoneSurcharge } from './zone-surcharge';
import { UserSource } from './constants/user-source';
import { TripOperator } from "./trip-operator";
import { ZoneFlatRate } from "./zone-flat-rate";
import { Exclusivity } from "./exclusivity";
import { Company } from "./company";

export class Trip
{
    // region Attributes

    id: number;
    client: Client;
    driver: Driver;
    fromAddress: Address;
    toAddress: Address;
    startDate: number; // Timestamp
    bookingDate: number | null; // Timestamp
    created: number; // Timestamp
    acceptedDate: number; // Timestamp
    endDate: number; // Timestamp
    type: TripType;
    assistance: boolean | null;
    status: TripStatus;
    vehicleType: VehicleType;
    totalDistance: number;
    estimatedDistanceInMeters: number;
    zdEstimatedPrice: number;
    zdForcedPrice: number | null;
    zdPrice: number;
    computedPriceWithKmAndTime: number;
    approachTime: number;
    estimatedDurationInSeconds: number;
    hourModifier: number;
    dateModifier: number;
    flatFee: boolean; // Is there a flat fee ?
    significantDisability: boolean;
    externalReference?: string;
    secondaryExternalReference?: string; // Local field, generated by splitting the "externalReference" on the space character
    comment: string;
    appeal: TripAppealType;
    driverAppeal: TripAppealType | null;
    createdBy: AlfredUser | null;

    /**
     * Exclusivity detail
     */
    exclusivity: Exclusivity | null;

    /**
     * TripWithCountdownConfirmation attributes
     */
    validated?: boolean;

    /**
     * TripWithClientPenalty attributes
     */
    zdClientPenalty = 0;

    /**
     * TripWithDriverPayment attributes
     */
    driverPayment?: number;

    /**
     * TripPricing attributes
     */
    paymentMethod: PaymentMethod;
    zoneSurcharge: ZoneSurcharge | null;
    willBePaidInCash: boolean | null;
    eurosDiscountedPrice: number;
    isMinimumPrice: boolean;
    zoneFlatRate: ZoneFlatRate | null;

    /**
     * TripSettings attributes
     */
    isAssistance: boolean;
    isPremium: boolean;
    source: UserSource;
    libertyCompanySource: Company | null;

    /**
     * PublicTransportSchedule attributes
     */
    theoreticalStopDate: Date | null;
    realtimeStopDate: Date | null;

    nbPassengers: number;

    /**
     * Added on 19/08/2020
     */
    isPricePaidByTheClientDifferentThanOurClientPrice = false;

    /**
     * Added on 02/08/2022
     */
    operators: TripOperator[] | null;

    // endregion

    /**
     * Deep casts (manually, so all fields might not be cast) the given "uncastedTrip"
     * @param uncastedTrip
     */
    static assign(uncastedTrip: any): Trip
    {
        const trip: Trip = Object.assign(new Trip(), uncastedTrip);

        /**
         * Casting non-nullable fields
         */
        trip.client = Client.assign(trip.client);
        trip.fromAddress = Object.assign(new Address(), trip.fromAddress);
        trip.toAddress = Object.assign(new Address(), trip.toAddress);
        trip.source = $enum(UserSource).getValueOrDefault(trip.source);
        trip.status = $enum(TripStatus).getValueOrDefault(trip.status);
        trip.type = $enum(TripType).getValueOrDefault(trip.type);
        trip.vehicleType = $enum(VehicleType).getValueOrDefault(trip.vehicleType);
        trip.appeal = $enum(TripAppealType).getValueOrDefault(trip.appeal);

        /**
         * Casting nullable fields here
         */
        if (!!trip.driver)
        {
            trip.driver = Driver.assign(trip.driver);
        }

        if(!!trip.exclusivity)
        {
            trip.exclusivity = Exclusivity.assign(trip.exclusivity);
        }

        if (!!trip.zoneSurcharge)
        {
            trip.zoneSurcharge = ZoneSurcharge.assign(trip.zoneSurcharge);
        }

        if (!!trip.zoneFlatRate)
        {
          trip.zoneFlatRate = Object.assign(new ZoneFlatRate(), trip.zoneFlatRate);
        }

        if (!!trip.operators)
        {
            trip.operators = trip.operators.map(op => Object.assign(new TripOperator(), op));
        }

        if(!!trip.driverAppeal)
        {
            trip.driverAppeal = $enum(TripAppealType).getValueOrDefault(trip.driverAppeal);
        }

        if(!!trip.libertyCompanySource)
        {

            trip.libertyCompanySource = Company.assign(trip.libertyCompanySource);
        }

        /**
         * https://mysamcab.atlassian.net/browse/MYS-3859
         * If the "externalReference" contains a space character, we split on it :
         * - The string after the first space character is the "secondaryExternalReference"
         * - The string before the first space character is the actual "external reference"
         */
        if (!!trip.externalReference)
        {
            /**
             * Since "indexOf" will return -1 if the searched string is not present, we need to explicitly set "secondaryExternalReference"
             * to null if "indexOfSpace" is -1. Otherwise, substr(-1) would return the last character of the string (and that's not what
             * we want)
             */
            const indexOfSpace = trip.externalReference.indexOf(' ');
            trip.secondaryExternalReference = indexOfSpace > -1 ? trip.externalReference.substr(indexOfSpace) : null;
            trip.externalReference = trip.externalReference.split(' ')[ 0 ];
        }

        return trip;
    }

    /**
     * Gets the "forced price" of the trip if any, otherwise gets the estimated price (both as zero-decimal values)
     */
    getForcedPriceOrEstimatedPriceAsZd(): number
    {
        return !!this.zdForcedPrice ? this.zdForcedPrice : this.zdEstimatedPrice;
    }
}
