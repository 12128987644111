import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiError } from '../errors/api-error';
import { BASE_API_URL } from '../index';

@Injectable({ providedIn: 'root' })
export class ApiService
{
    constructor(protected httpClient: HttpClient, @Inject(BASE_API_URL) protected BASE_URL: string)
    {
    }

    /**
     * Creates an ApiError from a HttpErrorResponse
     * @param err
     * @returns ApiError
     */
    public createApiError(err: HttpErrorResponse): ApiError
    {
        console.log(err);

        /**
         * The back-end tries to provide an object with extra data.
         * If we do have this extra data, we put it in our ApiError object.
         * Otherwise, we will settle with the default message
         *
         * UPDATE 09/03/2023 - The backend can now provide us some errors with a "translatedMessage" attribute.
         * If we have one, we keep it as our "errorDescription"
         * But first, we post-process it : We replace any \n in the "translatedMessage" by a <br/>
         */
        const errorDescription = err.error?.translatedMessage?.replace(/\n/g, '<br />')
            || err.error?.error_description || err.message;
        const errorType = err.error?.error_type || err.name;
        const extraParams = err.error?.extraParameters || {};

        return new ApiError(errorType, errorDescription, err.status, extraParams);
    }
}
