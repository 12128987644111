<mat-list>
    <!--
            "let lastVehicleType = last;" allows to set this variable only when we are on the last
            element of the collection
    -->
    <ng-container *ngFor="let vt of getVehicleTypeValues(); let lastVehicleType = last;">

        <msl-vehicle-radio-item-v2
                [vehicleType]="vt" [active]="vt === vehicleType" [zdPrice]="getZdPriceFromVehicleType(vt)"
                (clicked)="selectCurrentAndResetOthers($event)"></msl-vehicle-radio-item-v2>

        <!-- Removing the divider if we are on the last element of the collection -->
        <mat-divider *ngIf="!lastVehicleType"></mat-divider>
    </ng-container>
</mat-list>