/**
 * Created by Adrien Dos Reis on 31/10/2018
 * This interface maps the Estimation class on backend-side
 */
import { ZoneSurcharge } from './zone-surcharge';
import { TripType } from './constants/trip/trip-type';
import { VehicleType } from './constants/vehicle-type';
import { $enum } from 'ts-enum-util';

export class Estimation
{
    id: number;
    distance: number;
    duration: number;
    isPriceIncreased: boolean;
    price: number;

    tripType: TripType;
    vehicleType: VehicleType;

    zoneFlatRate: any | null; // We don't care about the actual object, we just want to know if it is NULL or not
    zoneSurcharge: ZoneSurcharge | null;

    static assign(uncastedEstimation: any): Estimation
    {
        const estimation: Estimation = Object.assign(new Estimation(), uncastedEstimation);

        estimation.tripType = $enum(TripType).getValueOrDefault(uncastedEstimation.tripType);
        estimation.vehicleType = $enum(VehicleType).getValueOrDefault(uncastedEstimation.vehicleType);

        if (!!estimation.zoneSurcharge)
        {
            estimation.zoneSurcharge = ZoneSurcharge.assign(estimation.zoneSurcharge);
        }

        return estimation;
    }
}
