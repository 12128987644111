import { Injectable } from '@angular/core';
import {ConvertDouble} from "../../../../../../alfred/src/alfred/utils/convert-double";

/**
 * Converts a value from a given currency to a zero decimal amount
 * Currently supports only euros
 *
 * This looks like a Pipe, but returns a number instead of a string.
 * If a Pipe is needed, use CurrencyToZeroPipe
 */
@Injectable()
export class CurrencyToZeroConverter
{
    transform(amount: number, currency: string = '€'): number
    {
        /**
         * We currently support only euros, but this method is extensible
         */
        switch (currency)
        {
            case '€': {
                return ConvertDouble.convertDoubleToZeroDecimal(amount);
            }
        }

        return amount;
    }
}
