import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService, BASE_API_URL, ConnectableUser } from 'mys-base';
import { LoginError } from '../errors/login.error';
import { OAuthToken } from '../models/oauth-token';
import { REALM } from '../../injection-tokens';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoginService extends ApiService
{
    // region Attributes

    static OAUTH_TOKEN_PATH = '/oauth/token';
    protected LOGIN_URL = this.BASE_URL + LoginService.OAUTH_TOKEN_PATH;

    // endregion

    // region Constructor

    constructor(httpClient: HttpClient, @Inject(BASE_API_URL) BASE_URL: string, @Inject(REALM) protected realm: string)
    {
        super(httpClient, BASE_URL);
    }

    // endregion

    /**
     * Sends a login request to the server
     */
    public login<U extends ConnectableUser>(email: string, password: string,
                                            castUserIntoSubclass: (user: ConnectableUser) => U): Observable<OAuthToken<U>>
    {
        return this.httpClient.post<OAuthToken<U>>(
            this.LOGIN_URL + '?grant_type=password&realm=' + this.realm +

            /**
             * Username & password are wrapped in a "encodeURIComponent" call, to preserve the special characters
             * from being removed/replaced by their "URL-friendly" counterparts
             */
            '&username=' + encodeURIComponent(email) +
            '&password=' + encodeURIComponent(password), ''
        ).pipe(map(oauth => OAuthToken.assign(oauth, castUserIntoSubclass)));
    }

    /**
     * Refreshes the OauthToken by sending a refreshToken to the server
     */
    public refreshToken<U extends ConnectableUser>(refreshToken: string,
                                                   castUserIntoSubclass: (user: ConnectableUser) => U): Observable<OAuthToken<U>>
    {
        return this.httpClient.post<OAuthToken<U>>(
            this.LOGIN_URL + '?grant_type=refresh_token&refresh_token=' + refreshToken, ''
        ).pipe(map(oauth => OAuthToken.assign(oauth, castUserIntoSubclass)));
    }

    createApiError(err: HttpErrorResponse): LoginError
    {
        const error = super.createApiError(err);
        return new LoginError(error);
    }
}
