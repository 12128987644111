/**
 * Created by Adrien on 17/12/2018
 * This class represents the User class from the back-end
 */
import { ConnectableUser } from './connectable-user';
import { Company } from '../company';
import { $enum } from 'ts-enum-util';
import { UserSource } from '../constants/user-source';

export class MobileUser extends ConnectableUser
{
    address: string;
    city: string;
    latitude: number;
    longitude: number;
    locationDate: Date;
    mobilePhoneNumber: string;
    photoFileName: string;
    zipCode: string;
    referral_code: string;
    source: UserSource;

    /**
     * Added on 24/04/2020, now that Drivers can also be bound to Companies.
     * However, if this MobileUser is a Client, this attribute will ONLY be filled if the Company is Premium
     */
    company: Company | null;

    static assign(unassignedMobileUser: any): MobileUser
    {
        const connectableUser = ConnectableUser.assign(unassignedMobileUser);
        const mobileUser = Object.assign(new MobileUser(), connectableUser);

        mobileUser.source = $enum(UserSource).getValueOrDefault(mobileUser.source);

        return mobileUser;
    }
}
