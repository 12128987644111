import { AddressType } from '../../constants/address-type';

export const SEARCH_ADDRESSES = '[Addresses] Search Addresses';
export const RESET_SEARCH_ADDRESSES = '[Addresses] Reset Addresses';

export class SearchAddresses
{
    static readonly type = SEARCH_ADDRESSES;

    constructor(public addressAsString: string, public addressType: AddressType, public mapViewport?: string)
    {
    }
}

export class ResetSearchAddresses
{
    static readonly type = RESET_SEARCH_ADDRESSES;

    constructor()
    {
    }
}
