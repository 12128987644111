import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'msl-significant-disability-radio',
    templateUrl: './msl-significant-disability-radio.component.html'
})
export class MslSignificantDisabilityRadioComponent
{
    @Input() disabled = false;
    @Output() disabilityLevelChanged = new EventEmitter<boolean | null>();
}
