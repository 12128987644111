import { TranslateService } from '@ngx-translate/core';
import { computed, effect, Injectable, Injector, Optional } from '@angular/core';
import { delay, Observable, pipe } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Language } from '../language';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { MysamLanguageHolder } from './mysam-language-holder';
import { WordReplacer } from '../word-replacer';
import { areAllAsyncTranslationsLoaded } from '../mysam-translate-http-loader';
import { toObservable } from '@angular/core/rxjs-interop';

/**
 * Created by Adrien Dos Reis on 18/02/2019
 *
 * Extension of TranslateService providing a dynamic replacement of "Clients" & "Employees" terms based on the
 * current language
 */
@Injectable({ providedIn: 'root' })
export class MysamTranslateService
{
    // region Attributes

    static LANGUAGE = 'language';

    // The language is defined in the constructor to avoid " Function calls are not supported in decorators but 'Language' was called in
    // 'TranslatorService' " errors while building with AOT for the production environment
    static availableLangs: Language[] = [
        new Language('fr', WordReplacer.FRENCH_LANGUAGE, 'FRE', localeFr),
        new Language('en', WordReplacer.ENGLISH_LANGUAGE, 'ENG', localeEn)
    ];

    private languageAsString: string;

    // endregion

    // region Constructor

    constructor(public translate: TranslateService, @Optional() private wordReplacerService: WordReplacer | null,
                private languageHolder: MysamLanguageHolder, private injector: Injector)
    {
        /**
         * Adding all "availableLangs" to the TranslateService
         */
        this.translate.addLangs(MysamTranslateService.availableLangs.map(lang => lang.code));

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');

        this.useLanguage(this.getLanguageCode());
    }

    // endregion

    // region Get Language

    static getLangByCode(code: string): Language
    {
        const language = this.availableLangs.filter(lang => lang.code === code)[ 0 ];

        /**
         * If no language could be found, we want to throw an Error and stop the execution
         */
        if (language === undefined)
        {
            throw new Error(`The language ${code} could not be found !`);
        }

        return language;
    }

    useLanguage(langCode: string)
    {
        this.languageAsString = langCode;
        this.translate.use(this.languageAsString);

        const currentLang = MysamTranslateService.getLangByCode(this.languageAsString);
        this.languageHolder.currentLang = currentLang;

        // Register global locale data to be used
        registerLocaleData(currentLang.locale, currentLang.code);

        localStorage.setItem(MysamTranslateService.LANGUAGE, this.languageAsString);
    }

    /**
     * This private method extracts a language code from either the user preferences (if he ever chose a language), or from the
     * browser settings
     */
    private getLanguageCode(): string
    {
        /**
         * If a language was stored, we try to reuse it instead of the default language
         */
        const storedLanguage = localStorage.getItem(MysamTranslateService.LANGUAGE);

        /**
         * https://stackoverflow.com/a/36914213/2294082
         * Looks like "navigator.language" is the standard, but is replaced by "navigator.userLanguage" on SOME browsers (hello IE)
         */
            // @ts-ignore
        const language = (!!storedLanguage) ? storedLanguage : navigator.language || navigator.userLanguage;

        /**
         * "userLang" is formatted like 'fr-FR', we only want the prefix before the dash character
         * If there is no dash, we keep the whole "language" string
         */
        let indexOfDash = language.indexOf('-');
        if (indexOfDash === -1)
        {
            indexOfDash = language.length;
        }

        return language.substr(0, indexOfDash);
    }

    // endregion

    // region Get Translation

    instant(key: string | Array<string>, interpolateParams?: Object): string | any
    {
        return this.translateStringOrArray(key, interpolateParams);
    }

    get(key: string | Array<string>, interpolateParams?: Object): Observable<string | any>
    {
        /**
         * Using "translate.get" would only work with translations loaded through the JSON files, but not with
         * translations loaded using MysamTranslateService.setTranslation (because MysamTranslateService.setTranslation
         * would wait for the JSON files to be loaded, and "translate.get" would be triggered after loading the JSON
         * files but riiiight before MysamTranslateService.setTranslation could execute)
         *
         * To avoid this caveat, we use the same condition here to wait for all translations to be loaded before
         * returning the translated value synchronously
         *
         * The "{ injector: this.injector }" option must be added, otherwise Angular would complain whenever this method
         * is called from outside a Component or Directive
         */
        return toObservable(computed(() => {
            if (areAllAsyncTranslationsLoaded())
            {
                return this.translateStringOrArray(key, interpolateParams);
            }
        }), { injector: this.injector });
    }

    /**
     * A translation can be done one key at a time, or by passing an array of keys.
     * This method handles both
     * @param stringOrArray
     * @param interpolateParams Parameters to interpolate into the translated string
     */
    private translateStringOrArray(stringOrArray: string | any, interpolateParams?: Object): string | any
    {
        /**
         * function for get the translation
         */
        const getTranslation = ((stringOrArray: any) =>
        {
            return !!this.wordReplacerService ? this.wordReplacerService.replaceTerms(
                    this.translate.instant(stringOrArray, interpolateParams), this.languageAsString)
                : this.translate.instant(stringOrArray, interpolateParams);
        });

        if (typeof stringOrArray === 'object')
        {
            /**
             * "stringOrArray" should be formatted as follows :
             * { aKey : 'aValue', anotherKey: 'aNewValue', ...}
             *
             * We need to parse each value, and replace the terms to be replaced for each key
             */
            const result = {};
            for (const key of Object.keys(stringOrArray))
            {
                const translated = getTranslation(stringOrArray[ key ]);

                // We define a new property on our anonymous object. We need to define it as "enumerable", in order to be able to
                // iterate over our object with Object.keys()
                Object.defineProperty(result, key, { value: translated, enumerable: true });
            }

            return result;
        }
        // We assume "stringOrArray" is a string
        return getTranslation(stringOrArray);
    }

    // endregion

    // Add Translations

    /**
     * Delegates the creation of new translations to the inner TranslateService
     *
     * Should be called as follows :
     * setTranslation('en', {
     *     HELLO: 'hello {{value}}'
     * });
     */
    setTranslation<T>(lang: string, translations: T)
    {
        /**
         * Added by Adrien Dos Reis on 20/07/2023
         * This effect allows to wait until "areAllAsyncTranslationsLoaded" becomes true, then adds
         * the given "translations" to the TranslateService.
         *
         * See MysamTranslateHttpLoader for more info : Basically, every translation added this way would be erased
         * by the asynchronously-laoded JSON translation files. So, we wait for all those JSON files to be loaded
         * before adding our own translations
         */
        effect(() =>
        {
            if (areAllAsyncTranslationsLoaded())
            {
                /**
                 * "shouldMerge" = true allows not to erase the existing JSON translations, and merge them with the
                 * given "translations" (otherwise, all other translations are deleted)
                 */
                this.translate.setTranslation(lang, translations, true);
            }
        }, { injector: this.injector });
    }

    // endregion
}
