/**
 * Created by Adrien Dos Reis on 23/10/2020
 */
import { Compiler, Injector, NgModuleFactory, Type } from '@angular/core';

export class DynamicImport
{
    /**
     * See https://mysamcab.atlassian.net/browse/MYS-4624
     * Also see https://medium.com/@ebrehault_25985/angular-9-makes-lazy-loading-without-routing-so-easy-18774092ed34
     *
     * Lazy loading a module dynamically is not as simple as calling "import(...).then()" (this part is needed, but
     * not enough)
     *
     * This method will fully load the module (identified by the type "M"), whose importation was already started
     * through an "import(...).then()" call (resulting in the given "modulePromise").
     *
     * Full load of the module implies two things :
     * - First, loading the Components of the Module
     * - Then, loading all the other elements (Services, States, etc...)
     * This method takes two callbacks, which will be executed at each step of the loading
     * (= components loaded / module fully loaded)
     *
     * We cannot wrap the "import(...).then()" into our method, since "import" is not really a JS function (and
     * therefore, we would lose the strong typing)
     *
     * So, that method should be called as follows :
     * const importPromise = import('path/to/module/XXX.module').then(mod => mod.XXXModule);
     * DynamicImport.loadModule(importPromise, ...)
     */
    static loadModule<M extends Type<any>>(modulePromise: Promise<M>, compiler: Compiler, injector: Injector,
                                           execWhenComponentsLoaded: (module: M) => void = () => {},
                                           execWhenModuleLoaded: (factory: NgModuleFactory<M>) => void = () => {})
    {
        /**
         * See https://mysamcab.atlassian.net/browse/MYS-4624
         * Also see https://medium.com/@ebrehault_25985/angular-9-makes-lazy-loading-without-routing-so-easy-18774092ed34
         */
        modulePromise.then(lazyModule =>
        {
            execWhenComponentsLoaded(lazyModule);
            return lazyModule;
        })
            .then(lazyModule => compiler.compileModuleAsync(lazyModule))
            .then(factory =>
            {
                factory.create(injector);
                execWhenModuleLoaded(factory);
            });
    }
}