export enum TripStatus
{
    ASSIGNED = 'enums.trip-status.ASSIGNED',
    CANCELED = 'enums.trip-status.CANCELED',
    FINISHED = 'enums.trip-status.FINISHED',
    NO_DRIVER_AVAILABLE = 'enums.trip-status.NO_DRIVER_AVAILABLE',
    PAYMENT_ISSUE = 'enums.trip-status.PAYMENT_ISSUE',
    SOURCE_AUTHORIZATION = 'enums.trip-status.SOURCE_AUTHORIZATION',
    STARTED = 'enums.trip-status.STARTED',
    THREE_D_SECURE_PENDING = 'enums.trip-status.THREE_D_SECURE_PENDING',
    WAITING = 'enums.trip-status.WAITING'
}
