<div class="row">

    <!-- "Time picker" -> Two lists (hours and minutes) -->
    <msl-simple-select [placeholder]="'misc.HOUR' | translate" [data]="hours"
                       [model]="selectedHour" (modelChange)="selectedHour = $event; emitHourOrMinuteChange()"
                       class="col-6"></msl-simple-select>

    <msl-simple-select [placeholder]="'misc.MINUTES' | translate" [data]="minutes"
                       [model]="selectedMinutes" (modelChange)="selectedMinutes = $event; emitHourOrMinuteChange()"
                       class="col-6"></msl-simple-select>

</div>