/**
 * Created by Adrien Dos Reis on 08/04/2021
 */
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASIC_AUTH_STRING } from 'msl-login';
import { BASE_API_URL, MysamBaseInterceptor } from 'mys-base';

@Injectable()
export class HttpBasicInterceptor extends MysamBaseInterceptor
{
    // region Constructor

    constructor(@Inject(BASE_API_URL) private BASE_URL: string, @Inject(BASIC_AUTH_STRING) private basicAuthString: string)
    {
        super(BASE_URL);
    }

    // endregion

    override interceptIfMySamRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        /**
         * If the request headers does not contain any Authorization of any kind, we add a "Basic" services
         * (in order to let our server know that the network call is coming from one of our frontends)
         */
        if (!request.headers.has('Authorization'))
        {
            request = request.clone({ headers: request.headers.set('Authorization', this.basicAuthString) });
        }

        return next.handle(request);
    }
}
