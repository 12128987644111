/**
 * Created by Adrien Dos Reis on 09/03/2021
 */
export enum LocationPermissionState
{
    ALWAYS = 'enums.location-permission-state.ALWAYS',
    COARSE_AND_FOREGROUND_ONLY = 'enums.location-permission-state.COARSE-AND-FOREGROUND-ONLY',
    COARSE_ONLY = 'enums.location-permission-state.COARSE-ONLY',
    DENIED = 'enums.location-permission-state.DENIED',
    FOREGROUND_ONLY = 'enums.location-permission-state.FOREGROUND-ONLY'
}