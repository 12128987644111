import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyToZeroConverter } from './currency-to-zero.converter';

/**
 * Converts a value from a given currency to a zero decimal amount
 * Currently supports only euros
 */
@Pipe({
    name: 'currencyToZero'
})
export class CurrencyToZeroPipe implements PipeTransform
{
    constructor(private currencyToZeroConverter: CurrencyToZeroConverter)
    {
    }

    transform(amount: number, currency: string = '€'): string
    {
        return '' + this.currencyToZeroConverter.transform(amount, currency);
    }
}
