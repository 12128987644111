import { AlertPriority, AlfredUser, Client, Driver } from "mys-base";

export class Alert
{
    id: number;
    driver: Driver | null;
    client: Client | null;
    tripId: number | null;
    priority: AlertPriority; // AlfredAlertType

    operator: AlfredUser | null = null;
    creationDate: Date;
    resolutionDate: Date | null = null;
    description: string;
}
