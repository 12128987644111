import { ApiError } from 'mys-base';

export class RegisterUserError extends ApiError
{
    public getTranslateCode(): string
    {
        switch (this.type)
        {
            case RegisterUserError.Type.EmailAlreadyExists:
                return 'errors.user-register.EMAIL-ALREADY-EXISTS';

            case RegisterUserError.Type.InvalidEmailSyntax:
                return 'errors.user-register.INVALID-EMAIL-SYNTAX';

            case RegisterUserError.Type.ReferralCodeNotFound:
                return 'errors.user-register.REFERRAL_CODE_NOT_FOUND';

            default: return 'errors.DEFAULT-BODY';
        }
    }
}

export namespace RegisterUserError
{
    export enum Type
    {
        EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
        InvalidEmailSyntax = 'INVALID_EMAIL_SYNTAX',
        ReferralCodeNotFound = 'REFERRAL_CODE_NOT_FOUND'
    }
}
