import { Component } from '@angular/core';
import { MslButtonComponent } from '../msl-button/msl-button.component';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'msl-accent-button',
    templateUrl: '../msl-button/msl-button.component.html',
    styleUrls: [ '../msl-button/msl-button.component.scss', './msl-accent-button.component.scss' ]
})
export class MslAccentButtonComponent extends MslButtonComponent
{
    color: ThemePalette = 'accent';
}
