import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { AbstractSuccess, Client, PatternsUtil, RoutingHelper, StringsUtil, USER_SOURCE_GENERATOR } from 'mys-base';
import { RegisterClientRequest } from '../../service/requests/register-client-request';
import { Select, Store } from '@ngxs/store';
import { RegisterClient } from '../../actions/register.action';
import { RegisterClientState } from '../../state/register-client.state';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { RegisterClientError } from '../../errors/register-client.error';
import { MslPostRegisterComponent } from '../msl-post-register/msl-post-register.component';
import { UserSourceGenerator } from '../../../../../mys-base/src/lib/services/interfaces/UserSourceGenerator';

@Component({
    selector: 'msl-register',
    templateUrl: './msl-register.component.html',
    styleUrls: [ './msl-register.component.scss' ]
})
export class MslRegisterComponent extends AbstractSuccess implements OnInit, AfterViewInit, OnDestroy
{
    // region Static fields

    /* These are needed as static as they are called within a context where this is undefined. */
    static readonly emailError = 'email';
    static readonly emailAlreadyUsedError = 'emailAlreadyUsed';
    static readonly emptyError = 'empty';
    static readonly passwordNoMatchError = 'noMatch';
    static readonly patternError = 'pattern';
    static readonly requiredError = 'required';

    RoutingHelper = RoutingHelper;

    // endregion

    // region Inputs / Outputs

    /**
     * If false, the "sponsorCodeInput" will be hidden, and no "sponsorCode" will be sent in the RegisterClientRequest.
     * The liberty-signup process should rely on other ways (e.g. "Liberty-Source-Company-Id" header ?) to send any
     * referral information to the server
     */
    @Input() displaySponsorCode = true;

    /**
     * UPDATE 20/12/2023 - https://mysamcab.atlassian.net/browse/MYS-6464
     * URL redirecting to the "Terms of Use". By default, those are MySam terms of use, but can be overridden
     * by Liberty Order for example
     */
    _termsOfUseUrl: string;
    @Input() set termsOfUseUrl(termsOfUseUrl: string)
    {
        /**
         * The usage of a "setter Input" instead of simply a default value allows us to handle the case where
         * the caller might provide a "null" termsOfUseUrl (lo-register.component might do that if its current
         * configuration is NOT LO (= Reservation Website). In this case, we fall back to the default
         * https://mysam.fr terms of use
         */
        this._termsOfUseUrl = termsOfUseUrl ?? "https://mysam.fr/condition-dutilisations/";
    }

    /**
     * Emitted when a request is sent to the server with the given RegisterClientRequest
     */
    @Output() registerRequestSent = new EventEmitter<RegisterClientRequest>();

    // endregion

    // region Fields

    /**
     * Should the "Passwords" fields be displayed ?
     * This attribute is here, in the library (whereas the usual "shouldDisplay..." are in the 3rd-party components), since we need it
     * to update the password validation method
     *
     * The getter is public, in order to access it through the HTML of the 3rd-party components
     * The setter is protected, in order to force the 3rd-party components to go through it to change the "shouldDisplayPassword" value
     */
    private _shouldDisplayPassword = true;
    get shouldDisplayPassword(): boolean
    {
        return this._shouldDisplayPassword;
    }

    /**
     * When the value changes, we need to re-create the Password FormGroup (in order for it to acknowledge our changes)
     * @param value The new "shouldDisplayPassword" value
     */
    set shouldDisplayPassword(value: boolean)
    {
        this._shouldDisplayPassword = value;
        this.passwordGroup = this.initPasswordFormGroup();

        /**
         * If the "registerForm" exists, we update its "passwordGroup" control
         */
        if (!!this.registerForm)
        {
            this.registerForm.removeControl('passwordGroup');
            this.registerForm.addControl('passwordGroup', this.passwordGroup);
        }
    }

    protected subscription = new Subscription();

    // endregion

    // region FormControlGroup

    /* Build password form inputs */
    passwordInput = new FormControl<string>('');
    passwordConfirmInput = new FormControl<string>('');

    /* Create a form group for passwords field as we need a custom validator between them */
    passwordGroup = this.initPasswordFormGroup();

    /* Build form inputs */
    acceptTermsInput = new FormControl<boolean>(false, Validators.pattern('true'));
    emailInput = new FormControl<string>('', [ Validators.email, Validators.required ]);
    firstNameInput = new FormControl<string>('', Validators.required);
    lastNameInput = new FormControl<string>('', Validators.required);
    phonePrefixIdInput = new FormControl<number>(1); // Selected prefix, defined by default by the French prefix
    phoneSuffixInput = new FormControl<string>('', [ Validators.required, Validators.pattern(PatternsUtil.ONLY_NUMBER_REGEXP), Validators.minLength(4) ]);
    sponsorCodeInput = this.revalidateSponsorCodeFormControl(true);
    companyNameInput = new FormControl<string>(null);

    /* Main register form group */
    registerForm = new UntypedFormGroup(
        {
            acceptTermsInput: this.acceptTermsInput,
            emailInput: this.emailInput,
            firstNameInput: this.firstNameInput,
            lastNameInput: this.lastNameInput,
            prefixInput: this.phonePrefixIdInput,
            mobileInput: this.phoneSuffixInput,
            sponsorCodeInput: this.sponsorCodeInput,
            companyNameInput: this.companyNameInput,
            passwordGroup: this.passwordGroup
        }
    );

    // endregion

    // region UI Elements

    @ViewChild('registerContent', { read: ViewContainerRef }) private registerContent: ViewContainerRef;

    // endregion

    // region Selectors

    @Select(RegisterClientState.client) client$: Observable<Client>;
    @Select(RegisterClientState.loading) isLoading$: Observable<boolean>;
    @Select(RegisterClientState.registerClientError) registerError$: Observable<RegisterClientError>;

    // endregion

    // region Static methods

    /**
     * Update the given password abstract control object to perform any business checks on the field.
     *
     * @param input - Current password abstract control
     * @param otherValue - Value to compare to, as it should matches
     */
    static buildPasswordInputErrors(input: AbstractControl, otherValue: string): ValidationErrors | null
    {
        let error = null;

        /**
         *  Check each errors cases
         *      - If field isn't empty
         *      - If it matches the password pattern
         *      - If it matches the other password value
         */
        const isNotEmpty = !StringsUtil.isBlank(input.value);
        const doesMatchesPattern = PatternsUtil.PASSWORD_REGEXP.test(input.value);
        const doesMatchesOtherValue = input.value === otherValue;

        if (!isNotEmpty || !doesMatchesPattern || !doesMatchesOtherValue)
        {
            // We have to create the errors object here to use reflection to set values to avoid errors in html
            error = {};

            if (!isNotEmpty)
            {
                Reflect.set(error, MslRegisterComponent.emptyError, !isNotEmpty);
            } else if (!doesMatchesPattern)
            {
                Reflect.set(error, MslRegisterComponent.patternError, !doesMatchesPattern);
            } else if (!doesMatchesOtherValue)
            {
                Reflect.set(error, MslRegisterComponent.passwordNoMatchError, !doesMatchesOtherValue);
            }

            input.markAsTouched();
        }

        input.setErrors(error);
        return error;
    }

    /**
     * Validate if password and password confirm inputs matches.
     * @param group - FormGroup object that needs to be validated
     */
    static checkPasswordsMatch(group: UntypedFormGroup): ValidationErrors | null
    {
        /* Extract passwords inputs */
        const password1 = group.controls.passwordInput;
        const password2 = group.controls.passwordConfirmInput;

        if (password1.dirty || password2.dirty || password1.touched || password2.touched)
        {
            /* Build errors for a given input */
            const error1 = MslRegisterComponent.buildPasswordInputErrors(password1, password2.value);
            const error2 = MslRegisterComponent.buildPasswordInputErrors(password2, password1.value);
            return (error1 == null) ? error2 : error1;
        } else
        {
            return Validators.required(password1) && Validators.required(password2);
        }
    }

    // endregion

    constructor(public store: Store,
                @Inject(USER_SOURCE_GENERATOR) private readonly userSourceGenerator: UserSourceGenerator,
                componentFactoryResolver: ComponentFactoryResolver, renderer: Renderer2)
    {
        super(componentFactoryResolver, renderer);
        this.successComponent = MslPostRegisterComponent;
    }

    /**
     * Utility method to register to subscription form success and errors events.
     */
    protected subscribeToRegisterEvents(): void
    {
        this.subscription.add(this.registerError$.pipe(
            filter(error => !!error),
            map(error => this.showErrorState(error))
        ).subscribe());
    }

    // region UI Actions

    /**
     * Called when user tap on the submit button of the register form.
     */
    onSubmit(): void
    {
        /**
         * We ensure that the form is valid (if the client hits F12 on his browser, then explicitly removes the "disabled"
         * attribute on the "submit" button, we need to re-check here)
         */
        if (this.registerForm.valid)
        {
            /**
             * Builds a RegisterClientRequest, sends it to the server, and also emits it (for parent components to know about this
             * request, and maybe trigger a login request once the liberty-signup is completed ?)
             */
            const request = this.buildRegisterClientRequest();
            this.sendRegisterClientRequest(request);
            this.registerRequestSent.emit(request);
        }
    }

    showErrorState(error: RegisterClientError): void
    {
        /** Display current errors value */
    }

    // endregion

    // region Convenience Tools

    /**
     * Builds a RegisterClientRequest from the Form elements that we know about in this component.
     * @return a RegisterClientRequest that can be used to create a client, or improved with more data
     */
    buildRegisterClientRequest(): RegisterClientRequest
    {
        const registerClientReq = new RegisterClientRequest();
        registerClientReq.email = this.emailInput.value;
        registerClientReq.firstName = this.firstNameInput.value;
        registerClientReq.lastName = this.lastNameInput.value;
        registerClientReq.phonePrefixCountryId = this.phonePrefixIdInput.value;
        registerClientReq.mobilePhoneNumber = this.phoneSuffixInput.value;
        registerClientReq.referrer = this.displaySponsorCode ? this.sponsorCodeInput.value : '';
        // We need to force the companyName to null if the field is empty because if a value has been entered and it has been deleted, it will be replaced by ''
        registerClientReq.companyName = !!this.companyNameInput.value ? this.companyNameInput.value : null;
        registerClientReq.setSource(this.userSourceGenerator.getSource());

        /**
         * UPDATE 14/06/2019
         * https://mysamcab.atlassian.net/browse/MYS-3504
         *
         * If the passwords fields are hidden, we send a dummy password to the server (the user can't log in
         * with this one, but we still need a valid password in order to register our client successfully)
         */
        if (this.shouldDisplayPassword)
        {
            registerClientReq.password = this.passwordInput.value;
        }
        else
        {
            registerClientReq.password = 'dummyP4ssw0rd';
        }

        return registerClientReq;
    }

    /**
     * Sends the actual RegisterClientRequest to the server
     * @param registerClientReq The request to send
     */
    protected sendRegisterClientRequest(registerClientReq: RegisterClientRequest): void
    {
      this.store.dispatch(new RegisterClient(registerClientReq));
    }

    /**
     * Returns a FormGroup that can be fit into our "passwordGroup" variable
     */
    private initPasswordFormGroup(): UntypedFormGroup
    {
        /**
         * First, we check the value of "shouldDisplayPassword".
         * If it is false (= no password is displayed nor needed), we don't want to validate the passwords fields
         */
        const validatorFn = (this.shouldDisplayPassword) ? MslRegisterComponent.checkPasswordsMatch : null;

        return new UntypedFormGroup({
            passwordInput: this.passwordInput,
            passwordConfirmInput: this.passwordConfirmInput
        }, validatorFn);
    }

    /**
     * UPDATE 23/09/2019
     * https://mysamcab.atlassian.net/browse/MYS-3761
     * This method allows to specify whether the "sponsorCode" should only contain digits, or if we can bypass this control
     * It resets the validators the sponsor code form control based on the "sponsorCodeOnlyDigits" value
     *
     * Returns the FormControl for convenience
     */
    protected revalidateSponsorCodeFormControl(sponsorCodeOnlyDigits: boolean): UntypedFormControl
    {
        /**
         * If the FormControl doesn't exist yet, we create it
         */
        if (!this.sponsorCodeInput)
        {
            this.sponsorCodeInput = new FormControl<string>('', sponsorCodeOnlyDigits ? Validators.pattern(PatternsUtil.ONLY_NUMBER_REGEXP) : null);
        }
        /**
         * Here we update the existing FormControl
         */
        else
        {
            this.sponsorCodeInput.setValidators(sponsorCodeOnlyDigits ? Validators.pattern(PatternsUtil.ONLY_NUMBER_REGEXP) : null);
            this.sponsorCodeInput.updateValueAndValidity(); // To let Angular know we changed the validators
        }

        return this.sponsorCodeInput;
    }

    // noinspection JSMethodCanBeStatic
    getEmailError(): string
    {
        return MslRegisterComponent.emailError;
    }

    // noinspection JSMethodCanBeStatic
    getEmailAlreadyUsedError(): string
    {
        return MslRegisterComponent.emailAlreadyUsedError;
    }

    // noinspection JSMethodCanBeStatic
    getEmptyError(): string
    {
        return MslRegisterComponent.emptyError;
    }

    // noinspection JSMethodCanBeStatic
    getPasswordNoMatchError(): string
    {
        return MslRegisterComponent.passwordNoMatchError;
    }

    // noinspection JSMethodCanBeStatic
    getPatternError(): string
    {
        return MslRegisterComponent.patternError;
    }

    // noinspection JSMethodCanBeStatic
    getRequiredError(): string
    {
        return MslRegisterComponent.requiredError;
    }

    // endregion

    // region Lifecycle

    ngOnInit()
    {
        this.subscribeToRegisterEvents();
    }

    ngAfterViewInit()
    {
        /**
         * If the success component still has its default value ( = has not been redefined by sub-classes), we set this default
         * behavior
         */
        if (this.successComponent === MslPostRegisterComponent)
        {
            /**
             * Once the client is registered, we can pass it to our "displaySuccessComponent" method
             */
            this.subscription.add(this.client$.pipe(
                filter(client => !!client),
                tap(() => this.displaySuccessComponent(this.registerContent))
            ).subscribe());
        }
    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }

    // endregion
}
