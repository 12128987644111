/**
 * Created by Adrien Dos Reis on 27/05/2020
 *
 * This interface represents how the MySamTranslateService should replace terms :
 * The multiple client applications can have their own replacement rules (in Alfred, it's based on whether the user
 * is from MySam or not, while in Website it's only based on the environment), and they should be defined
 * in those applications
 */
export abstract class WordReplacer
{
    // region Attributes

    static ENGLISH_LANGUAGE = 'English';
    static FRENCH_LANGUAGE = 'French';

    // endregion

    abstract replaceTerms(translatedString: string, language: string): string;

    /**
     * Replaces the given "searchValue" in the "origin" string, but makes the replace "pseudo-case-sensitive" :
     * It replaces any occurrences of "lowercase searchValue" by "lowercase replaceValue", then replaces "uppercase searchValue"
     * by "uppercase replaceValue", then finally replaces "capitalized searchValue" by "capitalized replaceValue"
     * @param origin The original string
     * @param searchValue The searched value
     * @param replaceValue The replace value
     * @return The new string
     */
    protected replaceCaseSensitive(origin: string, searchValue: string, replaceValue: string): string
    {
        return origin.replace(searchValue.toLowerCase(), replaceValue.toLowerCase())
            .replace(searchValue.toUpperCase(), replaceValue.toUpperCase())
            .replace(this.capitalize(searchValue), this.capitalize(replaceValue));
    }

    /**
     * Capitalizes the given string
     * @param str The string to capitalize
     * @return The capitalized string
     */
    protected capitalize(str: string): string
    {
        if (str.length <= 1)
        {
            return str.toUpperCase();
        }

        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
