<!-- These buttons allow to choose between an Immediate Trip and a Reservation -->
<div *ngIf="!shouldHideTripTypeButtons" class="row g-0">

    <div class="col me-2">
        <msl-primary-button (click)="isReservation = false" [disabled]="disabled"
                            [class]="'w-100'" [inactive]="isReservation">
            {{ 'misc.ORDER-NOW' | translate }}
        </msl-primary-button>
    </div>

    <div class="col me-2">
        <msl-primary-button (click)="isReservation = true" [disabled]="disabled"
                            [class]="'w-100'" [inactive]="!isReservation">
            {{ 'misc.ORDER-FOR-LATER' | translate }}
        </msl-primary-button>
    </div>
</div>
