/**
 * Created by Adrien Dos Reis on 05/02/2021
 */
export class Country
{
    // region Constructor

    constructor(public id: number, public code: string, public name: string,
                public phonePrefix: string, public opened: boolean)
    {
    }

    // endregion

    static assign(unassigned: any): Country
    {
        return Object.assign(new Country(unassigned.id, unassigned.code, unassigned.name,
            unassigned.phonePrefix, unassigned.opened), unassigned);
    }

    // region Equals / CompareWith

    equals(other: any)
    {
        if (!(other instanceof Country)) return false;
        return this.id === other.id;
    }

    static compareWith = (o1: Country, o2: any) => o1.equals(o2)

    // endregion
}