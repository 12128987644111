import { $enum } from 'ts-enum-util';
import { VehicleType } from './constants/vehicle-type';

export class DriverDetails
{
    driverId: string;
    vehicleBrand: string;
    vehicleModel: string;
    vehicleType: VehicleType;
    vehicleYear: number;
    regionId: number;
    vehicleColor: string;

    /**
     * DriverOnBoardPaymentDetails
     */
    acceptingCreditCardPayment: boolean;
    acceptingCashPayment: boolean;
    alwaysEligible: boolean;
    zdWeeklyBalance: number;

    static assign(unassignedDriverDetails: any): DriverDetails
    {
        const driverDetails = Object.assign(new DriverDetails(), unassignedDriverDetails);
        driverDetails.vehicleType = $enum(VehicleType).getValueOrDefault(driverDetails.vehicleType);

        return driverDetails;
    }
}
