import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MslOnboardPaymentItemComponent } from './payment-method-item/msl-onboard-payment-item.component';

@Component({
    selector: 'msl-select-onboard-payment-method',
    templateUrl: './msl-select-onboard-payment-method.component.html',
    styleUrls: [ './msl-select-onboard-payment-method.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslSelectOnboardPaymentMethodComponent
{
    @ViewChildren(MslOnboardPaymentItemComponent) onBoardPaymentItems: QueryList<MslOnboardPaymentItemComponent>;
    @Output() willBePaidInCash = new EventEmitter<boolean>();
    @Input() selectedColor = '#14cab3';

    constructor()
    {
    }

    /**
     * Selects the given MslOnboardPaymentItemComponent and resets the others (if they were highlighted by a previous selection)
     * @param willBePaidInCash Does the client want to pay in cash (true) or credit card (false) ?
     */
    selectItemAndResetOthers(willBePaidInCash: boolean)
    {
        this.onBoardPaymentItems.forEach((onBoardPaymentItem: MslOnboardPaymentItemComponent) =>
            onBoardPaymentItem.selected = onBoardPaymentItem.cash === willBePaidInCash);
        this.willBePaidInCash.emit(willBePaidInCash);
    }
}
