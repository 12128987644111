/**
 * Created by Adrien Dos Reis on 07/08/2019
 */
import { Trip } from '../../models/trip';

export class TripAndRedirection
{
    // region Attributes

    trip: Trip;
    clientSecret: string = null;

    // endregion

    /**
     * Deep casts the given "uncastedTripAndRedirection"
     * @param uncastedTripAndRedirection
     */
    static assign(uncastedTripAndRedirection: any): TripAndRedirection
    {
        const tripAndRedirection: TripAndRedirection = Object.assign(new TripAndRedirection(), uncastedTripAndRedirection);
        tripAndRedirection.trip = Object.assign(new Trip(), tripAndRedirection.trip);

        return tripAndRedirection;
    }
}
