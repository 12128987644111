import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslTripTypeRadioComponent } from './msl-trip-type-radio.component';
import { TranslateModule } from '@ngx-translate/core';
import { MslButtonModule } from '../msl-button/msl-button.module';

const components = [ MslTripTypeRadioComponent ];

@NgModule({
    imports: [
        CommonModule,
        MslButtonModule,
        TranslateModule
    ],
    declarations: [ ...components ],
    exports: [ ...components, TranslateModule ]
})
export class MslTripTypeRadioModule
{
}
