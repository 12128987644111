import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address, PaymentMethod } from 'mys-base';

@Component({
    selector: 'msl-trip-summary',
    templateUrl: './msl-trip-summary.component.html',
    styleUrls: [ './msl-trip-summary.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslTripSummaryComponent
{
    @Input() fromAddress: Address;
    @Input() toAddress: Address;
    @Input() bookingDate: string | null;
    @Input() vehicleTypeKey: string;

    @Input() paymentMethod: PaymentMethod;
    @Input() willBePaidInCash: boolean | null;

    @Input() passengers: number;
    @Input() flightNumber: string | null;
    @Input() trainNumber: string | null;
    @Input() comment: string;

    PaymentMethod = PaymentMethod;

    constructor()
    {
    }
}
