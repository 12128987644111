import { Component } from '@angular/core';
import { MslButtonComponent } from '../msl-button/msl-button.component';
import { ThemePalette } from '@angular/material/core';

/**
 *
 * In order to have a fully functional component in your application, you might want to add the following in your global style :
 *
 * .white-spinner circle { stroke: #fff !important; }
 *
 * This allows to display a white spinner inside the "msl-button" Components, but cannot seem to be set directly inside the library :(
 *
 */
@Component({
    selector: 'msl-primary-button',
    templateUrl: '../msl-button/msl-button.component.html',
    styleUrls: [ '../msl-button/msl-button.component.scss', './msl-primary-button.component.scss' ]
})
export class MslPrimaryButtonComponent extends MslButtonComponent
{
    color: ThemePalette = 'primary';
}
