import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MslVehicleRadioV2Component } from './vehicle-radio-v2/msl-vehicle-radio-v2.component';
import { MslVehicleRadioItemV2Component } from './vehicle-radio-item-v2/msl-vehicle-radio-item-v2.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';

const components = [ MslVehicleRadioV2Component, MslVehicleRadioItemV2Component ];
const material = [ MatDividerModule, MatListModule ];

@NgModule({
    declarations: [ ...components ],
    exports: [
        MslVehicleRadioV2Component
    ],
    imports: [
        CommonModule,
        ...material,
        TranslateModule
    ]
})
export class MslVehicleRadioV2Module
{
}
