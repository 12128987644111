import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { VehicleType } from 'mys-base';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'msl-vehicle-radio-item[vehicleType][selectedImage][unselectedImage]',
    templateUrl: './msl-vehicle-radio-item.component.html',
    styleUrls: [ './msl-vehicle-radio-item.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MslVehicleRadioItemComponent implements OnInit, OnChanges
{
    // region Attributes

    src: string;

    /**
     * Only one of those tooltips is displayed at a time :
     * - On standard screens, a question mark icon is displayed, and displays "questionMarkTooltipKey" when hovered
     * - On very small screens, the icon is hidden, and the whole container displays "smallScreensTooltipKey" when hovered
     */
    questionMarkTooltipKey: string | null = null;
    smallScreensTooltipKey: string | null = null;
    isScreenSmall: boolean;

    // endregion

    // region Inputs/Outputs

    @Input() selectedImage: string;
    @Input() unselectedImage: string;
    @Input() vehicleType: VehicleType;
    @Input() disabled = false;
    @Input() tooltipKey: string | null = null; // If given, will be displayed as a tooltip explaining what is this Vehicle Type
    @Output() clicked = new EventEmitter<VehicleType>();

    /**
     * The "selected" attribute must be bound to the "src" attribute value.
     * In order-button to do that, we define getters/setters instead of a simple attribute
     */
    _selected: boolean;
    @Input() get selected(): boolean
    {
        return this._selected;
    }

    set selected(newValue: boolean)
    {
        this._selected = newValue;

        /**
         * Here, we change the "src" attribute
         */
        this.src = newValue ? this.selectedImage : this.unselectedImage;

        /**
         * Since our "OnPush" change detection does not detect changes on "src" (since it is a string, a primitive type),
         * we need to trigger it manually
         * Source : https://stackoverflow.com/questions/13104494/does-javascript-pass-by-reference/13104500#13104500
         */
        this.changeDetectorRef.detectChanges();
    }

    // endregion

    // region Constructor

    constructor(private changeDetectorRef: ChangeDetectorRef, private breakpointObserver: BreakpointObserver) {}

    // endregion

    /**
     * On "very small screens" (=> screens with a width < $screen-xs-min (defined in "global-styles.scss")),
     * the RadioItem is resized.
     * In this case, we want to hide the tooltip icon and place the tooltip on the whole RadioItem.
     * This method allows to observe the screen width, and updates the tooltips accordingly.
     */
    switchTooltipsBasedOnScreenWidth()
    {
        const isSmall = this.breakpointObserver.isMatched('(max-width: 480px)');
        if (isSmall)
        {
            this.questionMarkTooltipKey = null;
            this.smallScreensTooltipKey = this.tooltipKey;
        }
        else
        {
            this.questionMarkTooltipKey = this.tooltipKey;
            this.smallScreensTooltipKey = null;
        }

        /**
         * Finally, if the previous value is different than the new one, we force a change detection
         */
        if (this.isScreenSmall !== isSmall)
        {
            this.isScreenSmall = isSmall;
            this.changeDetectorRef.detectChanges();
        }
    }

    /**
     * Selects the current item, and triggers the "change" event if it wasn't already selected
     */
    selectCurrentItem(): void
    {
        if (!this.disabled) // If our Component is disabled, we don't want to emit any events nor trigger a selection
        {
            this.selected = true;
            this.clicked.emit(this.vehicleType);
        }
    }

    // region Lifecycle

    ngOnInit(): void
    {
        /**
         * When "src" is set, we can bind it as our default source
         */
        this.src = this.unselectedImage;
    }

    ngOnChanges(changes: SimpleChanges)
    {
        /**
         * If the "tooltipKey" Input has been changed, we want to put it into the appropriate "tooltip"
         */
        if (!!changes.tooltipKey && !!changes.tooltipKey.currentValue)
        {
            this.switchTooltipsBasedOnScreenWidth();
        }
    }

    // endregion
}
